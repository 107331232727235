<template>
    <div class="history-item row">
        <span class="history-data history-header">
            <order-header
                :order="order"
                :action="true"
                @open="open = $event"
                @history="history = $event"
                @loading="loading = $event"
            ></order-header>
        </span>
        <div class="border history-body">
            <div v-if="hasContractBody" class="row">
                <contract-body :contract="order.contract" :text="order.translations.contract"></contract-body>
            </div>

            <div v-else class="row">
                <div class="attr-item order-data col-sm-12" :class="order.delivery.class">
                    <order-history-body :order="order"></order-history-body>
                </div>
            </div>
        </div>
        <pop-up :open="open">
            <div class="container popup-header b-primary t-capitalized">
                <order-header :order="order"></order-header>
                <span class="font-bold close-icon" @click="popupClose()">
                    <simple-svg  :src="closeImage" custom-class-name="stroke--white" />
                </span>

            </div>
            <div class="container scrollable">
                <div class="loader" :class="{isloading: loading}" id="loader-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="row" v-html="history"></div>
            </div>
        </pop-up>
    </div>
</template>

<script>
    import OrderHistoryBody from "./OrderHistoryBody";
    import ContractStatus from "./ContractStatus";
    import OrderHeader from "./OrderHeader";

    export default {
        name: "order-history",
        components: {
            OrderHeader,
            OrderHistoryBody,
            ContractStatus
        },
        props: {
            order: {
                default: {}, type: Object
            }
        },
        data() {
            return {
                open: false,
                history: '',
                loading: false
            }
        },
        computed: {
            hasContractBody() {
                return this.order.contract <= 5 && this.order.contract != 0;
            },
            closeImage(){
                return require('../../../../svg/CloseIcon.svg')
            }
        },
        methods: {
            popupClose() {
                this.open = false
                document.body.classList.remove('o-hidden')
            }
        }
    }
</script>
<style>
    .popup-header span:last-child:hover {
        cursor: pointer;
    }

    .pop-up .name {
        font-weight: bold;
    }

    .history-body {
        padding: 0.5rem 0.625rem;
    }
</style>
