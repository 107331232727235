<template>
    <div class="data-table mt-4" :class="compact ? 'data-table--small' : ''">
        <div class="loading" v-show="isLoading">
            <loader />
        </div>

        <data-table
            class="data-table__table"
            v-show="!isLoading"
            :url="url"
            :per-page="perPage"
            :filters="filters"
            :columns="columns"
            :translate="{ nextButton: translations.paginate_next, previousButton: translations.paginate_previous}"
            @finishedLoading="isLoading = false">
            <div slot="filters" slot-scope="{ tableData, perPage }">
                <div class="filters row">
                    <div v-if="perPage" class="col-md-3">
                        <div class="select-value">
                            <select name="perPage" class="form-control select-list" v-model="tableData.length">
                                <option :key="page" v-for="page in perPage" :value="page">
                                    {{ translations.entries_per_page }} {{ page }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div v-if="additional.companies" :class="getCompanyColumnClass">
                        <div class="select-value">
                            <select
                                v-model="tableData.filters.companies"
                                class="form-control select-list">
                                <option value selected>{{ translations.all_companies }}</option>
                                <option v-for="(company, index) in additional.companies"
                                        :key="index"
                                        :value="company.slug">
                                        {{ company.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div v-if="additional.states" class="col-md-2">
                        <div class="select-value">
                            <select
                                v-model="tableData.filters.states"
                                class="form-control select-list">
                                <option value>{{ translations.all_states }}</option>
                                <option v-for="(state, index) in additional.states"
                                        :key="index"
                                        :value="index">
                                        {{ state }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="additional.delivery_status" class="col-md-3">
                        <div class="select-value">
                            <select
                                v-model="tableData.filters.delivery_status"
                                class="form-control select-list">
                                <option value>{{ translations.all_states }}</option>
                                <option v-for="(state, index) in additional.delivery_status"
                                        :key="index"
                                        :value="index">
                                        {{ state }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-3 ml-auto">
                        <div class="field__search">
                            <input
                                name="name"
                                class="form-control filters__search"
                                v-model="tableData.search"
                                placeholder="">
                        </div>
                    </div>
                </div>
            </div>
        </data-table>
    </div>
</template>

<script>
    import EditButton from './administration/table/EditButton'
    import DeleteButton from './administration/table/DeleteButton'
    import SelectUser from './administration/table/SelectUser'
    import LastInvite from './administration/table/LastInvite'
    import UserVerified from './administration/table/UserVerified'
    import UserStatus from './administration/table/UserStatus'

    export default {
        name: 'user-datatable',
        components: {
            EditButton,
            DeleteButton,
            SelectUser,
            LastInvite,
            UserVerified,
            UserStatus
        },
        props: [
            'url',
            'data_columns',
            'config_pages',
            'additional_data',
            'compact'
        ],
        data() {
            return {
                isLoading: true,
                filters: {
                    companies: '',
                    states: '',
                    delivery_status: '',
                },
            }
        },
        computed: {
            perPage() {
                return this.parse(this.config_pages)
            },
            columns() {
                return this.parse(this.data_columns)
            },
            additional() {
                return this.parse(this.additional_data)
            },
            translations() {
                return this.additional.translations ? this.additional.translations : ''
            },
            getCompanyColumnClass() {
                return Object.keys(this.parse(this.additional_data)).length > 2 ? 'col-md-3' : 'col-md-4'
            }
        },
        methods: {
            parse(value) {
                return value ? JSON.parse(value) : ''
            }
        },
    }
</script>

<style scoped>
.data-table {
    position: relative;
    width: 100%;
}

.loading {
    position: relative;
    min-height: 490px;
}
</style>
