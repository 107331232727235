<template>
    <chart-accordion class="company-chart" :headline="translations.company_conversion.title">
        <loader v-show="loading" />

        <chart-filter
            :url="url"
            :translations="additionals.translations"
            :companies="filters"
            @updatedLoader="getUpdatedLoader"
            @updatedData="getUpdatedData" />

        <company-pie-charts :translations="translations" :product-data="productsData" :order-data="ordersData"></company-pie-charts>
        <bars-chart-element :translations="translations"
                            :colors="colors"
                            :charts="charts"
                            :info-text="translations.info.info_statistics_orders_per_company_empty"/>
    </chart-accordion>
</template>

<script>
export default {
    name: 'CompanyChart',
    props: {
        url: {
            type: String,
            default: ''
        },
        additionals: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            chartData: {},
            loading: false
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        filters() {
            return this.additionals.filters
        },
        translations() {
            return this.additionals.translations
        },
        charts() {
            return this.chartData.data
        },
        productsData() {
            return this.chartData.products
        },
        ordersData() {
            return this.chartData.orders
        },
        colors() {
            return this.chartData.colors
        }
    },
    methods: {
        getUpdatedLoader(value) {
            if(value) {
                this.loading = value
            }
        },
        getUpdatedData(value) {
            if(value) {
                this.chartData = value
                this.loading = false
            }
        },
        async fetchData() {
            this.loading = true

            await axios
                .get(this.url)
                .then(response => {
                    this.chartData = response.data
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
            })
        }
    }
}
</script>

<style scoped>
.company-chart {
    position: relative;
    border: 2px solid #F1F1F1;
}
</style>
