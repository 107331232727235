<template>
    <div class="form-item">
        <label :for="`user_${name}`">{{ label }}<span v-if="required == true">*</span></label>
        <money :class="classes" type="text" :id="`user_${name}`" :name="name" v-model="price" v-bind="money"></money>
    </div>
</template>

<script>
import { Money } from 'v-money'

/** TODO: try to remove suffix on submitting this value to backend */
export default {
    name: 'BaseCurrency',
    components: {
        Money
    },
    props: {
        name: {
            type: [String, Number],
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        old: {
            type: [Number, String],
            default: 0
        },
        classes: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            price: this.old,
            money: {
                decimal: '.',
                thousands: '',
                suffix: ' €',
                precision: 2
            }
        }
    }
}
</script>
