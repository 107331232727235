export default {
    methods: {

        // [[key, value], [key, value]]
        getChartData(dataset) {
            return dataset.map(el => [el.label, el.data])
        },
        getLabels(dataset) {
            return dataset.map(el => el.label)
        },
        getColors(dataset) {
            return dataset.map(el => el.backgroundColor)
        }
    }
}
