<template>
    <div :title="title" class="average-tiles__tile">
        <icon-base className="success" :icon="icon" :width="iconWidth" :height="iconHeight"/>

        <h3 class="average-tiles__headline">{{ headline }}</h3>

        <p class="average-tiles__value">{{ value }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            icon: {
                type: String,
                required: true
            },
            headline: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Number],
                default: 0.00
            },
            iconWidth: {
                type: String,
                default: '80px'
            },
            iconHeight: {
                type: String,
                default: '80px'
            }
        },
        computed: {
            title() {
                return `${this.headline} ${this.value}`
            }
        }
    }
</script>
