<template>
    <div class="user-verified">
        <svg    v-if="data.email_verified_at"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                class="stroke--verified">
                <polyline class="cls-1" points="26.36 8.7 6.89 8.7 6.89 31.7 29.89 31.7 29.89 19.52"/>
                <polyline class="cls-1" points="12.32 16.14 18.73 23.44 33.1 10.4"/>
        </svg>

        <svg    v-else
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                class="stroke">
            <rect class="cls-1" x="8.5" y="8.5" width="23" height="23"/>
            <line class="cls-2" x1="14.69" y1="14.69" x2="25.33" y2="25.33"/>
            <line class="cls-2" x1="25.33" y1="14.69" x2="14.69" y2="25.33"/>
        </svg>
    </div>
</template>

<script>
    export default {
        props: {
            data: {},
            meta: {},
            name: {},
            url: {}
        }
    }
</script>

<style scoped>
.user-verified {
    text-align: center;
}
</style>

<style scoped>
.stroke {
    color: #A10000;
}

.stroke--verified {
    color: #89C882;
}
</style>
