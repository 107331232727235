<template>
    <div class="choose-company">
        <div :class="classes">
            <label for="company_role_all">
                <input  class="checkbox"
                        type="checkbox"
                        value="all"
                        id="company_role_all"
                        v-model="selectAll"
                >
                <span class="checkbox"></span>
                <strong>{{ translations }}</strong>
            </label>
        </div>

        <div v-for="(company, index) in companies" :key="index" :class="classes">
            <label :for="`company_role_${company.slug}`">
                <input  class="checkbox"
                        type="checkbox"
                        name="company_role[]"
                        :id="`company_role_${company.slug}`"
                        :value="company.id"
                        v-model="selected"
                >
                <span class="checkbox"></span>
                {{ company.name }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseCompany',
    props: {
        companies: {
            type: [Array, Object],
            default: () => []
        },
        roles: {
            type: [Array, Object],
            default: () => []
        },
        translations: {
            type: [Array, Object, String],
            default: ''
        }
    },
	data: () => ({
        selected: [],
        classes: {
            'checkbox': true,
            'checkbox--left': true
        }
    }),
    created() {
        this.selectedCompanies()
    },
    computed: {
        selectAll: {
            get: function () {
                return this.companies ? this.selected.length == this.companies.length : false;
            },
            set: function (value) {
                let selected = []

                if (value) {
                    this.companies.forEach(company => {
                        selected.push(company.id)
                    });
                }

                this.selected = selected
            }
        }
    },
    methods: {
        selectedCompanies() {
            let selected = []

            if(this.roles) {
                this.roles.forEach(role => {
                    if(!role.id) {
                        selected.push(role)
                    } else {
                        selected.push(role.id)
                    }
                })
            }

            this.selected = selected
        }
    },
}
</script>
