<template>
    <div class="tabbed-bar d-flex justify-content-between align-items-center">
        <div class="tab" :class="{'active': activeState === 0}" @click="tabChanged(0)">
            {{ translations.key_figures }}
        </div>
        <div class="tab" :class="{'active': activeState === 1}" @click="tabChanged(1)">
            {{ translations.orders }}
        </div>
        <div class="tab" :class="{'active': activeState === 2}" @click="tabChanged(2)" v-show="showService">
            {{ translations.service }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            state: {
                type: Number,
                default: 0
            },
            showService: {
                type: [Boolean],
                default: false
            },
            translations: {
                type: [Array, Object]
            }
        },
        data() {
            return {
                activeState: 0
            }
        },
        created() {
            this.activeState = this.state
        },
        methods: {
            tabChanged(state) {
                this.activeState = state
                this.$emit('tabchange', this.activeState)
            },
        }
    }
</script>
