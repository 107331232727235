<template>
    <div class="tw-relative">
        <loader v-show="loading" />

        <div v-if="errors.length > 0 && !isValid" class="tw-bg-error tw-text-white tw-px-6 tw-py-3">
            <span class="tw-block" v-for="(error, index) in errors" :key="index">{{ error }}</span>
        </div>

        <div
            :class="isValid ? 'tw-bg-success tw-text-white tw-border-success' : 'tw-bg-gray-100 tw-border-gray-100 tw-text-gray-300'"
            class="tw-text-xl lg:tw-text-2xl tw-transition-colors tw-ease-in tw-border tw-font-bold tw-px-4 lg:tw-px-6 tw-py-3 tw-flex tw-justify-between"
        >
            <span>Marktüblicher Restwert:</span>

            <span v-if="remainingDeviceValue">
                {{ formatNumber(remainingDeviceValue) }} €
            </span>
        </div>

        <div class="tw-border tw-border-t-0 tw-p-6 tw-border-gray-200">
            <div class="tw-flex tw-items-center">
                <img class="tw-w-24 tw-h-24 tw-object-contain tw-hidden md:tw-block" :src="product.image">

                <div class="tw-flex tw-flex-col tw-px-4">
                    <span class="tw-font-bold">{{ product.name }}</span>
                    <span class="tw-mt-4 tw-text-sm tw-text-gray-500">Art.-Nr.: {{ product.number }}</span>
                    <span class="tw-text-sm tw-text-gray-500">Seriennummer: {{ product.identification_number }}</span>
                </div>

                <div v-if="product.main === '1'" class="tw-ml-auto tw-w-1/3">
                    <label :class="hasDeviceCondition ? 'tw-text-success' : 'tw-text-info'"
                            class="tw-text-sm">
                        <strong>Zustand deines Gerätes:</strong>
                    </label>
                    <select
                        v-model="currentDeviceCondition"
                        :class="hasDeviceCondition ? 'tw-border-success' : 'tw-border-info'"
                        class="tw-block tw-outline-none tw-w-full tw-px-4 tw-bg-white tw-border"
                        @change="calculateRemainingDeviceValue"
                    >
                        <option value="" disabled>bitte wählen</option>

                        <option v-for="(condition, index) in deviceConditions" :key="index" :value="index + 1">
                            {{ condition }}
                        </option>
                    </select>
                </div>
                <span class ="tw-ml-auto tw-w-1/3" v-else>Zubehör wird Pauschal bewertet.</span>
            </div>


            <div v-if="isMainProduct" v-show="hasDeviceCondition" class="tw-mt-8 tw-flex tw-space-y-4 tw-flex-col tw-items-start">
                <label :class="hasFiles ? 'tw-text-success' : 'tw-text-info'" class="tw-text-sm">
                    <strong>Lade hier 2 unterschiedliche Bilder deines Produktes hoch, auf denen der Zustand gut zu erkennen ist:</strong>
                </label>

                <buyback-upload
                    uploadFieldName="uploadedFile[]"
                    :uploadPostUrl="settings.upload"
                    :removePostUrl="settings.remove"
                    :productId="product.prod_id"
                    :uploadLimit="2"
                    :sessionFiles="product.images"
                    @uploadedFiles="getUploadedFiles">
                </buyback-upload>
            </div>

            <div v-show="isValid" class="tw-flex tw-py-4 tw-items-center tw-justify-center" style="background: rgba(255, 255, 255, 0.6);">
                <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>4BDBA4A1-DEEA-4330-9DD8-D0C8F2A6E45A</title>
                    <g id="06-Mein-Konto" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Desktop-HD/06-MeinKonto_04-Vertragsende_02-Zustand_03" transform="translate(-680.000000, -1505.000000)">
                            <rect fill="#FFFFFF" x="0" y="0" width="1440" height="2808"></rect>
                            <g id="Content/Vertragsende/Produkt/auswahl-Copy" transform="translate(165.000000, 912.000000)">
                                <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="1110" height="703"></rect>
                                <rect id="Rectangle" stroke="#89C882" x="0.5" y="0.5" width="1109" height="702"></rect>
                                <g id="Icon/Content/check-mark" transform="translate(515.000000, 593.000000)" stroke="#89C882" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                    <path d="M40.4800624,56.7950846 L21.5093684,37.1341194 L27.6531749,30.9903129 L40.2604306,43.5995296 L67.310436,16.5495241 C60.713639,9.08792701 51.0988662,4.358 40.3584805,4.358 C20.4759225,4.358 4.3584805,20.475442 4.3584805,40.356039 C4.3584805,60.238597 20.4759225,76.358 40.3584805,76.358 C60.2390775,76.358 76.3565195,60.238597 76.3565195,40.356039 C76.3565195,35.0005537 75.1544278,29.933335 73.05616,25.3642098 L40.4800624,56.7950846 Z" id="Stroke-1"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { formatNumber } from "../../../helpers/number.helper";

import BuybackUpload from "./BuybackUpload.vue";

export default {
    name: "BuybackProduct",
    props: {
        settings: {
            type: [Array, Object],
            default: () => []
        },
        product: {
            type: Object,
            default: () => {}
        },
        errors: {
            type: [Array, Object, String],
            default: () => []
        }
    },
    components: {
        BuybackUpload
    },
    data() {
        return {
            formatNumber,
            loading: false,
            currentDeviceCondition: this.product.condition || "",
            deviceConditions: [
                "sehr gut",
                "gut",
                "befriedigend",
            ],
            results: {},
            files: 0
        };
    },
    mounted() {
        this.calculateRemainingDeviceValue();
    },
    computed: {
        resultsData() {
            return this.results.data;
        },
        remainingDeviceValue() {
            return this.resultsData
                ? this.resultsData.prices[this.product.prod_id]
                : undefined;
        },
        hasDeviceCondition() {
            if (this.product.main === 0) {
                this.currentDeviceCondition = 4;
            }
            return this.currentDeviceCondition != "";
        },
        isMainProduct() {
            if (this.product.main === 0) {
                return false
            }
            return true
        },
        hasFiles() {
            return (
                this.files.length > 0 &&
                this.files.length === 2 &&
                !this.hasFailedUploads
            );
        },
        hasFailedUploads() {
            return this.files.some(file => file.success === false);
        },
        isValid() {
            if (this.product.main === 0) {
                return true
            }
            return this.resultsData && this.hasFiles;
        }
    },
    methods: {
        async calculateRemainingDeviceValue() {
            if (this.hasDeviceCondition) {
                this.loading = true;

                await axios
                    .post(this.settings.calculate, {
                        id: this.product.prod_id,
                        condition: this.currentDeviceCondition
                    })
                    .then(response => {
                        this.results = response.data;
                        this.$emit("calculatedResult", response.data);
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                    });
            }
        },
        getUploadedFiles(files) {
            this.files = files;
        }
    }
};
</script>

<style>
</style>
