<template>
    <pop-up :open="isOpen">
        <div class="popup-header b-primary container t-capitalized">
            <order-header :order="orderData"></order-header>
            <span class="font-bold close-icon" @click="popupClose()">
                <simple-svg  :src="closeImage" custom-class-name="stroke--white" />
            </span>
        </div>
        <div class="container scrollable">
            <div class="loader" :class="{isloading: loading}" id="loader-dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="row" v-html="orderHistory"></div>
        </div>
    </pop-up>
</template>

<script>
    import OrderHistoryPopupMixin from "../mixins/OrderHistoryPopupMixin";
    import OrderHeader from "./OrderHeader";

    export default {
        props: {
            isOpen: {
                type: Boolean,
                default: false
            },
            orderData: {
                type: [Array, Object]
            },
            orderNumber: {
                type: String,
                default: ''
            },
            orderId: {
                type: [Number, String],
                default: 0
            },
            orderHistory: {
                type: [String, Array, Object]
            }
        },
        components: {
            OrderHeader,
        },
        computed: {
            closeImage(){
                return require('../../../../svg/CloseIcon.svg')
            }
        },
        mixins: [OrderHistoryPopupMixin],
        methods: {
            popupClose() {
                this.$emit('popupClose')
                document.body.classList.remove('o-hidden')
            }
        }
    }
</script>
