import { toNumber } from "lodash";

const locale = "de-DE";

export const formatNumber = (value, options) =>
    new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options
    }).format(toNumber(value));
