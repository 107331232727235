<template>
    <div class="info-content info-content--info justify-content-start align-items-center" :class="geCenteredClassName">
        <icon-base icon="InfoIcon"  className="info" width="30px" height="30px" />
        <span>{{ infoText }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            infoText: {
                type: String,
                default: ''
            },
            iconCenter: {
                type: [Boolean],
                default: true
            }
        },
        computed: {
            geCenteredClassName() {
                return this.iconCenter ? 'align-items-center' : ''
            }
        }
    }
</script>

<style>
    .info-content {
        display: flex;
    }
</style>
