<template>
    <div class="accordion">
        <div class="accordion-header" @click="opened = !opened">
            <h3>{{ name }}</h3>
            <span>{{ date }}</span>
        </div>
        <div class="accordion-body" v-if="opened">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                default: '', type: String
            },
            date: {
                default: 'dd.mm.YY', type: String
            },
            open: {
                default: true, type: Boolean
            }
        },
        data() {
            return {
                opened: false
            }
        },
        created() {
            this.opened = this.open
        }
    }
</script>

<style>

</style>
