const notify = () => {
    const popups = document.querySelectorAll(".notify");

    if (!popups) {
        return;
    }

    popups.forEach(popup => {
        popup.addEventListener("click", () => {
            console.log(popups);
            popup.classList.add("slide-out");
        });
    });
};

export { notify };
