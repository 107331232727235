<template>
    <div class="average-tiles">
        <div class="average-tiles__row">
            <average-tile
                icon="OrderVolume"
                :headline="translations.order_volume"
                :value="getCurrencyValue(data.orderVolume)" />

            <average-tile
                icon="BasketVolume"
                :headline="translations.order_processed_volume"
                :value="getThousandSeperator(data.orderProcessedVolume)" />

            <average-tile
                icon="Devices"
                :headline="translations.total_devices"
                :value="getThousandSeperator(data.totalDevices)" />

            <average-tile
                icon="BasketVolume"
                :headline="translations.average_basket"
                :value="getCurrencyValue(data.averageBasket)" />

            <average-tile
                icon="PackageDelivery"
                :headline="translations.delivery_time"
                :value="deliveryTime" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            translations: {
                type: [Array, Object],
                default: () => []
            },
            data: {
                type: [Array, Object],
                default: () => []
            },
        },
        computed: {
            deliveryTime() {
                const days = this.data.deliveryTime / (3600 * 24)
                if(days) {
                    return `${Math.round(days)} Tage`
                }
            }
        },
        methods: {
            getCurrencyValue(value) {
                if(value) {
                    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(value)
                }
            },
            getThousandSeperator(value) {
                if(value){
                    return new Intl.NumberFormat('de-DE').format(value)
                }
            }
        }
    }
</script>
