import datepicker from 'js-datepicker'

export class DatePicker {
    constructor(selector, options={
        maxDate: new Date(),
        formatter: (input, date, instance) => {input.value = date.toLocaleString('de-DE', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }).replace(/[^ -~]/g,'')}
    }) {
        this.selector = selector
        this.options = options
    }

    initDatePicker() {
        return datepicker(this.selector, this.options)
    }
}
