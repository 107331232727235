<template>
    <transition name="modal">
        <div class="modal-mask" >
            <div class="modal-wrapper">
            <div class="modal-container lg:tw-w-128">
                <div class="tw-grid tw-justify-items-end tw-cursor-pointer">
                    <div @click="close">
                        <simple-svg  :src="closeImage" custom-class-name="stroke--black" />
                    </div>
                </div>
                <div class="tw-pb-4">
                    <slot></slot>
                </div>
            </div>
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'Modal',
    computed: {
        closeImage(){
            return require('../../../../svg/Alert/Close.svg')
        }
        },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>



<style>

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {

    max-width: 100%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}


.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

</style>
