import Vue from 'vue'
import OrderHistory from "../vue/default/order-history/OrderHistory";

export default class VueInitHelper {
    constructor(el) {
        this.el = el
    }

    initVue() {
        return new Vue({
            el: this.el
        })
    }
}
