<template>
    <div class="chart-view">
        <general-chart
            class="chart-view__item"
            :url="generalUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />

        <users-chart
            class="chart-view__item"
            :url="usersUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />

        <delivery-chart
            class="chart-view__item"
            :url="deliveryUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />

        <company-chart
            class="chart-view__item"
            :url="companyUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />

        <device-chart
            class="chart-view__item"
            :url="deviceUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />

        <supplier-chart
            class="chart-view__item"
            :url="supplierUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />

        <service-chart
            class="chart-view__item"
            :url="serviceUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />

        <contract-chart
            class="chart-view__item"
            :url="contractUrl"
            :additionals="{
                filters: companies,
                translations: translations
            }" />            
    </div>
</template>

<script>
    export default {
        props: {
            additionals: {
                type: [Array, Object],
                default: () => []
            },
            translations: {
                type: [Array, Object],
                required: true
            },
            filters: {
                type: [Array, Object]
            }
        },
        computed: {
            urls() {
                return this.additionals.urls
            },
            companies() {
                return JSON.parse(this.filters.companies)
            },
            generalUrl() {
                return this.urls.general
            },
            usersUrl() {
                return this.urls.users
            },
            companyUrl() {
                return this.urls.company
            },
            deliveryUrl() {
                return this.urls.delivery
            },
            deviceUrl() {
                return this.urls.device
            },
            serviceUrl() {
                return this.urls.service
            },
            contractUrl() {
                return this.urls.contract
            },
            recurringUrl() {
                return this.urls.recurring
            },
            registeredUrl() {
                return this.urls.registered
            },
            supplierUrl() {
                return this.urls.supplier
            },
            whitelistUrl() {
                return this.urls.whitelist
            }
        }
    }
</script>

<style scoped>
.chart-view__item {
    margin-bottom: 3rem;
}

.chart-view__item :last-child {
    margin-bottom: 0;
}
</style>
