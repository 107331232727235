<template>
    <div v-if="budget > 0">
        <div class="budget-view-header">
            <span>{{ headline }}</span>
        </div>
        <div class="budget-view-progress">
            <div class="progress-bar">
                <span class="progress" :style="`width: ${percentageBudget}`"></span>
            </div>
        </div>

        <div class="budget-view-progress-values">
            <div class="row">
                <span class="col-6 col-sm-6 progress-values used">
                    {{ usedBudget }}
                </span>
                <span class="col-6 col-sm-6 progress-values remaining">
                    {{ calculatedBudget }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Budget',
    props: {
        headline: {
            type: String,
            default: ''
        },
        budget: {
            type: [Number, String],
            default: 0
        },
        usedBudget: {
            type: [Number, String],
            default: 0
        },
        percentageBudget: {
            type: [Number, String],
            default: 0
        },
        calculatedBudget: {
            type: [Number, String],
            default: 0
        }
    }
}
</script>
