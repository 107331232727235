<template>
    <div class="service-pie">
    <h3 class="text-center">{{ serviceData.headline }}</h3>
    <dashboard-pie-chart
        :width="pieWidth"
        :height="pieHeight"
        :chartdata="serviceData.data"
        legend-class="legend-grid legend-grid--2"
        class-name="center mb-3"
        :info-text-empty="infoTranslations.info_statistics_ticket_empty"
        :info-text-one="infoTranslations.info_statistics_ticket__one"
        :info-text-value="infoTranslations.info_statistics_ticket_value"></dashboard-pie-chart>
    </div>
</template>

<script>
    export default {
        props: {
            serviceData: {
                type: [Array, Object]
            },
            translations: {
                type: [Array, Object],
                default: () => []
            },
            width: {
                type: String,
                default: '480px'
            },
            height: {
                type: String,
                default: '480px'
            }
        },
        computed: {
            infoTranslations() {
                return this.translations.info
            },
            pieWidth() {
                return this.width
            },
            pieHeight() {
                return this.height
            }
        }
    }
</script>

<style scoped>
    h3 {
        margin-top: 3.125rem;
        text-transform: none;
        font-weight: 700;
        margin-bottom: 1.75rem;
    }
    .delivery-chart{
        justify-content: center;
    }
</style>
