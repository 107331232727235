<template>
    <div class="pop-up">
        <div class="pop-up-layer" :class="{'open': isOpen}"></div>
        <div class="pop-up-form" :class="{'open': isOpen}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            open: {
               default: false, type: Boolean
            }
        },
        computed: {
            isOpen() {
                return this.open
            }
        }
    }
</script>

<style>

    .btn-close {
        text-align: right;
    }
</style>
