<template>
    <div class="tw-text-acadia tw-font-bold tw-my-4">
        <div :class="classes"
            class="tw-relative tw-flex tw-p-4"
            @click="open">
            <input class="tw-border tw-border-gray-100"
                type="checkbox"
                :name="name"
                :checked="checked">
            <span class="tw-relative tw-mt-px tw-w-4 tw-h-4 tw-inline-block tw-border tw-border-black tw-bg-white"></span>
            <label class="tw-mb-0 tw-ml-2 tw-flex-1 tw-inline-block tw-leading-snug">{{ label }}</label>
        </div>

        <div v-show="isOpen" class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-z-50">
            <div class=" tw-bg-black tw-opacity-50 tw-absolute tw-z-30 tw-inset-0"></div>

            <div class="tw-bg-white tw-p-8 tw-mx-auto tw-z-40"  style="width: 70%; max-height: 80vh; overflow-x: scroll;">
                <slot />

                <div class="tw-min-w-full" :id="`bp_check-${_uid}-holder`"></div>

                <div class="tw-w-1/2 tw-my-10 tw-mx-auto tw-flex tw-items-center tw-justify-center tw-space-x-4">
                    <button type="button"
                            class="tw-border tw-w-full tw-border-primary tw-py-2 tw-px-12 tw-text-primary"
                            @click="close">
                        Ablehnen
                    </button>

                    <button :disabled="isDisabled"
                            type="button"
                            :class="isDisabled ? 'tw-bg-gray-400 tw-border-gray-400 tw-cursor-not-allowed' : 'tw-bg-primary tw-border-primary'"
                            class="tw-w-full tw-border tw-text-white tw-py-2 tw-px-12"
                            @click="agree">
                        Zustimmen
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createPDF } from "../../../../js/components/PDF";

export default {
    name: "BasePopupCheckbox",
    props: {
        name: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        checked: {
            type: Boolean,
            default: false
        },
        file: {
            type: String,
            default: ""
        },
        errors: {
            type: [String, Array],
            default: () => []
        }
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        isDisabled() {
            return false;
        },
        isSuccess() {
            return this.checked && !this.isOpen;
        },
        isFailed() {
            return this.errors.length > 0;
        },
        classes() {
            let classes = "";

            if (this.isSuccess) {
                classes = "tw-bg-aqua-spring";
            } else if (this.isFailed) {
                classes = "tw-bg-pale-rose";
            } else {
                classes = "tw-bg-pastel-moon-creme";
            }

            return classes;
        }
    },
    methods: {
        open() {
            document.body.classList.add("tw-overflow-hidden");
            this.isOpen = true;
        },
        close() {
            document.body.classList.remove("tw-overflow-hidden");
            this.isOpen = false;
            this.checked = false;
        },
        agree() {
            document.body.classList.remove("tw-overflow-hidden");
            this.isOpen = false;
            this.checked = true;
        }
    },
    mounted() {
        createPDF(this.file, `bp_check-${this._uid}-holder`);
    }
};
</script>

<style scoped>
input[type="checkbox"]:checked ~ span {
    background-color: #3b3b3b;
}

input[type="checkbox"]:checked ~ span:after {
    content: "\2713";
    color: #fff;
    font-size: 14px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 12px;
    line-height: 12px;
}
</style>
