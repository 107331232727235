<template>
    <div class="basefield">
        <label :for="name" v-if="label">{{ label }}</label>
        <input  class="form-control" ref="input"
                :name="name"
                :disabled="disabled"
                :type="type"
                :placeholder="placeholder"
                :min="min"
                :max="max"
                :value="inputValue"
                @input="updateComponent($event)"
        >
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: [String],
                default: ''
            },
            name: {
                type: [String]
            },
            placeholder: {
                type: [String],
                default: ""
            },
            type: {
              type: [String],
              default: 'text'
            },
            required: {
                type: [Boolean],
                default: false
            },
            value: {
                type: [String],
                default: ''
            },
            min: {
                type: [String],
                default: "0",
            },
            max: {
                type: String,
                default: ''
            },
            disabled: {
                type: [Boolean],
                default: false
            },
            debounce: {
                type: [Boolean],
                default: false,
            }
        },
        data() {
            return {
                inputValue: 0
            }
        },
        created() {
            this.inputValue = this.value
        },
        watch: {
            value: function() {
                this.inputValue = this.value
            }
        },
        methods: {
            updateComponent(event) {
               this.debounce ? _.debounce(this.emitToParent, 1000)(event) : this.emitToParent(event)
            },
            emitToParent(event) {
                this.inputValue = this.validate(event.target.value)
                this.$refs.input.value = this.inputValue
                this.$emit('input', this.inputValue)
            },
            validate(value) {
                if(this.min !== "0" && this.min > value) {
                    return this.min
                }

                if(this.max !== '' && this.max < value) {
                    return this.max
                }
                return value
            }
        }
    }
</script>
