<template>
    <div v-if="conversionData" class="conversion-block d-md-flex justify-content-between">
        <div class="conversion-chart">
            <div class="headline">
                <h3>{{ headline }} {{conversionData.total}}</h3>
                <p v-if="subheadline && subheadline.length > 0">{{ subheadline }} {{ conversionData.importedAt }}</p>
            </div>
            <dashboard-pie-chart :chartdata="conversionData.chart.data"
                                 :info-text-empty="infoTextEmpty"
                                 :info-text-one="infoTextOne"
                                 :info-text-value="infoTextValue" />
        </div>
        <div class="conversion-icon">
            <icon-base :icon="icon" className="success" width="80" height="80"/>
            <div class="conversion-rate">
                <h3>{{ percentHeadline }}</h3>
                <p class="conversion-percent">
                    {{ conversionData.percentage }} %
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            chartdata: {
                type: [Array, Object],
                default: () => []
            },
            conversionData: {
                type: [Object],
                default: () => {}
            },
            icon: {
                type: [String],
                default: ''
            },
            translations: {
                type: [Object, Array],
                default: () => []
            },
            headline: {
                type: [String],
                default: ""
            },
            subheadline: {
                type: [String],
                default: ""
            },
            percentHeadline: {
                type: [String],
                default: ""
            },
            infoTextEmpty: {
                type: String,
                default: ''
            },
            infoTextOne: {
                type: String,
                default: ''
            },
            infoTextValue: {
                type: String,
                default: ''
            },
        }
    }
</script>
