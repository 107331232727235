<template>
    <div class="user-status" :class="data.active === 1 ? 'user-status--active' : 'user-status--inactive'">
        <span v-if="data.active === 1">{{ this.translations.active }}</span>
        <span v-else>{{ this.translations.inactive }}</span>
    </div>
</template>

<script>
export default {
    name: 'UserStatus',
    props: {
        data: {},
        meta: {}
    },
    computed: {
        translations() {
            return this.meta.translations ? this.meta.translations : this.meta
        }
    }
}
</script>
