<template>
    <div class="border p-4 mb-5">
        <div class="row">
            <div class="employee col-md-6">
                <span class="personal-number">{{ employee.personal_number }}</span><br>
                {{ employee.email }}<br>
                {{ company  }}
            </div>
            <div class="d-flex align-items-center justify-content-between col-md-2">
                {{ translations.box.registered }}: <user-verified class="w-auto px-3" :data="{email_verified_at : employee.email_verified_at}" />
            </div>
            <div class="d-flex align-items-center justify-content-between col-md-2">
                {{ translations.box.status }}: <user-status class="w-auto px-3" :data="{active: active}" :meta="{translations: translations.box}" />
            </div>
            <div class="d-flex align-items-center justify-content-end col-md-2 font-bold">
                <a class="d-flex align-items-center justify-content-end" :href="url" >
                    <icon-base icon="Edit" className="primary stroke--secondary mr-2" width="34px" height="34px" :stroke-width="2"/>
                    {{ translations.edit }}
                </a>
                <!--
                    <button v-if="active === 1" class="employee-button btn btn-primary border-radius-15" @click="togglePopup">{{ translations.box.deactivate }}</button>
                    <button v-else class="employee-button btn btn-primary border-radius-15" @click="togglePopup">{{ translations.box.activate }}</button>
                -->
            </div>
        </div>

        <PopUp :open="open">
            <div class="container popup">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h2 class="mb-3 text-left">{{ translationsPopup.headline }}</h2>
                        <span class="personal-number">{{ employee.personal_number }}</span><br>
                        <span class="employee">{{ employee.email }}<br>
                        {{ company  }}</span>

                        <Alert v-if="active === 1" class="mt-5 mb-4" :header="translationsPopup.alert.headline" :text="translationsPopup.alert.text" />
                    </div>
                    <div class="col-12 col-sm-4">
                        <button type="button" class="btn close-form btn-block btn-default btn-lg btn-back bc-p b-transparent" @click="togglePopup">
                            {{ translationsPopup.cancel }}
                        </button>
                    </div>
                    <div class="col-12 col-sm-4">
                        <button v-if="active === 1" class="btn btn-primary btn-block" @click="deactivateUser(employee.id)"> {{ translationsPopup.submit }}</button>
                        <button v-else class="btn btn-primary btn-block" @click="activateUser(employee.id)"> {{ translationsPopup.submit }}</button>
                    </div>
                </div>
            </div>
        </PopUp>
    </div>
</template>

<script>
export default {
    name: 'EmployeeDetail',
    props: {
        active: {
            type: Number,
            default: 0
        },
        user: {
            type: String,
            default: ''
        },
        company: {
            type: String,
            default: ''
        },
        additional: {
            type: [Object, Array, String],
            default: ''
        }
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        employee() {
            return JSON.parse(this.user)
        },
        translations() {
            return this.additional.translations || JSON.parse(this.additional.translations)
        },
        url() {
            return this.additional.url
        },
        translationsPopup() {
            return this.active === 1 ? this.translations.popup.deactivate : this.translations.popup.activate
        }
    },
    methods: {
        togglePopup() {
            this.open = !this.open
        },
        deactivateUser(id) {
            window.location.href = `${this.additional.url_deactivate}/${id}`;
        },
        activateUser(id) {
            window.location.href = `${this.additional.url_activate}/${id}`;
        }
    }
}
</script>

<style scoped>
.employee {
    font-size: 1.25rem;
}

.employee-button {
    font-size: 1rem;
    font-weight: normal;
    padding: .15rem 1rem;
}

.personal-number {
    font-size: 1.5rem;
    font-weight: bold;
}

.popup {
    max-height: 100%;
    background: #fff;
    padding: 2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80vw;
    transform: translate(-50%, -50%);
}
</style>
