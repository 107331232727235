<template>
    <div class="row" v-if="order.translations">
        <div class="col-12 col-md-4 name">
            {{order.translations.order_date}} {{order.translations.date}}
        </div>
        <div class="col-12 col-md-4 d-flex">
            <contract-status :order="order"></contract-status>
        </div>
        <div class="col-12 col-md-4 d-flex">
            <span class="name">{{order.translations.delivery_status}}</span>
            <div class="status-delivery">
                <span :class="'status-icon status-delivery--'+order.deliveryClass"></span>
                <span class="delivery-status-name">{{order.status}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import ContractStatus from "./ContractStatus";

    export default{
        props: ['order'],
        components: {
            ContractStatus
        }
    }
</script>

<style>
    .account-history-list .pop-up .attr-item {
        padding: 42px 0;
        font-size: 17px;
        border-bottom: 1px dotted #D5D5D5;
    }
</style>
