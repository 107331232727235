<template>
    <div class="service-detail">
        <span class="ticket-details c-primary b-white" @click="getResponse()">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                stroke="currentColor"
                stroke-width="2"
                viewBox="0 0 40 40"
                class="fill--secondary stroke--secondary">
                    <circle class="cls-1" cx="20" cy="20" r="1.98"/>
                    <circle class="cls-1" cx="32" cy="20" r="1.98"/>
                    <circle class="cls-1" cx="8" cy="20" r="1.98"/>
            </svg>
        </span>
        <pop-up :open="open">
            <div class="popup-header b-primary container t-capitalized">
                <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
                    <span class="f-big">{{ serviceStatus }}</span>
                    <date-text :data="data" :meta="meta"></date-text>
                    <state-text :data="data" :meta="meta"></state-text>
                    <span class="font-bold" @click="popupClose()">
                        <simple-svg :src="closeImage" custom-class-name="stroke--white" />
                    </span>
                </div>
            </div>
            <div class="container b-white o-auto p-0">
                <div class="loader" :class="{isloading: loading}" id="loader-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="popup-body pt-4 pl-4 pr-3" v-html="response"></div>
            </div>
        </pop-up>
    </div>
</template>

<script>
    import {triggerAccordion} from "../../../../themes/default/ServiceAccordion";
    import {sendForm} from "../../../../components/ServiceMessageForm";
    import IconBase from "../../shared/IconBase";
    import DateText from "./DateText";

    export default {
        components: {DateText, IconBase},
        props: {
            data: {},
            meta: {},
            name: {},
            click: {
                type: Function,
                default: () => {
                }
            }
        },
        data() {
            return {
                open: false,
                response: '',
                loading: false
            }
        },
        computed: {
            translations() {
                return this.meta.translations
            },
            url() {
                return `${this.meta.url}/${this.data.id}`
            },
            serviceStatus() {
                return this.meta.translations[`title_${this.data.type}`]
            },
            closeImage(){
                return require('../../../../../svg/CloseIcon.svg')
            }
        },
        methods: {
            getResponse() {
                if (this.response.length === 0) {
                    this.loading = true
                    fetch(this.url)
                        .then(data => data.text())
                        .then(data => this.response = this.getCropHtml(data))
                        .then(() => sendForm())
                        .then(() => triggerAccordion())
                        .then(() => this.loading = false)
                        .catch(err => {
                            this.loading = false
                            this.response = err
                        })
                }
                this.open = true
                document.body.classList.add('o-hidden')
            },
            getCropHtml(response) {
                let div = document.createElement('div')
                div.innerHTML = response
                return div.querySelector('.ticket-detail').innerHTML
            },
            popupClose() {
                this.open = false
                document.body.classList.remove('o-hidden')
            }
        }
    }
</script>

<style>
    .ticket-details:hover {
        cursor: pointer;
    }

    .service-detail {
        display: flex;
        justify-content: flex-end;
    }
</style>
