<template>
    <chart-accordion class="delivery-chart" :headline="translations.service.title">
        <loader v-show="loading"/>

        <chart-filter
            :url="url"
            :translations="additionals.translations"
            :companies="filters"
            @updatedLoader="getUpdatedLoader"
            @updatedData="getUpdatedData"/>

<div class="row">
    <div class="col-6">
        <service-pie-charts :translations="translations" :service-data="allData"></service-pie-charts>
    </div>
    <div class="col-6">
        <div class="row">
            <div class="col-6">
                <service-pie-charts :translations="translations" :service-data="damageData" width="220px" height="220px"></service-pie-charts>
            </div>
            <div class="col-6">
                <service-pie-charts :translations="translations" :service-data="shopData" width="220px" height="220px"></service-pie-charts>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <service-pie-charts :translations="translations" :service-data="leasingData" width="220px" height="220px"></service-pie-charts>
            </div>
            <div class="col-6">
                <service-pie-charts :translations="translations" :service-data="deliveryData" width="220px" height="220px"></service-pie-charts>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-3">
        <service-pie-charts :translations="translations" :service-data="theftData" width="220px" height="220px"></service-pie-charts>
    </div>
    <div class="col-3">
        <service-pie-charts :translations="translations" :service-data="warrantyData" width="220px" height="220px"></service-pie-charts>
    </div>
    <div class="col-3">
        <service-pie-charts :translations="translations" :service-data="transportData" width="220px" height="220px"></service-pie-charts>
    </div>
    <div class="col-3">
        <service-pie-charts :translations="translations" :service-data="lostData" width="220px" height="220px"></service-pie-charts>

    </div>
</div>
    </chart-accordion>
</template>

<script>
export default {
    name: 'ServiceChart',
    props: {
        url: {
            type: String,
            default: ''
        },
        additionals: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            chartData: {},
            loading: false
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        filters() {
            return this.additionals.filters
        },
        translations() {
            return this.additionals.translations
        },
        allData() {
            return this.chartData.all
        },
        damageData() {
            return this.chartData.damage
        },
        shopData() {
            return this.chartData.shop
        },
        leasingData() {
            return this.chartData.leasing
        },
        deliveryData() {
            return this.chartData.delivery
        },
        transportData() {
            return this.chartData.transport
        },
        theftData() {
            return this.chartData.theft
        },
        warrantyData() {
            return this.chartData.warranty
        },
        lostData() {
            return this.chartData.lost
        },
    },
    methods: {
        getUpdatedLoader(value) {
            if (value) {
                this.loading = value
            }
        },
        getUpdatedData(value) {
            console.log(value)
            if (value) {
                this.chartData = value
                this.loading = false
            }
        },
        async fetchData() {
            this.loading = true

            await axios
                .get(this.url)
                .then(response => {
                    this.chartData = response.data
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
                })
        }
    }
}
</script>
