<template>
    <div class="service-view">
        <service-ticket-datatable
            :config_pages="data.perPage"
            :data_columns="data.columns"
            :additional_data="data.additional"
            :url="serviceRoute"
        ></service-ticket-datatable>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: [Array, Object],
                required: true
            },
            translations: {
                type: [Array, Object],
                required: true
            },
            serviceRoute: {
                type: [String],
                required: true
            }
        }
    }
</script>
