<template>
    <div class="calculator-layer">
        <div class="calculator" :class="{loading: isloading}">
            <div class="container mt-3">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="saving-calculator-wrapper">
                                    <div class="saving-calculator">
                                        <div class="row calculator-form-block">
                                            <div class="col-12 col-lg-6">
                                                <label class="saving-label">Kaufpreis inkl.
                                                    Zubehoer und MwSt.</label>
                                                <input type="text" name="purchaseprice" placeholder="z.B.: 2000"
                                                       v-model="formdata.prodPRICEGross" class="saving-input"
                                                       required>
                                                <span v-if="!isBudget" class="info__text--error error-calculator">{{budgetMessage}}</span>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <label class="saving-label">UVP inkl. Zubehoer und
                                                    MwSt.</label>
                                                <input type="text" name="uvp"
                                                       placeholder="falls abweichend vom Kaufpreis"
                                                       v-model="formdata.prodMSRP" class="saving-input">
                                            </div>
                                        </div>
                                        <div class="row calculator-form-block">
                                            <div
                                                class="col-12 col-lg-6 d-flex justify-content-end align-items-end flex-column">
                                                <label
                                                    class="saving-label">Bruttogehalt</label>
                                                <input type="text" name="grosssalary" placeholder="3000"
                                                       v-model="formdata.steuerBRUTTO" class="saving-input"
                                                       required>
                                            </div>
                                            <div
                                                class="col-12 col-lg-6 d-flex justify-content-end align-items-end flex-column">
                                                <label
                                                    class="saving-label">Krankenversicherungsbeitrag
                                                    (AN-Sonderbeitrag)</label>
                                                <select type="text" name="healthinsurance_amount"
                                                        v-model="formdata.steuerKVZ"
                                                        class="saving-select custom-select">
                                                    <option value="0.0">0,0%</option>
                                                    <option value="0.1">0,1%</option>
                                                    <option value="0.2">0,2%</option>
                                                    <option value="0.3">0,3%</option>
                                                    <option value="0.4">0,4%</option>
                                                    <option value="0.5">0,5%</option>
                                                    <option value="0.6">0,6%</option>
                                                    <option value="0.7">0,7%</option>
                                                    <option value="0.8">0,8%</option>
                                                    <option value="0.9">0,9%</option>
                                                    <option value="1.0">1,0%</option>
                                                    <option value="1.1">1,1%</option>
                                                    <option value="1.2">1,2%</option>
                                                    <option value="1.3">1,3%</option>
                                                    <option value="1.4">1,4%</option>
                                                    <option value="1.5">1,5%</option>
                                                    <option value="1.6">1,6%</option>
                                                    <option value="1.7">1,7%</option>
                                                    <option value="1.8">1,8%</option>
                                                    <option value="1.9">1,9%</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div class="row calculator-form-block">
                                            <div
                                                class="col-12 col-lg-6 d-flex justify-content-end align-items-end flex-column">
                                                <label class="saving-label">Bundesland</label>
                                                <select type="text" name="state" v-model="formdata.steuerBL"
                                                        class="saving-select custom-select">
                                                    <option value="BW">Baden-Württemberg</option>
                                                    <option value="BY">Bayern</option>
                                                    <option value="BE">Berlin</option>
                                                    <option value="BB">Brandenburg</option>
                                                    <option value="HB">Bremen</option>
                                                    <option value="HH">Hamburg</option>
                                                    <option value="HE">Hessen</option>
                                                    <option value="MV">Mecklenburg-Vorpommern</option>
                                                    <option value="NI">Niedersachsen</option>
                                                    <option value="NW">Nordrhein-Westfalen</option>
                                                    <option value="RP">Rheinland-Pfalz</option>
                                                    <option value="SL">Saarland</option>
                                                    <option value="SN">Sachsen</option>
                                                    <option value="ST">Sachsen-Anhalt</option>
                                                    <option value="SH">Schleswig-Holstein</option>
                                                    <option value="TH">Thüringen</option>
                                                </select>
                                            </div>
                                            <div
                                                class="col-12 col-lg-6 d-flex justify-content-end align-items-end flex-column">
                                                <label class="saving-label">Steuerklasse</label>
                                                <select type="text" name="taxclass" v-model="formdata.steuerSTKL"
                                                        class="saving-select custom-select">
                                                    <option value="1">Klasse I</option>
                                                    <option value="2">Klasse II</option>
                                                    <option value="3">Klasse III</option>
                                                    <option value="4">Klasse IV</option>
                                                    <option value="5">Klasse V</option>
                                                    <option value="6">Klasse VI</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row calculator-form-block">
                                            <div class="col-12 col-lg-6">
                                                <label class="saving-label">Haben Sie Kinder?
                                                    <input type="checkbox" name="ischildren"
                                                           v-model="formdata.steuerPVZ" @change="customerHasChildren()"
                                                           class="calculator-checkbox" :true-value="0" :false-value="1">
                                                </label>
                                                <label v-if="formdata.steuerPVZ == 0"
                                                       class="saving-label">Kinderfreibetrag</label>
                                                <select v-if="formdata.steuerPVZ == 0" type="text" name="children"
                                                        v-model="formdata.steuerZKF"
                                                        class="saving-select custom-select">
                                                    <option value="0">0</option>
                                                    <option value="0.5">0,5</option>
                                                    <option value="1">1</option>
                                                    <option value="1.5">1,5</option>
                                                    <option value="2">2</option>
                                                    <option value="2.5">2,5</option>
                                                    <option value="3">3</option>
                                                    <option value="3.5">3,5</option>
                                                    <option value="4">4</option>
                                                    <option value="4.5">4,5</option>
                                                    <option value="5">5</option>
                                                    <option value="5.5">5,5</option>
                                                    <option value="6">6</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <label class="saving-label">
                                                    Service-Gutschein
                                                </label>
                                                <div role="group">
                                                    <label for="ohne">
                                                        <input type="radio" id="ohne" name="gift" value="12"
                                                               checked="checked" v-model="formdata.entPCOPTK1">
                                                        ohne
                                                    </label>
                                                    <label for="seventy">
                                                        <input type="radio" id="seventy" name="gift" value="13"
                                                               v-model="formdata.entPCOPTK1"><span>70 €</span></label>
                                                    <label for="hundret">
                                                        <input type="radio" id="hundret" name="gift" value="14"
                                                               v-model="formdata.entPCOPTK1"><span>100 €</span></label>
                                                    <label for="hundretandfifty">
                                                        <input type="radio" id="hundretandfifty" name="gift" value="20"
                                                               v-model="formdata.entPCOPTK1"><span>150 €</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row calculator-form-block">
                                            <div class="col-12 col-lg-6">
                                                <label class="saving-label">
                                                    Übernahme des Rundum-Schutzes
                                                </label>
                                                <div role="group">
                                                    <label class="saving-label" for="arbeitnehmer">
                                                        <input type="radio" id="arbeitnehmer" name="calcAGRS" value="0" checked="checked" v-model="formdata.calcAGRS" />
                                                        Arbeitnehmer</label>
                                                    <label class="saving-label" for="arbeitgeber">
                                                        <input type="radio" id="arbeitgeber" name="calcAGRS" value="1" v-model="formdata.calcAGRS"/>
                                                        <span>Arbeitgeber</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row calculator-form-block">
                                            <div class="col-12 col-lg-6">
                                                <label class="saving-label">
                                                    <input type="checkbox" name="ischurchtax" v-model="formdata.steuerR"
                                                           class="calculator-checkbox" :true-value="1" :false-value="0">
                                                    Kirchensteuerpflicht
                                                </label>
                                                <label class="saving-label">
                                                    <input type="checkbox" name="ispensiontax"
                                                           v-model="formdata.steuerKRV"
                                                           class="calculator-checkbox" :true-value="1" :false-value="0">
                                                    Rentenversicherungspflicht
                                                </label>
                                            </div>
                                            <div class="col-12 col-lg-6 calculator-checkbox-two">
                                                <label class="saving-label">
                                                    <input type="checkbox" name="ishealthinsurance"
                                                           v-model="formdata.steuerKV"
                                                           class="calculator-checkbox" :true-value="1" :false-value="0">
                                                    Krankenversicherungspflicht
                                                </label>
                                                <label class="saving-label">
                                                    <input type="checkbox" name="ispretax" v-model="formdata.calcNETTO"
                                                           class="calculator-checkbox" :true-value="1" :false-value="0">
                                                    Ist Ihr Arbeitgeber vorsteuerabzugsberechtigt?
                                                </label>
                                            </div>
                                            <button @click="sendRequestToCalculator()" :disabled="!isBudgetAndValid"
                                                    class="calculator_submit">
                                                Berechnen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="calculator-saving">
                                    <h2 class="calculator-saving-headline">So viel kann gespart werden:</h2>
                                    <div class="calculator-result">
                                        <div class="row">
                                            <div
                                                class="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column"
                                                v-if="apiData.lsng">
                                                <h3 class="calculator-price">
                                                    {{ apiData.lsng.prettified.saving }}
                                                </h3>
                                                <h5 class="calculator-precent">{{savingInPrecent}}<span
                                                    v-if="typeof savingInPrecent !== 'string'">% gespart!</span></h5>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 263.91 284.18">
                                                    <title>Element 84190318_Eleasa_Web_EleasaTaschenrechner</title>
                                                    <g id="Ebene_2" data-name="Ebene 2">
                                                        <g id="Ebene_2-2" data-name="Ebene 2">
                                                            <circle class="cls-1" cx="101.33" cy="20.51" r="0.93"/>
                                                            <path class="cls-1"
                                                                  d="M92.27,20.51a.93.93,0,0,1-.93.93.91.91,0,0,1-.63-.25l.07-1.42a.92.92,0,0,1,.56-.19A.93.93,0,0,1,92.27,20.51Z"/>
                                                            <path class="cls-1"
                                                                  d="M90.78,19.77l-.07,1.42a.92.92,0,0,1,.07-1.42Z"/>
                                                            <path class="cls-1"
                                                                  d="M175.11,51.26l-.71,2.84L155.71,76.79,149.1,67.9l-7.71-10.36a17,17,0,0,0-13.7-6.92H117.46"/>
                                                            <path class="cls-1"
                                                                  d="M183.26,38.14V27.69a2.55,2.55,0,0,0-2.55-2.55,2.56,2.56,0,0,0-2.55,2.55v10"/>
                                                            <line class="cls-1" x1="192.74" y1="44.53" x2="192.69"
                                                                  y2="44.2"/>
                                                            <path class="cls-1"
                                                                  d="M128.12,116.44,128.39,77l4.92,6,10.77,13.1a12.34,12.34,0,0,0,19.29-.27L187.94,62l5.53-13"/>
                                                            <line class="cls-1" x1="87.81" y1="87.08" x2="91.1"
                                                                  y2="116.71"/>
                                                            <line class="cls-1" x1="87.3" y1="82.47" x2="87.45"
                                                                  y2="83.83"/>
                                                            <path class="cls-1"
                                                                  d="M36.48,129.13l29.91-18.32A42.46,42.46,0,0,0,79.66,96.23l3.4-6.16,4.23-7.65"/>
                                                            <path class="cls-1"
                                                                  d="M101,50.55H90.71a16,16,0,0,0-14,8.23L68.09,77.37,60.61,93.49,31.68,113.78"/>
                                                            <path class="cls-1"
                                                                  d="M87.29,82.42a.07.07,0,0,1,0,0c0,.17.08.7.15,1.36,0,.29.06.6.1.93,0,.16,0,.32,0,.48.12,1.21.23,2.35.23,2.35"/>
                                                            <path class="cls-1"
                                                                  d="M87.29,82.42h0C78.86,73.69,86.73,62,86.73,62"/>
                                                            <path class="cls-1"
                                                                  d="M148.65,10.16c4.37,0,7.31,2.23,7.31,8.12,0,3.6-4.58,6.37-8.16,6.37h2.28c5.88,0,9.07,7.78,7,13.65S148.41,46,142.47,46H117.73"/>
                                                            <path class="cls-1"
                                                                  d="M148.65,10.16a8.53,8.53,0,0,1-1.46.13A9,9,0,0,1,148.65,10.16Z"/>
                                                            <path class="cls-1"
                                                                  d="M90.78,19.77l.31-6.6a8.23,8.23,0,0,1,.16-1.31C92.36,6,98.85.5,105,.5h43.49c2.82,0,5.14,1.67,5.14,5.05,0,1.92-2,4.07-4.95,4.61"/>
                                                            <line class="cls-1" x1="90.51" y1="25.43" x2="90.71"
                                                                  y2="21.19"/>
                                                            <path class="cls-1"
                                                                  d="M117.42,50.7l-17.68,13c-2.89,2.12-6.34,2.35-8.06.54h0c-2.17-2.28-.73-6.74,3-9.25L101,50.7v-.15l.34-7.56H96.8c-4.24,0-7-2.75-6.8-6.79l.26-5.59,0-.91"/>
                                                            <path class="cls-1"
                                                                  d="M105.51,65.27a19.26,19.26,0,0,0-1.36,18.51"/>
                                                            <path class="cls-1"
                                                                  d="M91.26,11.86h21.35c4.41,0,7.24,2.9,7,7.13l-.33,3.62v0"/>
                                                            <polyline class="cls-1"
                                                                      points="95.81 18.5 90.51 25.43 87.24 29.7 90.3 29.7 94.17 29.7"/>
                                                            <path class="cls-1"
                                                                  d="M118.27,31.19c.26,0,.51,0,.76,0,3.08-.24,5.07-2,5.21-4.16s-1.72-4.23-5-4.39h-.43"/>
                                                            <polyline class="cls-1"
                                                                      points="119.04 31.28 117.83 46.02 117.46 50.55"/>
                                                            <line class="cls-1" x1="118.66" y1="251.19" x2="96.51"
                                                                  y2="253.45"/>
                                                            <path class="cls-1"
                                                                  d="M139.72,188.86c8.84,1.25,16.79,4.28,22,11.6a29.55,29.55,0,0,1-2.2,36.94"/>
                                                            <path class="cls-1" d="M117.28,188.09l0-.07h.45"/>
                                                            <line class="cls-1" x1="67.97" y1="265.75" x2="69.75"
                                                                  y2="268.26"/>
                                                            <line class="cls-1" x1="41.48" y1="254.52" x2="52.71"
                                                                  y2="254.52"/>
                                                            <line class="cls-1" x1="120.06" y1="216.33" x2="106.05"
                                                                  y2="216.33"/>
                                                            <path class="cls-1"
                                                                  d="M120.4,207.89a8.3,8.3,0,0,0-4.69,1.58l-9.66,6.86L93.91,199.24l8.1-5.76a29.41,29.41,0,0,1,15.27-5.39l.49,0"/>
                                                            <polyline class="cls-1"
                                                                      points="82.24 234.92 115.32 230.78 119.6 227.74"/>
                                                            <polyline class="cls-1"
                                                                      points="60.68 265.75 55.15 257.96 52.71 254.52 48.94 249.21 65.77 237.26"/>
                                                            <line class="cls-1" x1="118.73" y1="249.3" x2="92.88"
                                                                  y2="251.93"/>
                                                            <path class="cls-1"
                                                                  d="M140.8,197.51a28.83,28.83,0,0,1,2.4,3c2.37,3.34,4,7,5.39,15.29l.06,1.78A29.55,29.55,0,0,1,141,237.38"/>
                                                            <polyline class="cls-1"
                                                                      points="53.22 271.05 47.68 263.26 41.48 254.52 24.17 266.81 35.91 283.34"/>
                                                            <polyline class="cls-1"
                                                                      points="68.69 260.06 65.52 262.31 60.68 265.75 67.97 265.75 69.42 265.75"/>
                                                            <polyline class="cls-1"
                                                                      points="82.41 263.47 96.51 253.45 92.88 251.93 92.87 251.93 82.35 253.01"/>
                                                            <polyline class="cls-1"
                                                                      points="70.1 271.05 53.22 271.05 48.22 274.6 35.91 283.34 46.17 283.34"/>
                                                            <line class="cls-1" x1="67.05" y1="274.37" x2="70.24"
                                                                  y2="272.11"/>
                                                            <path class="cls-1"
                                                                  d="M143.11,237.32h14.44c.66,0,1.32,0,2,.09a21.82,21.82,0,0,1,5.13,1"/>
                                                            <path class="cls-1" d="M156.71,283.36H151"/>
                                                            <path class="cls-1"
                                                                  d="M136.55,238.15a22,22,0,0,1,4.47-.77c.56,0,1.12-.07,1.69-.07h.4A23,23,0,0,1,162.76,249"/>
                                                            <path class="cls-1"
                                                                  d="M157.69,277.81a22.5,22.5,0,0,1-6.73,4"/>
                                                            <path class="cls-1"
                                                                  d="M133.6,263a10,10,0,0,0,.74,1.81A9.48,9.48,0,0,0,149,267.42"/>
                                                            <path class="cls-1"
                                                                  d="M149,253.25a9.49,9.49,0,0,0-14.53,2.32"/>
                                                            <path class="cls-1"
                                                                  d="M149,267.42a9.46,9.46,0,0,0,0-14.17,9.46,9.46,0,0,0,0,14.17Z"/>
                                                            <line class="cls-1" x1="124.94" y1="79.85" x2="128.39"
                                                                  y2="76.97"/>
                                                            <line class="cls-1" x1="148.94" y1="68.02" x2="133.36"
                                                                  y2="82.92"/>
                                                            <polyline class="cls-1"
                                                                      points="68.07 77.35 68.09 77.37 83.06 90.07 83.08 90.09"/>
                                                            <path class="cls-1"
                                                                  d="M34.86,131.53a1.15,1.15,0,0,1-.4.42L18.6,142a1.29,1.29,0,0,1-1.77-.38L.71,117.28a1.28,1.28,0,0,1,.37-1.77l1.23-.8"/>
                                                            <path class="cls-1"
                                                                  d="M31.08,120.8,36.42,129a.67.67,0,0,1,.06.12,1.26,1.26,0,0,1-.43,1.65l-1.19.75L20.19,140.8a1.3,1.3,0,0,1-1.78-.38L6.66,122.7l-4.37-6.59a1.25,1.25,0,0,1,0-1.4,1.15,1.15,0,0,1,.36-.37L18.59,104a1.29,1.29,0,0,1,1.78.38l4.48,6.88,2.74,4.21"/>
                                                            <polygon class="cls-1"
                                                                     points="21.83 110.5 7.23 119.67 4.84 115.85 19.27 106.65 21.83 110.5"/>
                                                            <polyline class="cls-1"
                                                                      points="6.77 122.62 23.19 112.28 24.75 111.3"/>
                                                            <path class="cls-1"
                                                                  d="M13.79,125.21l-.77.48a1.44,1.44,0,0,1-1.52-2.45l.76-.48A1.45,1.45,0,0,1,14.47,124,1.43,1.43,0,0,1,13.79,125.21Z"/>
                                                            <path class="cls-1"
                                                                  d="M19.4,121.16a1.41,1.41,0,0,1-.67,1.1l-.77.48a1.44,1.44,0,0,1-2-.46,1.44,1.44,0,0,1,.46-2l.77-.48a1.45,1.45,0,0,1,.74-.22"/>
                                                            <path class="cls-1"
                                                                  d="M16.33,129.12l-.77.48a1.44,1.44,0,0,1-2-.46,1.44,1.44,0,0,1,.46-2l.77-.48a1.45,1.45,0,0,1,2,.46,1.44,1.44,0,0,1-.46,2Z"/>
                                                            <path class="cls-1"
                                                                  d="M21.26,126.18l-.76.48a1.44,1.44,0,0,1-2-.47,1.43,1.43,0,0,1,.46-2l.77-.48a1.43,1.43,0,0,1,2,.46,1.46,1.46,0,0,1,.22.77A1.43,1.43,0,0,1,21.26,126.18Z"/>
                                                            <path class="cls-1"
                                                                  d="M26.47,121.07a1,1,0,0,1,.15.2,1.42,1.42,0,0,1,.22.76,1.46,1.46,0,0,1-.68,1.23l-.76.47a1.45,1.45,0,0,1-2.21-1.22,1.46,1.46,0,0,1,.68-1.23l.12-.07"/>
                                                            <path class="cls-1"
                                                                  d="M18.9,133l-.77.48a1.44,1.44,0,0,1-1.52-2.45l.76-.48a1.45,1.45,0,0,1,2.21,1.23A1.43,1.43,0,0,1,18.9,133Z"/>
                                                            <path class="cls-1"
                                                                  d="M23.84,130.09l-.77.48a1.44,1.44,0,0,1-2-.46,1.44,1.44,0,0,1,.46-2l.77-.48a1.45,1.45,0,0,1,2,.47,1.39,1.39,0,0,1,.22.75A1.43,1.43,0,0,1,23.84,130.09Z"/>
                                                            <path class="cls-1"
                                                                  d="M28.73,127.17l-.76.48a1.45,1.45,0,0,1-2.21-1.23,1.43,1.43,0,0,1,.68-1.22l.77-.48a1.46,1.46,0,0,1,2,.46,1.42,1.42,0,0,1,.22.76A1.45,1.45,0,0,1,28.73,127.17Z"/>
                                                            <path class="cls-1"
                                                                  d="M26.5,134l-6,3.79a1.41,1.41,0,0,1-1.94-.45l0-.06a1.41,1.41,0,0,1,.45-1.94l6-3.79a1.4,1.4,0,0,1,1.93.45l0,.07A1.4,1.4,0,0,1,26.5,134Z"/>
                                                            <path class="cls-1"
                                                                  d="M31,131.21l-.76.48a1.44,1.44,0,0,1-2-.46,1.42,1.42,0,0,1-.22-.76,1.46,1.46,0,0,1,.68-1.23l.76-.47A1.44,1.44,0,1,1,31,131.21Z"/>
                                                            <path class="cls-1"
                                                                  d="M33.82,120.64l-2.74.16h-.22l-2,.12-2.35.14-2.48.14-3.32.19a2.74,2.74,0,0,1-2.89-2.62v-.11a2.74,2.74,0,0,1,2.56-2.74l5-.32,2.24-.17c.8-.07,1.32-.12,1.34-.14l2.73-1.59"/>
                                                            <line class="cls-1" x1="155.71" y1="76.79" x2="150.17"
                                                                  y2="83.78"/>
                                                            <path class="cls-1"
                                                                  d="M132.32,273.85,133.6,263l.8-6.69.09-.78.72-6.06.92-7.81.31-2.61.11-.94,1.2-10.16,2.38-20.06.41-3.46a44.26,44.26,0,0,0-.25-12.07l-.57-3.54-4.67-29.15"/>
                                                            <path class="cls-1"
                                                                  d="M150.93,279.19a10,10,0,0,0-8.8-5.23h-9.82v-.07"/>
                                                            <line class="cls-1" x1="117.76" y1="273.62" x2="117.53"
                                                                  y2="279.23"/>
                                                            <polyline class="cls-1"
                                                                      points="112.24 159.71 117.76 188.02 117.77 188.06 120.61 202.61 120.55 204.15 120.4 207.89 120.3 210.33 120.06 216.33 119.6 227.74 119.41 232.58 118.73 249.3 118.66 251.19 117.76 273.6"/>
                                                            <polyline class="cls-1"
                                                                      points="82.43 279.84 82.48 279.84 82.48 275.18 82.48 274.58 82.41 263.47 82.35 253.01 82.26 238.7 82.24 234.92 82.08 209.14 100.97 159.71"/>
                                                            <path class="cls-1"
                                                                  d="M76.35,159.71,65.8,190.48a58.07,58.07,0,0,0-2.67,26.19l2.64,20.59,1.54,12,1.38,10.83.73,5.69.33,2.51.35,2.79.14,1.06.29,2.26H57.08a13.5,13.5,0,0,0-7.44,2.24,13.11,13.11,0,0,0-3.42,3.23"/>
                                                            <polygon class="cls-1"
                                                                     points="91.1 116.71 58.18 159.71 76.35 159.71 100.97 159.71 107.33 159.71 112.24 159.71 135.05 159.71 155.2 159.71 128.12 116.44 91.1 116.71"/>
                                                            <line class="cls-1" x1="93.64" y1="132.12" x2="81.19"
                                                                  y2="159.51"/>
                                                            <polyline class="cls-1"
                                                                      points="107.64 133.99 107.54 142.52 107.33 159.51"/>
                                                            <line class="cls-1" x1="119.47" y1="133.68" x2="129.42"
                                                                  y2="159.51"/>
                                                            <path class="cls-1"
                                                                  d="M46.22,279.84s-.39,3.23,0,3.5l0,0H82.43v-3.52s-12.64-.06-23-.07H51.89C48.52,279.78,46.26,279.8,46.22,279.84Z"/>
                                                            <path class="cls-1"
                                                                  d="M117.53,279.23v4.45H151v-4.49h0l-16.74,0H117.53Z"/>
                                                            <polyline class="cls-1"
                                                                      points="70.53 274.37 76.64 276.58 82.41 274.58"/>
                                                            <line class="cls-1" x1="205.54" y1="169.28" x2="215.11"
                                                                  y2="169.28"/>
                                                            <polyline class="cls-1"
                                                                      points="214.78 210.16 201.86 283.48 185.4 283.48"/>
                                                            <path class="cls-1"
                                                                  d="M117.67,273.6l.09,0c.74.18,5.94,1.42,7.52,1.76l.45.08c.27,0,5.59-1.32,6.59-1.57l.16,0"/>
                                                            <path class="cls-1" d="M224.22,212.79c-.31,0-.6,0-.88-.07"/>
                                                            <path class="cls-1" d="M225.16,212.82h-.31l-.34,0"/>
                                                            <path class="cls-1"
                                                                  d="M225.92,166.79h14.46a23,23,0,1,1,0,46.05h-14.5"/>
                                                            <polygon class="cls-1"
                                                                     points="185.4 283.48 173.15 283.48 156.69 283.48 156.71 283.36 157.69 277.81 162.76 249.04 164.64 238.43 176.83 169.28 193.28 169.28 205.54 169.28 202.55 186.26 185.4 283.48"/>
                                                            <path class="cls-1"
                                                                  d="M225.92,166.79a23,23,0,0,1,0,46h-.72c-.22,0-.44,0-.65,0h-.29c-.3,0-.59,0-.88-.07a1.53,1.53,0,0,1-.3,0,23,23,0,0,1,2.5-45.91Z"/>
                                                            <path class="cls-1"
                                                                  d="M231.85,182.72a9.47,9.47,0,0,0-12.16-.39,9.61,9.61,0,0,0-2.49,2.92,9.5,9.5,0,0,0,0,9.06c.1.18.2.35.31.52a9.46,9.46,0,0,0,14.36,2.06"/>
                                                            <path class="cls-1"
                                                                  d="M231.85,196.89a9.46,9.46,0,0,0,0-14.17,9.48,9.48,0,0,0,0,14.17Z"/>
                                                            <path class="cls-1"
                                                                  d="M193.47,49a2.39,2.39,0,0,1-1.27-3.14l.54-1.29,1-2.36a2.4,2.4,0,0,1,4.6.94,2.35,2.35,0,0,1-.19.93l-1.54,3.65A2.39,2.39,0,0,1,193.47,49Z"/>
                                                            <path class="cls-1"
                                                                  d="M189.26,40l.72-1.7a2.4,2.4,0,0,1,4.6.94,2.35,2.35,0,0,1-.19.93l-1.7,4-.35.81a2.39,2.39,0,0,1-4.52-.33"/>
                                                            <path class="cls-1"
                                                                  d="M183.94,38.35l.48-1a2.72,2.72,0,1,1,4.91,2.33l-.15.32"/>
                                                            <path class="cls-1"
                                                                  d="M187.07,44.45l-.29.6a2.72,2.72,0,0,1-5-2.2"/>
                                                            <path class="cls-1"
                                                                  d="M175.11,51.26l.43-10a4.4,4.4,0,0,1,5.69-3.79l2,.64.68.21L189.18,40l.08,0,.54.17a2.36,2.36,0,0,1,1.48,1.31A2.44,2.44,0,0,1,189.07,45a2.5,2.5,0,0,1-.71-.11l-.54-.16-.75-.23-5.26-1.6-.74-.22.18,1L182.39,50"/>
                                                            <path class="cls-1" d="M90.13,35.48c.5.07,8,0,8,0"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="calculator-gross">
                                    <h4>Die individuelle Nutzungrate beträgt:</h4>
                                    <h3>{{ apiData.lsng.prettified.diff }}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loader" :class="{isloading: isloading}"></div>
        <div class="calculator-summary-wrapper eleasa-element" :class="{loading: isloading}">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="summary-section-headline">So errechnen sich die monatlichen Kosten:</h2>
                        <div class="col-12 calculator-summary">
                            <div class="calculation-information">
                                <div class="row striped-bold summary-headline">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span>Gehaltsabrechnung</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span>ohne Dienstfahrrad</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span>mit Dienstfahrrad</span>
                                        <span class="sub-text">inkl. Rundum-Schutz</span>
                                    </div>
                                </div>
                                <div class="row striped">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span class="table-col-bold">Bruttomonatsgehalt</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span class="table-col-bold">{{apiData.cash.prettified.brutto}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span class="table-col-bold">{{apiData.lsng.prettified.brutto}}</span>
                                    </div>
                                </div>
                                <div class="row striped bold">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span>Brutto-Nutzungsrate</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span>0,00 €</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span>- {{apiData.lsng.prettified.minus}}</span>
                                    </div>
                                </div>
                                <div class="row striped-bold bold">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span>Geldwerter Vorteil (0,5% Regel)</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span>{{ getLocalePrice(0) }}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span>{{apiData.lsng.prettified.plus}}</span>
                                    </div>
                                </div>
                                <div class="row striped">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span class="table-col-bold">Berechnungsgrundlage</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span class="table-col-bold">{{apiData.cash.prettified.brutto}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span class="table-col-bold">{{apiData.lsng.prettified.basis}}</span>
                                    </div>
                                </div>
                                <div class="row striped bold">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span>Steuern gesamt</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span>- {{apiData.cash.prettified.steuern}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span>- {{apiData.lsng.prettified.steuern}}</span>
                                    </div>
                                </div>
                                <div class="row striped-bold bold">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span>Sozialabgaben gesamt</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span>- {{apiData.cash.prettified.sozial}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span>- {{apiData.lsng.prettified.sozial}}</span>
                                    </div>
                                </div>
                                <div class="row bold">
                                    <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                        <span class="table-col-bold">Nettomonatsgehalt</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col text-center">
                                        <span class="table-col-bold">{{apiData.cash.prettified.zahlung}}</span>
                                    </div>
                                    <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                        <span class="table-col-bold">{{apiData.lsng.prettified.zahlung}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="netto-diff">
                            <span>Differenz Nettomonatsgehalt = Individuelle Nutzungsrate: <span
                                class="netto-diff-price">{{apiData.lsng.prettified.diff}}</span></span>
                        </div>

                        <div class="summary">
                            <h2 class="summary-section-headline">So errechnet sich die Ersparnis zum Privatkauf:</h2>
                            <div class="col-12 calculator-summary">
                                <div class="calculation-information-private">
                                    <div class="row striped-bold summary-headline">
                                        <div class="offset-sm-4 table-col">
                                        </div>
                                        <div class="col-6 col-sm-4 table-col text-center">
                                            <span>Privatkauf mit Schutz</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                            <span>Dienstfahrrad + Rundum-Schutz</span>
                                        </div>
                                    </div>
                                    <div class="row striped">
                                        <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                            <span>(E-)Bike</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col text-center">
                                            <span>{{ getLocalePrice(formdata.prodPRICEGross) }}</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                            <span>(36 Monate x {{apiData.lsng.prettified.diff}}) {{apiData.lsng.prettified.endprice}}</span>
                                        </div>
                                    </div>
                                    <div class="row striped bold">
                                        <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                            <span>zzgl. Rundum-Schutz (Gehaltsumwandlung)</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col text-center">
                                            <span>{{apiData.cash.prettified.kasko}}</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                            <span>{{apiData.lsng.prettified.kasko}}</span>
                                        </div>
                                    </div>
                                    <div class="row striped bold" v-if="serviceGift[formdata.entPCOPTK1] !== '0'">
                                        <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                            <span>zzgl. Service-Gutschein</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col text-center">
                                            <span>{{getLocalePrice(serviceGift[formdata.entPCOPTK1] * 3)}}</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                            <span>inklusive</span>
                                        </div>
                                    </div>
                                    <div class="row striped">
                                        <div class="col-12 col-sm-4 table-col table-text-center-mobile">
                                            <span>Restwert 13%</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col text-center">
                                            <span>{{ getLocalePrice(0) }}</span>
                                        </div>
                                        <div class="col-6 col-sm-4 table-col table-text-right table-text-center-mobile">
                                            <span>{{ thirteenPrecent }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="netto-diff">
                                <span>Barkauf minus Dienstfahrrad = Ersparnis von: <span class="netto-diff-price">{{ apiData.lsng.prettified.saving }} ({{savingInPrecent}}%)</span></span>
                            </div>
                            <div class="summary-info">
                                <span>Berechnung ohne Gewähr. Bitte wenden Sie sich an Ihren Steuerberater, um eine verbindliche Kalkulation zu erhalten.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "calculator",
        props: {
            url: String,
            token: String,
            formresponse: Object,
            request: Object
        },

        data() {
            return {
                isloading: false,
                formdata: {
                    prodPRICEGross: 0,
                    prodMSRP: '',
                    steuerBRUTTO: 0,
                    steuerKVZ: "0.0",
                    steuerBL: "BW",
                    steuerSTKL: true,
                    steuerZKF: 0,
                    steuerPVZ: 0,
                    entPCOPTK1: 12,
                    steuerR: false,
                    steuerKRV: false,
                    steuerKV: false,
                    calcNETTO: false,
                    calcAGRS: false
                },
                apiData: {},
                header: {
                    "Authorization": "Bearer " + this.token,
                    "Accept": "application/x.calculator.v1+json",
                    "cache-control": "no-cache"
                },
                serviceGift: {
                    12: "0",
                    13: "70",
                    14: "100",
                    20: "150"
                },
                calculatorValues: {
                    min: 250,
                    max: 7500
                }
            }
        },
        watch: {
            isloading() {
                document.body.classList.toggle('isloading')
            }
        },
        computed: {
            onePrecentAdvantage() {
                return new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(this.apiData.cash.startprice / 100);
            },
            savingInPrecent() {
                return this.apiData.lsng.saving ? parseInt((100 / this.apiData.cash.endprice) * this.apiData.lsng.saving) : "Keine Ersparnis";
            },
            thirteenPrecent() {
                return new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format((this.formdata.prodPRICEGross * 0.13));
            },
            isValidated() {
                return (parseFloat(this.formdata.prodPRICEGross) !== 0 && parseFloat(this.formdata.prodPRICEGross) > 0) &&
                    (parseFloat(this.formdata.steuerBRUTTO) !== 0 && parseFloat(this.formdata.steuerBRUTTO) > 0)
            },
            isBudget() {
                return parseFloat(this.formdata.prodPRICEGross) > parseFloat(this.calculatorValues.min) && parseFloat(this.formdata.prodPRICEGross) < parseFloat(this.calculatorValues.max)
            },
            budgetMessage() {
                if (parseInt(this.formdata.prodPRICEGross) < this.calculatorValues.min) {
                    return 'Der angegebene Preis ist zu niedrig'
                } else if (parseInt(this.formdata.prodPRICEGross) > this.calculatorValues.max) {
                    return 'Der angegebene Preis ist zu hoch'
                }
                return ''
            },
            isBudgetAndValid() {
                return this.isBudget && this.isValidated
            }
        },
        async mounted() {
            this.formdata = await this.request;
        },
        created() {
            this.apiData = this.formresponse;
        },
        methods: {
            sendRequestToCalculator() {
                this.isloading = true;
                this.formdata.prodMSRP = (this.formdata.prodMSRP === '0' || this.formdata.prodMSRP === '') ? this.formdata.prodPRICEGross : this.formdata.prodMSRP
                this.formdata.steuerBRUTTO = this.formdata.steuerBRUTTO.split('.').join(',')
                this.formdata.prodMSRP = this.formdata.prodMSRP.split('.').join(',')
                this.formdata.prodPRICEGross = this.formdata.prodPRICEGross.split('.').join(',')
                window.axios.default.post(this.url + "/api/calculatevelo", this.formdata, {headers: this.header})
                    .then(data => {
                        this.apiData = data.data
                        setTimeout(() => {
                            document.getElementsByTagName('body')[0].classList.remove('loading')
                            this.isloading = false;
                        }, 1000)
                    })
            },

            getLocalePrice(price) {
                return new Intl.NumberFormat('de-DE', {
                    style: 'currency',
                    currency: 'EUR'
                }).format(price);
            },

            changeCheckboxValues(value) {
                value = value == 1 ? 0 : 1
            },
            customerHasChildren() {
                if (this.formdata.steuerPVZ === 1) {
                    this.formdata.steuerZKF = 0
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../sass/_variables.scss";

    .custom-select {
        padding: 0 1.75rem 0 .75rem;
    }

    .loader {
        display: none;
    }

    .loader.isloading {
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        border: 3px solid #ddd;
        border-top: 3px solid $black;
        border-radius: 50%;
        height: 75px;
        width: 75px;
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        z-index: 1000;
        margin-left: -32px;
        margin-top: -100px;
        filter: blur(0px);
    }

    .loading {
        filter: blur(2px);
        background-color: $white;
        opacity: .5;
        z-index: 2;
    }

    .loading * {
        pointer-events: none;
    }

    @-webkit-keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .calculator-price {
        font-family: "Bradford Medium Italic", sans-serif;
        font-size: 50px;
    }

    .calculator-precent {
        font-size: 30px;
    }

    .calculator-saving-headline {
        font-size: 50px;
        padding-bottom: 35px;
        font-family: "Neuzeit Regular", sans-serif;
    }

    .summary-info {
        padding: 25px 50px;
        border: 1px solid $black;
        border-radius: 40px;
        margin: 50px 0;
    }

    .summary-section-headline {
        font-family: "Neuzeit Regular", sans-serif;
        margin-top: 50px;
        font-size: 50px;
    }

    .table-text-right {
        text-align: right;
    }

    .calculator-summary .summary-headline span.sub-text {
        font-size: 22px;
        font-family: "Neuzeit Regular", sans-serif;
        display: block;
    }

    .netto-diff-price {
        float: right;
        font-family: "Bradford Medium", sans-serif;
        font-size: 30px;
    }

    .table-col-bold {
        font-weight: bold;
    }

    .table-col {
        padding: 15px 0;
    }

    .summary-headline {
        font-family: "Bradford Medium Italic", sans-serif;
        padding-bottom: 10px;
    }

    .calculator-summary .summary-headline span {
        font-size: 30px;
    }

    .container {
        max-width: 1399px;
    }

    .cls-1 {
        fill: none;
        stroke: $white;
        stroke-miterlimit: 10;
    }

    .calculator p,
    .calculator label,
    .calculator span,
    .calculator h1,
    .calculator h2,
    .calculator h3,
    .calculator h4,
    .calculator h5 {
        color: $white;
    }

    .calculator-saving {
        border: 1px solid $white;
        border-radius: 20px;
        padding: 20px;
    }

    .saving-headline {
        padding: 40px 0 10px 0;
        font-family: "Bradford Medium", sans-serif;
    }

    .saving-calculator {
        padding: 25px 23px;
    }

    .calculator_submit {
        margin: 0 auto;
        padding: 12px 60px;
        font-size: 20px;
        border: 0 none;
        margin-top: 60px;
        font-family: "Bradford Medium", sans-serif;
        border-radius: 10px;
        background-color: $white;
    }

    .saving-label {
        font-size: 18px;
        margin: 0;
        color: $white;
        width: 100%;
        font-family: "Neuzeit Regular", sans-serif;
    }

    .saving-input {
        width: 100%;
        font-size: 18px;
        height: 35px;
        border: 0 none;
        padding-left: 20px;
    }

    .saving-select {
        width: 100%;
        font-size: 18px;
        height: 35px;
    }

    .calculator-checkbox {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .calculator-headline {
        font-size: 10px;
        color: $white;
        margin: 0;
    }


    .calculator-custom {
        display: inline-block;
        width: unset;
        padding-right: 15px;
    }

    input[type="radio"] {
        margin-right: 5px;
    }

    .calculator-price-wrapper {
        padding-top: 35px;
    }

    .calculator-price-saving,
    .calculator-price-leasing {
        color: $black;
        font-size: 23px;
    }

    .calculator-gross {
        border: 1px solid $white;
        border-radius: 20px;
        padding: 10px 10px 10px 30px;
        margin-top: 30px;
        font-family: "Bradford Medium Italic", sans-serif;
    }

    .calculator-summary span {
        font-size: 16px;
    }

    div[role='group'] {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
    }

    div[role='group'] label > span {
        position: relative;
        bottom: -2.5px;
    }

    .error-calculator {
        display: block !important;
    }


    @media (max-width: 767px) {
        .table-text-center-mobile {
            text-align: center;
        }

        .netto-diff {
            padding: 60px 10px;
        }

        svg {
            display: block;
            margin: 0 auto;
            max-height: 330px;

        }

        .calculator-summary .summary-headline span.sub-text {
            font-size: 18px;
        }
    }

    @media(max-width: 678px) {
        .calculator-summary .summary-headline span {
            font-size: 20px;
        }

        .summary-headline .sub-text {
            font-size: 18px;
        }

        .calculator-saving-headline,
        .summary-section-headline {
            font-size: 30px;
        }

        .calculator-price {
            font-size: 30px;
        }

        .netto-diff-price {
            float: none;
            font-size: 26px;
            display: block;
            text-align: center;
            padding-top: 18px;
        }

        .calculator-gross h3 {
            text-align: center;
        }

        div[role="group"] {
            padding-bottom: 25px;
        }
    }

    @media(min-width: 768px) and (max-width: 1143px) {
        .calculator-price {
            font-size: 30px;
        }

        .summary-headline .sub-text {
            font-size: 18px;
        }
    }

    @media(min-width: 992px) and (max-width: 1162px) {
        div[role='group'] {
            display: block;
            padding-bottom: 25px;
        }

        div[role='group'] label {
            display: block;
        }

        div[role='group'] label > span {
            position: relative;
            bottom: -1px;
        }
    }

</style>
