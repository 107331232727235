<template>
    <div v-if="!data.email_verified_at" class="send-invite">
        <svg    xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                width="36"
                height="36"
                viewBox="0 0 40 40"
                class="stroke--secondary"
                @click="togglePopup">
            <polygon stroke="none" class="fill--secondary" points="18.5 28.85 26 28.85 26 34.01 33.5 27.14 26 20.26 26 25.42 18.5 25.42 18.5 28.85"/>
            <polyline class="cls-1" points="15.42 26.69 6.5 26.69 6.5 10.26 31.95 10.26 31.95 21.76"/>
            <path class="cls-2" d="M6.5,11.26l9.93,6.79a4,4,0,0,0,4.95,0l9.93-6.79"/>
        </svg>

        <PopUp :open="open">
            <div class="container popup">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h2 class="mb-3 text-left">{{ translations.headline }}</h2>
                        <p>{{ translations.content }}</p>
                    </div>
                    <div class="col-12 col-sm-5">
                        <button type="button" class="btn close-form btn-block btn-default btn-lg btn-back bc-p b-transparent" @click="togglePopup">
                            {{ translations.cancel }}
                        </button>
                    </div>
                    <div class="col-12 col-sm-5">
                        <button type="submit" class="btn btn-primary btn-block" @click="sendInvite(data)">
                            {{ translations.send }}
                        </button>
                    </div>
                </div>
            </div>
        </PopUp>
    </div>
</template>

<script>
    export default {
        props: {
            data: {},
            meta: {},
            name: {},
            url: {}
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            translations() {
                return this.meta.translations ? this.meta.translations : ''
            }
        },
        methods: {
            togglePopup() {
                this.open = !this.open
            },
            sendInvite(data, meta) {
                window.location.href = `${this.meta.url}/${data.id}`;
            }
        },
    }
</script>

<style scoped>
.send-invite {
    text-align: center;
    cursor: pointer;
}

.popup {
    max-height: 100%;
    background: #fff;
    padding: 2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50vw;
    transform: translate(-50%, -50%);
}
</style>
