<template>
    <div class="tw-mb-4">
        <div class="tw-b-4 tw-border-2 tw-border-gray-300 tw-rounded-t-lg" :class="{'tw-block': opened,'tw-hidden': !opened,}">
            <slot></slot>
        </div>
        <div class="accordion_head tw-px-6 tw-py-4 tw-bg-gray-300 tw-flex tw-items-center tw-justify-between" :class="{'tw-rounded-b-lg': opened, 'tw-rounded': !opened, }" @click="opened = !opened">
            <div class="tw-flex">
                <simple-svg v-if="svgImage" :src="svgImage" custom-class-name="stroke--white tw-mr-4" />
                <span class="tw-text-white tw-text-base" v-html="name"></span>
            </div>
            <span class="tw-text-white tw-text-xl" >
                <span class="tw-text-base" v-if="percentage">(~{{ percentage }} %) </span>
                <span class="tw-font-bold tw-mr-2"> {{ price }} €</span>
                <svg class="tw-w-3 tw-transition-all tw-duration-200 tw-transform"
                    :class="{
                        'tw-rotate-180': opened,
                        'tw-rotate-0': !opened,
                    }"
                    fill="none"
                    stroke="white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 10"
                    aria-hidden="true"
                    >
                    <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
        </div>

    </div>
</template>


<script>

    export default {
        name: "calculator-accordion",
        props: {
            icon: {
                default: 'info', type: String
            },
            percentage: {
                default: '', type: String
            },
            name: {
                default: '', type: String
            },
            price: {
                default: '0 €', type: String
            },
            open: {
                default: true, type: Boolean
            }
        },
        computed: {
            closeImage(){
            return require('../../../../svg/Alert/Close.svg')
        },
            svgImage(){
                if(this.icon){
                    return require('../../../../svg/'+ this.icon+'.svg')
                }
                return false
            }
        },
        data() {
            return {
                opened: false
            }
        },
        created() {
            this.opened = this.open
        },
        components: {
         //   InlineSvg,
        }
    }
</script>

<style>
a.tw-underline{
    text-decoration:underline !important;
}
.accordion_head{
    cursor: pointer;
}

</style>
