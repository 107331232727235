<template>
    <label class="justify-content-end" :for="`user_select_${data.id}`">
        <input :id="`user_select_${data.id}`" type="radio" :value="data.id" name="user_id">
        {{meta.text}}
    </label>
</template>

<script>
    export default {
        props: {
            data: {},
            meta: {},
            classes: {
                default: {}, type: Object
            },
        },
    }
</script>
