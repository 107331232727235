<template>
    <div class="administration__actions">
        <div class="row">
            <div class="col-12 col-sm-4">
                <a :href="route" class="btn close-form btn-block btn-default btn-lg btn-back bc-p b-transparent">{{ translations.cancel }}</a>
            </div>
            <div class="col-12 col-sm-4">
                <button type="submit" @click="sendForm($event)" class="btn btn-primary btn-block employee-submit">{{ translations.save }}</button>
            </div>
        </div>
        <PopUp :open="popupOpen">
            <div class="container popup">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h2 class="mb-3 text-left">{{ translation.headline }}</h2>
                        <span class="personal-number">{{ user.personal_number }}</span><br>
                        <span class="employee">{{ user.email }}<br>
                        {{ this.company.name }}</span>

                        <Alert v-if="activeUser === false" class="mt-5 mb-4" :header="translation.alert.headline" :text="translation.alert.text" />
                    </div>
                    <div class="col-12 col-sm-4">
                        <button type="button" class="btn close-form btn-block btn-default btn-lg btn-back bc-p b-transparent" @click="togglePopup">
                            {{ translation.cancel }}
                        </button>
                    </div>
                    <div class="col-12 col-sm-4">
                        <button class="btn btn-primary btn-block"> {{ translation.submit }}</button>
                    </div>
                </div>
            </div>
        </PopUp>
    </div>
</template>

<script>
    export default {
        props: ['route', 'translations', 'user', 'company'],
        data() {
            return {
                activeUserOld: false,
                activeUser: false,
                popupOpen: false
            }
        },
        computed: {
            activeUserChanged() {
                return this.activeUser === this.activeUserOld
            },
            translation() {
                const activeString = this.activeUser ? 'activate' : 'deactivate'
                return this.translations[activeString]
            }
        },
        mounted() {
            this.activeUserOld = document.querySelector('#activate').checked
        },
        methods: {
            togglePopup() {
                this.popupOpen = !this.popupOpen
            },
            sendForm(e) {
                if (Object.keys(this.user).length > 0 || this.user.length > 0) {
                    this.activeUser = document.querySelector('#activate').checked
                    if (!this.activeUserChanged) {
                        e.preventDefault()
                        this.popupOpen = true
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .employee {
        font-size: 1.25rem;
    }

    .employee-button {
        font-size: 1rem;
        font-weight: normal;
        padding: .15rem 1rem;
    }

    .personal-number {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .popup {
        max-height: 100%;
        background: #fff;
        padding: 2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 80vw;
        transform: translate(-50%, -50%);
    }
</style>
