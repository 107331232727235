<template>
    <span>
        <a href="#" @click="togglePopup">
            <slot/>
        </a>

        <PopUp :open="open">
            <div class="container popup">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h2 class="mb-3 text-left">{{ translations.headline }}</h2>
                        <p>{{ translations.content }}</p>
                    </div>

                    <div class="col-12">
                        <p>{{ translations.info }}</p>
                        <Alert class="mb-4" :header="translations.alert.headline" :text="translations.alert.text"/>
                    </div>

                    <div class="col-12 col-sm-6">
                        <button type="button" class="btn close-form btn-block btn-default btn-lg btn-back bc-p b-transparent" @click="togglePopup">
                            {{ translations.cancel }}
                        </button>
                    </div>

                    <div class="col-12 col-sm-6">
                        <button type="submit" class="btn btn-primary btn-block" @click="startExport()">
                            {{ translations.export }}
                        </button>
                    </div>
                </div>
            </div>
        </PopUp>
    </span>
</template>

<script>
    export default {
        name: 'ExportEmployerBonus',
        props: {
            url: {
                type: String,
                default: ''
            },
            translations: {
                type: [Array, Object, String],
                default: ''
            }
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            togglePopup(e) {
                e.preventDefault()
                this.open = !this.open
            },
            startExport() {
                if (this.url) {
                    window.location.href = `${this.url}`;
                }
            }
        }
    }
</script>

<style scoped>
    .popup {
        max-height: 100%;
        background: #fff;
        padding: 2rem;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 50vw;
        transform: translate(-50%, -50%);
    }
</style>
