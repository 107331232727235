<template>
    <label :for="name" class="p-0 m-0 d-flex">
        <input class="switch"
               :id="name" type="checkbox"
               :name="name"
               v-model="isChecked"
               :value="isChecked ? 1 : 0"
               @change="changeValue" />
    </label>
</template>

<script>
    export default {
        props: ['name', 'checked'],
        data() {
            return {
                isChecked: false,
            }
        },
        created() {
            this.isChecked = this.checked
        },
        methods: {
            changeValue() {
                this.$emit('changeValue', this.isChecked)
            }
        }
    }
</script>
