var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-view"},[_c('general-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.generalUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}}),_vm._v(" "),_c('users-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.usersUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}}),_vm._v(" "),_c('delivery-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.deliveryUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}}),_vm._v(" "),_c('company-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.companyUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}}),_vm._v(" "),_c('device-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.deviceUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}}),_vm._v(" "),_c('supplier-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.supplierUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}}),_vm._v(" "),_c('service-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.serviceUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}}),_vm._v(" "),_c('contract-chart',{staticClass:"chart-view__item",attrs:{"url":_vm.contractUrl,"additionals":{
            filters: _vm.companies,
            translations: _vm.translations
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }