<template>
    <div class="bars">
        <div v-show="!isChartdataEmpty" class="bars__row" v-for="(chart, index) in charts" :key="index">
            <div class="bars__label">
                <span class="bars__name">{{ chart['name'] }}</span>
                <span class="bars__subline">{{ chart['subline'] }}</span>
            </div>

            <div class="bars__bars">
                <div class="bars__item" v-for="(item, index) in chart.data" :key="index">
                    <span   :title="`${Math.round(item.width)} %`"
                            :style="`width: ${item.width}%;  background-color: ${colors[index]}` "
                            :class="`bars__bar`">
                    </span>
                    <span class="bars__value">
                        {{ item.value }} {{ item.name }}
                    </span>
                </div>
            </div>

            <div class="bars__rate" v-if="chart['conversion']">
                <span>{{ translations.company_conversion.quote }}</span>
                <span class="bars__percentage">{{ chart['conversion'] }}</span>
            </div>
        </div>
        <info-component v-show="isChartdataEmpty" :info-text="infoText" :icon-center="false"></info-component>
    </div>
</template>

<script>
export default {
    name: 'BarsChartElement',
    props: {
        charts: {
            type: [Array, Object],
            default: () => []
        },
        colors: {
            type: [Array, Object],
            default: () => []
        },
        translations: {
            type: [Array, Object],
            default: () => []
        },
        infoText: {
            type: [String],
            default: ''
        }
    },
    computed: {
        isChartdataEmpty() {
            return this.charts.length === 0 || (this.charts.length <= 1 && this.charts[0].data[0].width === 0)
        }
    },
    methods: {
        replaceText(needle, haystack, replacer) {
            return haystack.split(needle).join(replacer)
        }
    },
}
</script>
