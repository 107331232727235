<template>
    <div class="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
        <div class="tw-space-x-12">
            <span class="order-nr">
                {{order.translations.ordernumber}}
                <strong>{{order.translations.client}}</strong>
            </span>
            <span class="order-date">
                {{ order.translations.order_date }}
                <strong>{{ order.translations.date }}</strong>
            </span>
        </div>
        <span v-if="action" class="show-details border-radius-15" @click="getOrder(order.url)">
                <span class="show-details--text">{{order.translations.show_details}}</span>
            </span>
        <span v-else></span>
    </div>
</template>
<script>
import { openVueOrderReports } from "../../../components/MyAccount";

export default {
    props: {
        order: {
            type: [Array, Object]
        },
        action: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            history: ""
        };
    },
    methods: {
        getOrder(url) {
            if (this.history.length === 0) {
                this.$emit("loading", true);
                fetch(url)
                    .then(data => data.text())
                    .then(data => {
                        this.history = this.parseAccountHistory(data).innerHTML;
                        this.$emit("history", this.history);
                        return data;
                    })
                    .then(data => {
                        if (
                            this.hasTempElementClass(
                                data,
                                ".order-report-button"
                            )
                        ) {
                            openVueOrderReports();
                        }
                        this.$emit("loading", false);
                    })
                    .catch(err => {
                        this.$emit("loading", true);
                        this.$emit("history", err);
                    });
            }
            this.$emit("open", true);
            document.body.classList.add("o-hidden");
        },
        parseAccountHistory(html) {
            let div = document.createElement("div");
            div.innerHTML = html;
            return div.querySelector(".aimeos.account-history");
        },
        hasTempElementClass(html, className) {
            let div = document.createElement("div");
            div.innerHTML = html;
            return div.querySelectorAll(className).length > 0;
        }
    }
};
</script>
