<template>
    <span class="f-big">{{ text }}</span>
</template>

<script>
    export default {
        props: ['meta', 'data'],
        computed: {
            text() {
                return this.meta.translations[`title_${this.data.type}`]
            }
        }
    }
</script>
