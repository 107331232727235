<template>
    <div class="order-tracking-line d-none d-sm-grid">
        <div v-if="orderStep !== 0" v-for="i in 8" class="track-step" :class="getOrderStatusClass(i)"></div>
    </div>
</template>
<script>
    export default {
        props: ['orderStep'],
        methods: {
            // get classname for step amount and for bigger arrows
            getOrderStatusClass(index) {
                let className = index <= this.orderStep ? `coloring--background` : ''
                if (index === 5 || index === 7) {
                    className += ' arrow-big'
                }
                return className
            }
        }
    }
</script>

<style lang="scss">
    .order-tracking-line {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 3fr 3fr 4fr;
        grid-column-gap: 1px;
        padding: .5rem 0;
        width: 100%;
    }

    .order-tracking-line div {
        background-color: #e2e2e2;
        height: 8px;
        position: relative;
    }

    .order-tracking-icon {
        margin-right: 25px;
    }

    @media(min-width: 668px) {
        .d-sm-grid {
            display: grid !important;
        }
    }
</style>
