<template>
    <div class="state-manager d-flex align-items-center">
        <span class="font-bold" :style="{color: isChecked ? '#83c582' : '#a60015'}">{{ translation.form.status[activeStateTranslations] }}</span>
        <enable-button :name="name" :checked="isChecked" @changeValue="isChecked = $event"></enable-button>
        <span class="font-bold">{{ translation.form.status[`${activeStateTranslations}_label`] }}</span>
    </div>
</template>

<script>
    export default {
        props: ['name', 'checked', 'translations'],
        data() {
          return {
              isChecked: !!this.checked
          }
        },
        computed: {
            translation() {
                return JSON.parse(this.translations)
            },
            activeStateTranslations() {
                return this.isChecked ? 'activate' : 'deactivate'
            }
        }
    }
</script>

<style scoped>
    .state-manager {
        margin-top: 50px;
        margin-bottom: 40px;
    }

    span:first-child {
        display: inline-block;
        margin-right: 1rem;
        width: 40px;
    }

    span:last-child {
        display: inline-block;
        margin-left: 1rem;
    }
</style>
