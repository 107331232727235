<template>
    <div class="info-content-pie flex-column justify-content-center align-items-center"
         :style="{width: _size, height: _size, backgroundColor: _backgroundColor}"
         :class="borderClassName">
         <div v-if="empty" class="text-center">
            <icon-base v-if="infoTextEmpty" icon="InfoIcon" width="30px" height="30px"  className="info" />
            <span v-if="empty" class="info-content-pie--text" :class="'c-'+className">{{ infoTextEmpty }}</span>
         </div>
        <div v-else class="info-content-pie--one">
            <icon-base v-if="infoTextOne" icon="InfoIcon" width="30px" height="30px"  className="info" />
            <span class="info-content-pie--text" :class="'c-'+className">{{ infoTextOne }}</span>
            <h3 class="info-content-pie--value d-block" :class="'c-'+className" v-html="infoTextValue"></h3>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            infoTextEmpty: {
                type: String,
                default: ''
            },
            infoTextOne: {
                type: String,
                default: ''
            },
            infoTextValue: {
                type: String,
                default: ''
            },
            empty: {
                type: Boolean,
                default: true
            },
            backgroundColor: {
                type: String,
                default: '#fff'
            },
            size: {
                type: String
            }
        },
        data() {
            return {
                _size: ''
            }
        },
        created() {
            this._size = this.size !== '' ? this.size : '250px'
        },
        computed: {
            _backgroundColor() {
                return this.empty ? '#fff' : this.backgroundColor
            },
            borderClassName() {
                return this.empty ? 'info-border' : 'transparent-border'
            },
            className() {
                return this.empty ? 'info' : 'white'
            }
        }
    }
</script>
