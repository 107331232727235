<template>
    <div class="state">
        <span :class="className"></span>
        <span>{{ statusText }}</span>
    </div>
</template>

<script>
    export default {
        props: ['meta', 'data'],
        computed: {
            translations() {
                return this.meta.translations
            },
            statusText() {
                return this.translations[`status_${this.data.status}`]
            },
            className() {
                return `type${this.data.status}`
            }
        }
    }
</script>
