<template>
    <div class="delivery-chart d-flex align-items-center mb-3">
        <div class="company-order-chart">
            <h3 class="text-center">{{ deliveryData.headline }}</h3>
            <dashboard-pie-chart
                width="480px"
                height="480px"
                :chartdata="deliveryData.data"
                legend-class="legend-grid legend-grid--3"
                class-name="center mb-3"
                :info-text-empty="infoTranslations.info_statistics_delivery_per_company_empty"
                :info-text-one="infoTranslations.info_statistics_orders_per_company_one"
                :info-text-value="infoTranslations.info_statistics_orders_per_company_value"></dashboard-pie-chart>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            deliveryData: {
                type: [Array, Object]
            },
            translations: {
                type: [Array, Object],
                default: () => []
            }
        },
        computed: {
            infoTranslations() {
                return this.translations.info
            }
        }
    }
</script>

<style scoped>
    h3 {
        margin-top: 3.125rem;
        text-transform: none;
        font-weight: 700;
        margin-bottom: 1.75rem;
    }
    .delivery-chart{
        justify-content: center;
    }
</style>
