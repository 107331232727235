<template>
    <div class="chart-filter" :class="{'chart-filter--simple' : !datesFilterable}">
        <div v-if="datesFilterable" class="chart-filter__content">
            <div class="chart-filter__headline">
                <h3>{{ translations.filter.headline_time }}</h3>
                <p>{{ translations.filter.text_time }}</p>
            </div>

            <div class="chart-filter__inputs">
                <base-field
                    class="chart-filter__input"
                    name="startdate"
                    placeholder="dd/mm/yyyy"
                    type="date"
                    :label="translations.filter.start_date"
                    :max="limit"
                    v-model="formData.startdate" />

                <base-field
                    class="chart-filter__input"
                    name="enddate"
                    placeholder="dd/mm/yyyy"
                    type="date"
                    :label="translations.filter.end_date"
                    v-model="formData.enddate"
                    :min="formData.startdate"
                    :max="limit"
                    :disabled="formData.startdate === ''" />
            </div>
        </div>

        <div class="chart-filter__content">
            <div class="chart-filter__headline">
                <h3>{{ translations.filter.headline_company}}</h3>
                <p>{{ translations.filter.text_company}}</p>
            </div>

            <div>
                <label for="company_select" v-if="translations.filter.show_data">
                    {{ translations.filter.show_data }}
                </label>
                <div class="select-value">
                    <select
                        class="chart-filter__select form-control"
                        name="company_select"
                        :disabled="companies.length === 0"
                        v-model="formData.company">
                        <option value="" selected v-if="translations.filter.select.all_companies">
                            {{ translations.filter.select.all_companies }}
                        </option>
                        <option v-for="(value, index) in companies" :key="index" :value="value.value">
                            {{ value.text }}
                        </option>
                    </select>
                </div>
            </div>

            <info-component
                v-if="companies.length === 0"
                :infoText="translations.filter.select_empty_info"
                :icon-center="false" />
        </div>

        <div class="chart-filter__submit">
            <button
                class="chart-filter__button btn btn-primary border-radius-15"
                :class="{'chart-filter__button--disabled': this.loading}"
                :disabled="this.loading"
                @click="updateData">
                {{ translations.filter.submit }}
            </button>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: 'ChartFilter',
    props: {
        url: {
            type: String,
            default: ''
        },
        datesFilterable: {
            type: Boolean,
            default: true
        },
        translations: {
            type: [Array, Object],
            default: () => []
        },
        companies: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            formData: {
                startdate: '',
                enddate: '',
                company: ''
            },
            loading: false,
            response: {},
            limit: moment().format('YYYY-MM-DD')
        }
    },
    watch: {
        "formData.startdate": function() {
            if (this.formData.startdate === '') {
                this.formData.enddate = ''
            }
        }
    },
    computed: {
        getRequestParams() {
            return {
                startdate: this.formData.startdate ? moment(this.formData.startdate).format('YYYY-MM-DD') : '',
                enddate: this.formData.startdate ? moment(this.formData.enddate).format('YYYY-MM-DD') : '',
                company: this.formData.company
            }
        }
    },
    methods: {
        async updateData() {
            this.loading = true
            this.$emit('updatedLoader', this.loading)

            await axios
            .get(this.url, { params: this.getRequestParams })
            .then(response => {
                this.response = response.data
                this.loading = false
            })
            .catch(error => {
                console.log(error)
                this.loading = false
            })

            this.$emit('updatedData', this.response)
        }
    }
}
</script>

<style lang="scss" scoped>
label {
    font-weight: bold;
}

.chart-filter {
    display: grid;
    grid-gap: 1.5rem 2rem;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    background: #F3F3F3;

    &--simple {
        display: flex;
        flex-direction: column;

        .chart-filter__content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            grid-gap: 2rem;
            margin-bottom: 1rem;
        }
    }

    &__content {
        grid-column: auto;
        grid-row: 1;
        display: flex;
        flex-direction: column;
    }

    &__headline {
        margin-bottom: 1rem;

        & + * {
            margin-top: auto;
        }
    }

    &__inputs {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
    }

    &__input {
        width: 100%;
    }

    &__select {
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        width: 100%;
        outline: 0 none;
        box-shadow: none;
        padding: .9rem;
        transition: ease border-color .3s;
        margin: 0 0 1rem 0;

        &:hover {
            border-color: #8D8D8D;
        }
    }

    &__submit {
        grid-column: 1 / 3;
        grid-row: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__button {
        font-size: 1rem;
        font-weight: normal;
        padding: .15rem 1rem;

        &--disabled {
            cursor: not-allowed;
            background: #E3E3E3;
            border: 1px solid #E3E3E3;
        }
    }
}
</style>
