<template>
    <span>
        <a href="#" @click="togglePopup">
            <slot />
        </a>

        <PopUp :open="open">
            <div class="container popup">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h2 class="mb-3 text-left">{{ translations.headline }}</h2>
                        <p v-html="translations.content" />
                        <ul class="list">
                            <li v-for="item in translations.list" :key="item">{{ item }}</li>
                        </ul>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <base-field name="startdate"
                                            placeholder="dd/mm/yyyy"
                                            type="date"
                                            :label="translations.start"
                                            v-model="startdate"
                                            :max="limit" />
                            </div>
                            <div class="col-12 col-md-6">
                                <base-field name="enddate"
                                            placeholder="dd/mm/yyyy"
                                            type="date"
                                            :label="translations.end"
                                            v-model="enddate"
                                            :min="startdate"
                                            :disabled="startdate === ''"/>
                            </div>
                        </div>
                    </div>

                    <div class="tw-m-4">
                        <label class="tw-block tw-text-left" style="max-width: 400px;">
                            <span class="tw-text-gray-700">{{ translations.contract_completed }}</span>
                            <select class="tw-border-b tw-border-l tw-border-r tw-border-t tw-rounded tw-form-select tw-block tw-w-full tw-mt-1 tw-p-1" name="contract_completed" @change="selectedChange($event)" :value="selectDefaultValue" :disabled="states.length === 0">
                                <option v-for="(v,i) in states" :key="i" :value="v.value">{{ v.text }}</option>
                            </select>
                        </label> 
                    </div>
                      
                    <div class="col-12">
                        <p>{{ translations.info }}</p>
                        <Alert class="mb-4" :header="translations.alert.headline" :text="translations.alert.text"/>
                    </div>

                    <div class="col-12 col-sm-6">
                        <button type="button" class="btn close-form btn-block btn-default btn-lg btn-back bc-p b-transparent" @click="togglePopup">
                            {{ translations.cancel }}
                        </button>
                    </div>
                    <div class="col-12 col-sm-6">
                        <button type="submit"
                                class="btn btn-block"
                                :class="exportable ? 'btn-primary' : ''"
                                :disabled="!exportable"
                                @click="startExport()">
                            {{ translations.export }}
                        </button>
                    </div>
                </div>
            </div>
        </PopUp>
    </span>
</template>

<script>
import moment from 'moment'


export default {
    name: 'ExportContract',
    props: {
        url: {
            type: String,
            default: ''
        },
        translations: {
            type: [Array, Object, String],
            default: ''
        },
        states: {
            type: [Array, Object, String],
            default: ''
        }
    },
    data() {
        return {
            open: false,
            startdate: '',
            enddate: '',
            selected: null,
            selectDefaultValue: '',
            limit: moment().format('YYYY-MM-DD')
        }
    },
    computed: {
        exportable() {
            return (this.startdate !== '' && this.enddate !== '')
        }
    },
    methods: {
        togglePopup(e) {
            e.preventDefault()
            this.open = !this.open
        },
        selectedChange(event) {
            this.selectDefaultValue = event.target.value
            this.$emit('change', this.selectDefaultValue)
        },
        startExport() {
            if (this.url && this.startdate && this.enddate) {
                window.location.href = `${this.url}?start=${this.startdate}&end=${this.enddate}&contract_completed=${this.selectDefaultValue}`;
            }
        }
    }
}
</script>

<style scoped>
.list {
    padding-left: 1rem;
}

.popup {
    max-height: 100%;
    background: #fff;
    padding: 2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50vw;
    transform: translate(-50%, -50%);
}
</style>
