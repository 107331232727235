<template>
    <div class="loader">
        <svg class="circular" viewBox="25 25 50 50" width="100" height="100">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Loader'
    }
</script>

<style scoped>
.loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,0.7);
    width: 100% !important;
    height: 100%;
    z-index: 10000;
    cursor: not-allowed;
}


.loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.circular {
    animation: rotate 2s linear infinite;
    transform-origin: center center;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #395563;
  }
}
</style>
