<template>
    <div class="service-accordion">
        <div class="service-accordion-header d-flex justify-content-between align-items-center" :class="{'open': open}" @click="open = !open">
            <h3>{{ headline }}</h3>
            <icon-base icon="ChevronDown" className="primary fill--primary"/>
        </div>
        <div class="service-accordion-content" v-if="open">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    /* TODO change chevron icon and make a base arrow component */
    export default {
        props: {
            headline: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                open: false
            }
        }
    }
</script>
