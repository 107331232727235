<template>
    <a :href="link">
        <slot />
    </a>
</template>

<script>
export default {
    name: 'BaseLink',
    props: {
        link: {
            type: String,
            default: ''
        }
    }
}
</script>
