<template>
    <div class="order-view account-history-list">
        <user-datatable :config_pages="data.perPage"
                        :data_columns="data.columns"
                        :additional_data="data.additional"
                        :url="orderRoute">
        </user-datatable>
    </div>
</template>


<script>
    export default {
        props: {
            data: {
                type: [Array, Object],
                required: true
            },
            translations: {
                type: [Array, Object],
                required: true
            },
            orderRoute: {
                type: [String],
                required: true
            }
        }
    }
</script>
