<template>
    <div class="container">
        <div class="row">
            <div class="filezone">
                <span class="font-regular upload-btn c-white no-m b-primary border-radius-15">{{button_text}}</span>
                <input type="file" id="files" ref="files" v-if="multiple == 'false'" v-on:change="handleFiles()"/>
                <input type="file"  id="files" ref="files" v-else multiple v-on:change="handleFiles()"/>
            </div>
        </div>
        <div class="files-wrapper" v-if="files.length > 0">
            <div v-for="(file, key) in files" class="file-listing">
                <div class="invalid-feedback" v-if="file.errorMessage">
                    {{file.errorMessage}}
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-4">
                            <img :title="file.name" class="preview" v-bind:ref="'preview'+parseInt(key)"/>
                        </div>
                        <div class="col-4">
                            {{file.name}}
                        </div>

                        <div class="col-2">
                            <div class="success-container" v-if="file.id > 0">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M11 11v19h19V19.8h2V32H9V9h19.5v2H11zm23.5-1l1.3 1.5-15 13.7-7.1-8.1 1.5-1.3 5.7 6.5L34.5 10z" fill="#00da71"/></svg>
                                <input type="hidden" :name="input_name" :value="file.uploadPath"/>
                            </div>
                            <div class="error-container" v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M11 11v19h19V11H11zm9.5 8.1l-5.3-5.3-1.4 1.4 5.3 5.3-5.3 5.3 1.4 1.4 5.3-5.3 5.3 5.3 1.4-1.4-5.3-5.3 5.3-5.3-1.4-1.4-5.3 5.3zM9 9h23v23H9V9z" fill="#eb0046"/></svg>
                            </div>
                        </div>
                        <div class="remove-container col-2">
                            <a class="remove" v-on:click="removeFile(key)">
                                <svg class="icon loeschen" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 23 23">
                                    <defs>
                                        <path d="M22.084 2.966v1.5H19.84l-1.286 15.439a2.205 2.205 0 01-2.198 2.022H5.94a2.205 2.205 0 01-2.198-2.022L2.454 4.465H.212v-1.5H6.76V2.26c0-1.164.903-2.118 2.047-2.2l.158-.005h4.365c1.218 0 2.206.988 2.206 2.205l-.001.705h6.549zm-3.748 1.5H3.959L5.236 19.78c.03.366.336.647.703.647h10.418a.705.705 0 00.703-.647l1.276-15.314zM9.715 8.059v8.775h-1.5V8.06h1.5zm4.365 0v8.775h-1.5V8.06h1.5zm-.75-6.504H8.965a.705.705 0 00-.705.705v.706h5.776V2.26a.705.705 0 00-.706-.705z" id="a99"/>
                                    </defs>
                                    <g transform="translate(.75 .75)" fill="none" fill-rule="evenodd">
                                        <mask id="b99" fill="#fff">
                                            <use xlink:href="#a99"/>
                                        </mask>
                                        <g mask="url(#b99)" fill="#849CA8">
                                            <path d="M-3.75-3.75h30v30h-30z"/>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            input_name: {
                type: String,
                default: ''
            },
            post_url: {
                type: String,
                default: ''
            },
            button_text: {
                type: String,
                default: ''
            },
            button_text_submit: {
                type: String,
                default: ''
            },
            prod_id: {
                type: String,
                default: '0'
            },
            multiple: {
                type: String,
                default: "true"
            },
        },
        data() {
            return {
                files: []
            }
        },
        methods: {
            removeFile(key) {
                this.files.splice(key, 1);
                this.getImagePreviews();
            },
            handleFiles() {
                if(this.multiple == 'false'){
                    this.files.splice(0, this.files.length);
                }
                let uploadedFiles = this.$refs.files.files;
                for (var i = 0; i < uploadedFiles.length; i++) {
                    this.files.push(uploadedFiles[i]);
                }
                this.getImagePreviews();
                this.submitFiles();
            },
            getImagePreviews() {
                for (let i = 0; i < this.files.length; i++) {
                    if (/\.(jpe?g|png|gif)$/i.test(this.files[i].name)) {
                        let reader = new FileReader();
                        reader.addEventListener("load", function () {
                            this.$refs['preview' + parseInt(i)][0].src = reader.result;
                        }.bind(this), false);
                        reader.readAsDataURL(this.files[i]);
                    } else {
                        this.$nextTick(function () {
                            if (/\.(pdf)$/i.test(this.files[i].name)) {
                                this.$refs['preview' + parseInt(i)][0].src = '/img/svg/pdf.svg';
                            } else if (/\.(csv)$/i.test(this.files[i].name)) {
                                this.$refs['preview' + parseInt(i)][0].src = '/img/svg/csv.svg';
                            } else {
                                this.$refs['preview' + parseInt(i)][0].src = '/img/svg/imagefile.svg';
                            }
                        });
                    }
                }
            },
            submitFiles() {
                for (let i = 0; i < this.files.length; i++) {
                    if (this.files[i].id) {
                        continue;
                    }
                    let formData = new FormData();
                    formData.append('file', this.files[i]);
                    formData.append('prod_id', this.prod_id);
                    axios.post(this.post_url,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(function (data) {
                        console.log(data)
                        this.files[i].id = data['data']['id'];
                        this.files[i].uploadPath = data['data']['uploadPath'];
                        this.files[i].errorMessage = data['data']['message'];
                        this.files.splice(i, 1, this.files[i]);
                        console.log('success');
                    }.bind(this)).catch(function (data) {
                        console.log('error');
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .files-wrapper{
        margin: 0 -15px 30px -15px;
    }
    input[type="file"] {
        opacity: 0;
        left: 0;
        top: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        right: 0;
        position: absolute;
        cursor: pointer;
    }

    .error-container,
    .success-container{
        width: 40px;
        display: block;
        margin: 0 auto;
        height: 40px;
    }
    .filezone {
        position: relative;
        display: inline-block;
        padding: 5px 0 15px;
    }

    div.file-listing img {
        max-width: 90%;
    }

    div.file-listing {
        margin: auto;
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    div.file-listing .row {
        align-items: center;
    }

    div.file-listing img {
        height: 75px;
    }

    div.success-container {
        text-align: center;
        color: green;
    }

    div.remove-container {
        text-align: center;
    }

    div.remove-container a {
        width: 40px;
        display: block;
        height: 40px;
        cursor: pointer;
        margin: 16px auto;
    }
</style>
