<template>
    <chart-accordion class="delivery-chart" :headline="translations.delivery.title">
        <loader v-show="loading" />

        <chart-filter
            :url="url"
            :translations="additionals.translations"
            :companies="filters"
            @updatedLoader="getUpdatedLoader"
            @updatedData="getUpdatedData" />

        <delivery-pie-charts :translations="translations" :delivery-data="deliveryData"></delivery-pie-charts>
    </chart-accordion>
</template>

<script>
export default {
    name: 'DeliveryChart',
    props: {
        url: {
            type: String,
            default: ''
        },
        additionals: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            chartData: {},
            loading: false
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        filters() {
            return this.additionals.filters
        },
        translations() {
            return this.additionals.translations
        },
        deliveryData() {
            return this.chartData
        },
    },
    methods: {
        getUpdatedLoader(value) {
            if(value) {
                this.loading = value
            }
        },
        getUpdatedData(value) {
            if(value) {
                this.chartData = value
                this.loading = false
            }
        },
        async fetchData() {
            this.loading = true

            await axios
                .get(this.url)
                .then(response => {
                    this.chartData = response.data
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
            })
        }
    }
}
</script>

<style scoped>
.company-chart {
    position: relative;
    border: 2px solid #F1F1F1;
}
</style>
