import {triggerAccordion} from "../themes/default/ServiceAccordion";

function sendForm() {
    Array.from(document.querySelectorAll('.ticket-update form')).forEach((form, index) => {
        form.querySelector('.send-btn').addEventListener('click', ev => {
            ev.preventDefault()
            const formData = new FormData(form)
            fetch(form.action, {method: 'POST', body: formData})
                .then(() => {
                    document.getElementsByTagName('textarea')[index].value = ''
                    const ticketUrl = form.action
                    fetch(ticketUrl).then(data => data.text())
                        .then(data => document.getElementsByClassName('ticket-articles')[index].innerHTML = getTicketsFromResponse(data))
                        .then(() => triggerAccordion())
                })
        })
    })
}

function getTicketsFromResponse(html) {
    let div = document.createElement('div')
    div.innerHTML = html
    return div.querySelector('.ticket-articles').innerHTML
}

function replaceFileUploadLabel() {
    if(document.getElementById('file-upload')) {
        const fileUpload = document.getElementById('file-upload')
        fileUpload.addEventListener('change', ev => {
            if(ev.target.files.length !== 0) {
                const div = document.querySelector('.file-list')
                Array.from(ev.target.files).map(file => {
                    const span = document.createElement('span')
                    span.classList.add('file-name')
                    span.innerHTML = file.name + ' ' + getSVG()
                    div.append(span)
                })
            }
        })
    }
}

function getSVG() {
    return `<svg class="icon loeschen" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 23 23">
    <defs>
        <path d="M22.084 2.966v1.5H19.84l-1.286 15.439a2.205 2.205 0 01-2.198 2.022H5.94a2.205 2.205 0 01-2.198-2.022L2.454 4.465H.212v-1.5H6.76V2.26c0-1.164.903-2.118 2.047-2.2l.158-.005h4.365c1.218 0 2.206.988 2.206 2.205l-.001.705h6.549zm-3.748 1.5H3.959L5.236 19.78c.03.366.336.647.703.647h10.418a.705.705 0 00.703-.647l1.276-15.314zM9.715 8.059v8.775h-1.5V8.06h1.5zm4.365 0v8.775h-1.5V8.06h1.5zm-.75-6.504H8.965a.705.705 0 00-.705.705v.706h5.776V2.26a.705.705 0 00-.706-.705z" id="a99"/>
    </defs>
    <g transform="translate(.75 .75)" fill="none" fill-rule="evenodd">
        <mask id="b99" fill="#fff">
            <use xlink:href="#a99"/>
        </mask>
        <g mask="url(#b99)" fill="#849CA8">
            <path d="M-3.75-3.75h30v30h-30z"/>
        </g>
    </g>
</svg>`
}

export {sendForm, replaceFileUploadLabel}
