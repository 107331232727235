/**
 * First we will load all of this project"s JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
// Polyfills.
import { initPolyfills } from "./helpers/polyfills";
initPolyfills();

import { getCookie, setCookie } from "./components/CookieHandler";
import {
    createInputObject,
    getCalculatedPrices,
    getDecodeData
} from "./components/ProductPrices";
import { SlickSlider } from "./components/SlickSlider";
import { openAccordion } from "./components/Accordion";
import { changeBasket } from "./components/Basket";
import { openInfoBox } from "./components/InfoBoxes";
import { hasClass } from "./helpers/ClassHelper";
import { Note } from "./components/Note";
import { createNuevPDF, createInsurancePDF, createPrivacyPDF } from "./components/PDF";
import { cookieCheck } from "./components/CookieConsent";
import {
    initNewAddress,
    initPopUp,
    openNewAddressForm
} from "./components/DeliveryUpdatePopup";
import VueInitHelper from "./helpers/VueInitHelper";
import Vue from "vue";
import { replaceFileUploadLabel } from "./components/ServiceMessageForm";
import { lightBoxHook } from "./hooks/AimeosHooks";
import { DatePicker } from "./components/DatePicker";
import { notify } from "./components/Notify";

import DataTable from "laravel-vue-datatable";
import Chart from 'chart.js'
import Chartkick from 'vue-chartkick'
import ChartAnnotationsPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import VueSimpleSVG from 'vue-simple-svg'

Chart.plugins.register(ChartAnnotationsPlugin)
Chart.plugins.register(ChartDataLabels)

Vue.use(Chartkick.use(Chart));
Vue.use(DataTable);
Vue.use(require("vue-moment"));
// Vue recursive component initialisation
const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split("/")
            .pop()
            .split(".")[0],
        files(key).default
    )
);

require("./bootstrap");
require("jquery.mmenu");


Vue.use(VueSimpleSVG)

const vue = new VueInitHelper("#mpp");
vue.initVue();
if (document.querySelector(".bu-datepicker")) {
    const datepicker = new DatePicker(".bu-datepicker").initDatePicker();
}
Vue.component("upload-files", require("./vue/UploadFiles.vue"));


jQuery(document).ready(function() {
    cookieCheck();
    initHomepageSlider();
    resizeHomepage();
    openAccordion();
    replaceFileUploadLabel();
    lightBoxHook();

    if (document.getElementById("nuev-holder")) {
        createNuevPDF();
    }

    if (document.getElementById("insurance-holder")) {
        createInsurancePDF();
    }

    if (document.getElementById("privacy-holder")) {
        createPrivacyPDF();
    }

    let input = "#option-nuev-accept";

    $("body").on(
        "click",
        ".checkout-standard-summary-option-nuev .single",
        function(e) {
            e.preventDefault();
            $(".nuev-container").addClass("show");
            $("body").addClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".nuev-container .nuev-button-group .btn-primary",
        function(e) {
            e.preventDefault();
            $(".nuev-container").removeClass("show");
            $(input).attr("checked", true);
            $(input).prop("checked", true);
            $("input[name=cs_option_nuev_value]").val(1);
            $("body").removeClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".nuev-container .nuev-button-group .btn-primary--light",
        function(e) {
            e.preventDefault();
            $(".nuev-container").removeClass("show");
            $(input).attr("checked", false);
            $(input).prop("checked", false);
            $("input[name=cs_option_nuev_value]").val(0);
            $("body").removeClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".checkout-standard-summary-option-insurance .single",
        function(e) {
            e.preventDefault();
            $(".insurance-container").addClass("show");
            $("body").addClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".insurance-container .insurance-button-group .btn-primary",
        function(e) {
            e.preventDefault();
            $(".insurance-container").removeClass("show");
            $("#option-insurance-accept").attr("checked", true);
            $("#option-insurance-accept").prop("checked", true);
            $("input[name=cs_option_insurance_value]").val(1);
            $("body").removeClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".insurance-container .insurance-button-group .btn-primary--light",
        function(e) {
            e.preventDefault();
            $(".insurance-container").removeClass("show");
            $("#option-insurance-accept").attr("checked", false);
            $("#option-insurance-accept").prop("checked", false);
            $("input[name=cs_option_insurance_value]").val(0);
            $("body").removeClass("overflow-hidden");
        }
    );


    $("body").on(
        "click",
        ".checkout-standard-summary-option-accessories .single",
        function(e) {
            e.preventDefault();
            $(".accessories-container").addClass("show");
            $("body").addClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".accessories-container .accessories-button-group .btn-primary",
        function(e) {
            e.preventDefault();
            $(".accessories-container").removeClass("show");
            $("#option-accessories-accept").attr("checked", true);
            $("#option-accessories-accept").prop("checked", true);
            $("input[name=cs_option_accessories_value]").val(1);
            $("body").removeClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".accessories-container .accessories-button-group .btn-primary--light",
        function(e) {
            e.preventDefault();
            $(".accessories-container").removeClass("show");
            $("#option-accessories-accept").attr("checked", false);
            $("#option-accessories-accept").prop("checked", false);
            $("input[name=cs_option_accessories_value]").val(0);
            $("body").removeClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".privacy-input #privacy",
        function(e) {
            e.preventDefault();
            $(".insurance-container").addClass("show");
            $("body").addClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".insurance-container .insurance-button-group .btn-primary",
        function(e) {
            e.preventDefault();
            $(".insurance-container").removeClass("show");
            $("#privacy").attr("checked", true);
            $("#privacy").prop("checked", true);
            $("input[name=privacy]").val(1);
            $(".privacy-input").addClass("input-success");
            $("body").removeClass("overflow-hidden");
        }
    );

    $("body").on(
        "click",
        ".insurance-container .insurance-button-group .btn.c-primary",
        function(e) {
            e.preventDefault();
            $(".insurance-container").removeClass("show");
            $("#privacy").attr("checked", false);
            $("#privacy").prop("checked", false);
            $("input[name=privacy]").val(0);
            $(".privacy-input").removeClass("input-success");
            $("body").removeClass("overflow-hidden");
        }
    );

    $("body").on("click", ".pagination .limit .limit-select", function(ev) {
        let parent = ev.target.parentNode;
        while (!hasClass(parent, "limit")) {
            parent = parent.parentNode;
        }
        $(parent)
            .children(".limit-select-content")
            .toggleClass("d-block");
    });

    $("body").on("click", ".pagination .sort .sort-select", function(ev) {
        let parent = ev.target.parentNode;
        while (!hasClass(parent, "sort")) {
            parent = parent.parentNode;
        }
        $(parent)
            .children(".sort-select-content")
            .toggleClass("d-block");
    });
});

window.onload = () => {
    if (document.getElementById("note")) {
        let note = new Note(document.getElementById("note"), 3000);
        note.triggerNote();
    }
    changeBasket();
    if (getCookie("calcdata") === null) {
        setCookie();
    }
    if (typeof $(".calculate") !== "undefined") {
        $(".calculate").on("click", function(e) {
            setCookie();
        });
    }
    if (document.querySelector(".show-form")) {
        openNewAddressForm();
    }
    initializeProductsOnPage();
    if (document.querySelector(".pop-up-layer")) {
        initPopUp();
    }

    if (document.getElementById("ca_mpp_deliveryoption-null")) {
        initNewAddress();
    }

    notify();
};

if ($(window).width() < 768) {
    openInfoBox();
    jQuery(document).ready(function($) {
        if ($(".navigation")) {
            $(".navigation .meta-navigation")
                .children()
                .appendTo("#menu > ul");
        } else {
            $(".header-right .meta-navigation")
                .children()
                .appendTo("#menu > ul");
        }
        $(".header").addClass("mm-slideout");
        $("#menu").mmenu({
            extensions: ["pagedim-black"]
        });
        const api = $("#menu").data("mmenu");
        api.bind("open:finish", () => {
            setSelected();
            closeMobileMenuPanel();
            openActiveList();
            document
                .querySelector(".mm-panel_has-navbar")
                .classList.add("mm-panel_opened");
            document
                .querySelector(".mm-panel_has-navbar")
                .classList.remove("mm-hidden");
        });
    });
}

function openActiveList() {
    $(".cat-item.selected").each((index, el) => {
        $(el)
            .parent()
            .parent()
            .removeClass("mm-hidden");
        $(el)
            .parent()
            .parent()
            .addClass("mm-panel_opened");
    });
}

function closeMobileMenuPanel() {
    $(".mm-panel.mm-panel_has-navbar").each((index, el) => {
        $(el).addClass("mm-hidden");
        $(el).removeClass("mm-panel_opened");
    });
}

function setSelected() {
    $(".cat-item.mm-listitem__text").each((index, el) => {
        if (
            $(el).attr("href") ===
            window.location.pathname + window.location.search
        ) {
            $(el)
                .parent()
                .addClass("selected");
        }
    });
}

function initializeProductsOnPage() {
    if ($(".price-list").length !== 0) {
        const salary = JSON.parse(getDecodeData());
        const inputObj = createInputObject(salary.salary);
        getCalculatedPrices(inputObj);
    }
}

function initSlider(
    el,
    options = {
        prevArrow: '<div class="a-left control-c prev slick-prev"></div>',
        nextArrow: '<div class="a-right control-c next slick-next"></div>'
    }
) {
    const slider = new SlickSlider(el, options);
    slider.initializeSlider();
    document.querySelector(el).classList.remove("initializing");
    $(el).slick("resize");
}

function resizeHomepage() {
    window.onresize = () => {
        initHomepageSlider();
    };
}

function isSlickSlider(el) {
    return document.querySelector(el).classList.contains("slick-slider");
}

function initHomepageSlider() {
    if (document.querySelector("#homepage-slider")) {
        if (window.innerWidth > 567) {
            if (!isSlickSlider("#homepage-slider")) {
                initSlider("#homepage-slider");
            }
        } else {
            if (document.querySelector("#homepage-slider-mobile")) {
                if (!isSlickSlider("#homepage-slider-mobile")) {
                    initSlider("#homepage-slider-mobile");
                }
            }
        }
    }

    if (document.querySelector("#homepage-brand-slider")) {
        if (!isSlickSlider("#homepage-brand-slider")) {
            initSlider("#homepage-brand-slider", {
                prevArrow:
                    '<div class="a-left control-c prev slick-prev"></div>',
                nextArrow:
                    '<div class="a-right control-c next slick-next"></div>',
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }
    }
}
