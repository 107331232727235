<template>
    <div class="dashboard-bar-chart">
        <h3 v-if="headline">{{ headline }}</h3>
        <bar-chart :data="getData"
                   :colors="getChartColors"
                   :dataset="dataset"
                   :library="options"
        />
    </div>
</template>

<script>
    import ChartMixin from "../../../mixins/ChartMixin";

    export default {
        props: {
            chartdata: {
                type: [Array, Object],
                required: true
            },
            headline: {
                type: [String],
            },
            redLine: {
                type: [Boolean],
                default: false
            },
            redLineValue: {
                type: [Number],
                default: 24
            },
            datalabels: {
                type: [Boolean],
                default: false,
            }
        },
        data() {
            return {
                dataset: {
                    barThickness: 20
                },
                options: {
                    scales: {
                        xAxes: [
                            {
                                id: 'x-axis-1',
                                ticks: {
                                    beginAtZero: true,
                                    steps: 18,
                                    stepValue: 6,
                                    max: 100
                                },
                                gridLines: {
                                    display: false
                                },
                            }
                        ]
                    },
                    plugins: {}
                }
            }
        },
        mixins: [ChartMixin],
        computed: {
            getData() {
                return this.getChartData(this.chartdata).map(el => [el[0], (el[1] / 60) / 60])
            },
            getChartColors() {
                return this.getColors(this.chartdata)
            }
        },
        created() {
            if(this.redLine) {
                this.options.annotation = this.getAnnotations()
            }

            if(this.datalabels) {
                this.options.plugins.datalabels = this.getDataLabels()
            }
        },
        methods: {
            getDataLabels() {
                return {
                    color: '#3B3B3B',
                    anchor: "end",
                    align: 0,
                    formatter: (value, context) => {
                        const minutes = value * 60
                        return `${Math.floor(minutes / 60)}h : ${Math.floor(minutes % 60)}m`
                    },
                    labels: {
                        title: {
                            font: {
                                size: '17'
                            }
                        }
                    }
                }
            },
            getAnnotations() {
                return {
                    drawTime: "afterDraw",
                    annotations: [{
                        id: 'line1',
                        type: 'box',
                        xScaleID: 'x-axis-1',
                        xMin: this.redLineValue,
                        xMax: this.redLineValue,
                        backgroundColor: 'rgba(255, 0, 0, 1)',
                        borderColor: 'rgba(255, 0, 0, 1)',

                    }]
                }
            }
        }
    }
</script>
