<template slot-scope="{}">
    <div class="chart-accordion">
        <div class="chart-accordion__header" :class="{'open': open}" @click="open = !open">
            <span>
                <icon-base v-if="icon" :icon="icon" width="40" height="40" stroke-width="0" className="secondary info-small mr-2 secondary headline-icon"/>
                <h3>{{ headline }}</h3>
            </span>
            <icon-base icon="ChevronDown" className="primary fill--primary chevron"/>
        </div>
        <div class="chart-accordion__content" v-if="open">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        headline: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            open: false
        }
    }
}
</script>
