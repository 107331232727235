<template>
    <div class="notify" :class="classes">
        <span class="notify__heading">{{ headline }}</span>
        <p v-if="message">{{ message }}</p>
        <p v-else v-for="error in errors" :key="error">{{ error }}</p>
        <span class="notify__close">
            <simple-svg  :src="closeImage" custom-class-name="stroke--white " />
        </span>

    </div>
</template>

<script>
export default {
    name: 'NotifyPopup',
    props: {
        type: {
            type: String,
            default: 'error'
        },
        headline: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, Array],
            default: () => []
        }
    },
    computed: {
        closeImage(){
            return require('../../../../svg/CloseIcon.svg')
        },
        classes() {
            if (this.type === 'success') {
                return 'alert alert-success'
            }

            return 'error-list invalid-feedback'
        }
    },
}
</script>

<style scoped>
.notify__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
}
</style>
