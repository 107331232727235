<template>
    <div>
        <div @click="showModal" class="tw-p-6 tw-rounded tw-border tw-border-gray-100 tw-w-50 tw-cursor-pointer tw-grid  tw-justify-items-center hover:tw-bg-gray-100">
            <img class="tw-w-16 tw-mb-4" :src="image" >
            <span class="tw-font-bold tw-text-center">{{ title }}</span>
        </div>
        <div v-show="isModalVisible" class="tw-absolute tw-rounded tw-bg-gray-100 tw-inset-0 tw-p-4">
            <div class="tw-grid tw-justify-items-end tw-cursor-pointer" @click="closeModal"><simple-svg  :src="closeImage" custom-class-name="stroke--black" /></div>
                <div class="tw-grid  tw-justify-items-center">
                    <img class="tw-w-24 tw-mb-4" :src="image" >

                    <span class="tw-font-bold tw-text-center tw-block">{{ title }}</span>
                    <span class="tw-font-base tw-text-center" v-html="text"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
      name: 'overlay-modal',
      props: {
        image: {
            default: '', type: String
        },
        title: {
            default: '', type: String
        },
        text: {
            default: '', type: String
        },
        info: {
            default: '', type: String
        }
    },

      data() {
        return {
          isModalVisible: false,
        };
      },
      computed: {
        closeImage(){
            return require('../../../../svg/Alert/Close.svg')
        }
    },
      methods: {
        showModal() {
          this.isModalVisible = true;
        },
        closeModal() {
          this.isModalVisible = false;
        }
      }
    };
</script>

<style>
.modal-trigger{
    cursor: pointer;
}
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }


  .modal-body {
    position: relative;
  }


</style>
