<template>
    <button :type="type" class="btn">
        <slot />
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        type: {
            type: String,
            default: 'submit',
        }
    }
}
</script>
