<template>
    <div class="company-charts d-flex align-items-center">
        <div class="company-order-chart">
            <h3 class="text-center">{{ translations.conversion.orders_per_company }}</h3>
            <dashboard-pie-chart
                width="350px"
                height="350px"
                :chartdata="orderData"
                legend-class="legend-grid"
                class-name="center mb-3"
                :info-text-empty="infoTranslations.info_orders_per_company_empty"
                :info-text-one="infoTranslations.info_orders_per_company_one"
                :info-text-value="infoTranslations.info_orders_per_company_value"></dashboard-pie-chart>
        </div>
        <div class="company-product-chart">
            <h3 class="text-center">{{ translations.conversion.products_per_company }}</h3>
            <dashboard-pie-chart
                width="350px"
                height="350px"
                :chartdata="productData"
                legend-class="legend-grid"
                class-name="center mb-3"
                :info-text-empty="infoTranslations.info_products_per_company_empty"
                :info-text-one="infoTranslations.info_products_per_company_one"
                :info-text-value="infoTranslations.info_products_per_company_value"></dashboard-pie-chart>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            orderData: {
                type: [Array, Object]
            },
            productData: {
                type: [Array, Object]
            },
            translations: {
                type: [Array, Object],
                default: () => []
            }
        },
        computed: {
            infoTranslations() {
                return this.translations.info
            }
        }
    }
</script>

<style scoped>
    h3 {
        margin-top: 3.125rem;
        text-transform: none;
        font-weight: 700;
        margin-bottom: 1.75rem;
    }

    .company-product-chart,
    .company-order-chart {
        width: 50%;
    }
</style>
