function triggerAccordion() {
    openLastAccordion()
    showCreateMessageForm()
    Array.from(document.querySelectorAll('.accordion')).forEach(accordion => {
        const header = accordion.querySelector('.accordion-header')
        header.addEventListener('click', ev => {
            const body = accordion.querySelector('.accordion-body')
            toggleClass(body, 'open')
            toggleClass(header, 'open')
        })
    })
}

function openLastAccordion() {
    const accordion = document.querySelectorAll('.accordion')
    accordion[accordion.length - 1].children[0].classList.add('open')
    accordion[accordion.length - 1].children[1].classList.add('open')
}

function showCreateMessageForm() {
    Array.from(document.querySelectorAll('.create-message')).forEach(el => {
        el.addEventListener('click',ev => {
            toggleClass(ev.target.parentNode.querySelector('.ticket-update'), 'open')
            toggleClass(ev.target, 'closed')
        })
    })

    Array.from(document.querySelectorAll('.cancel')).forEach(el => {
        el.addEventListener('click',ev => {
            toggleClass(ev.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('.ticket-update'), 'open')
            toggleClass(ev.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector('.create-message'), 'closed')
        })
    })
}

// Toggle class for IE11
function toggleClass(element, classname) {
    if(element.classList.contains(classname)) {
        element.classList.remove(classname)
    } else {
        element.classList.add(classname)
    }
}

export { triggerAccordion }
