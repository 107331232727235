<template>
    <div class="status-delivery">
        <span :class="getStatus.classes"></span><span class="status-text">{{ getStatus.message }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            data: {},
            meta: {},
        },
        computed: {
            getStatus() {
                var message = "";
                var classes = "";
                switch (this.data.statusdelivery) {
                    case 3:
                        classes = "status-icon status-delivery--blue";
                        message = JSON.parse(this.meta.translations)[2];
                        break;
                    case 4:
                        classes = "status-icon status-delivery--green";
                        message = JSON.parse(this.meta.translations)[3];
                        break;
                    case 25:
                        classes = "status-icon status-delivery--blue";
                        message = JSON.parse(this.meta.translations)[4];
                        break;
                    case 26:
                        classes = "status-icon status-delivery--blue";
                        message = JSON.parse(this.meta.translations)[5];
                        break;
                    case 27:
                        classes = "status-icon status-delivery--blue";
                        message = JSON.parse(this.meta.translations)[6];
                        break;
                    case 5:
                    case 6:
                        classes = "status-icon status-delivery--red";
                        message = JSON.parse(this.meta.translations)[7];
                        break;
                    case 24:
                        classes = "status-icon status-delivery--blue";
                        message = JSON.parse(this.meta.translations)[8];
                        break;
                    case 7:
                        classes = "status-icon status-delivery--red";
                        message = JSON.parse(this.meta.translations)[9];
                        break;
                    case 28:
                        classes = "status-icon status-delivery--blue";
                        message = JSON.parse(this.meta.translations)[10];
                        break;
                    default:
                        classes = "status-icon status-delivery--orange";
                        message = JSON.parse(this.meta.translations)[1];
                }
                return  { message: message, classes: classes};
            },

        }
    }
</script>

<style scoped>
    .status-delivery {
        font-size: 17px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 400;
    }
</style>
