<template>
    <div class="tw-text-acadia tw-font-bold tw-my-4">
        <div class="tw-bg-primary tw-cursor-pointer tw-whitespace-no-wrap hover:tw-text-white tw-ml-auto tw-py-1 tw-px-4 tw-rounded-full tw-text-white"
            @click="open">
            {{ label }}
        </div>

        <div v-show="isOpen" class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-z-50">

            <div class="tw-cursor-not-allowed tw-bg-black tw-opacity-50 tw-absolute tw-z-30 tw-inset-0" @click="close()"></div>

            <div class="tw-bg-white tw-pt-4 tw-p-8 tw-mx-auto tw-z-40"  style="width: 70%; max-height: 80vh; overflow-x: scroll;">
                <div class="tw-grid tw-pb-4 tw-justify-items-stretch">
                    <div class="tw-justify-self-end ">
                        <span class="font-bold tw-cursor-pointer" @click="close()">
                            <simple-svg  :src="closeImage" custom-class-name="stroke--white" />
                        </span>
                    </div>
                </div>
                <slot />
                <div class="tw-min-w-full full-important" :id="`bp_check-${_uid}-holder`"></div>
                <div class="tw-flex tw-mt-8">
                    <button class="tw-inline-block tw-align-middle tw-m-auto tw-bg-primary tw-cursor-pointer tw-whitespace-no-wrap hover:tw-text-white tw-ml-auto tw-py-1 tw-px-4 tw-rounded-full tw-text-white" v-show="showDownload" @click="download()">{{downloadlabel}}</button>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createPDF } from "../../../../js/components/PDF";

export default {
    name: "PopUpButton",
    props: {
        label: {
            type: String,
            default: ""
        },
        file: {
            type: String,
            default: ""
        },
        fileurl: {
            type: String,
            default: ""
        },
        downloadlabel: {
            type: String,
            default: "download"
        },
        filename: {
            type: String,
            default: "file.pdf"
        }
    },
    data() {
        return {
            isOpen: false,
            showDownload: false
        };
    },
    created() {
        if (this.fileurl) {
            this.showDownload = true;
        }
    },
    computed: {
            closeImage(){
                return require('../../../../svg/CloseIcon.svg')
            }
        },
    methods: {
        open() {
            document.body.classList.add("tw-overflow-hidden");
            this.isOpen = true;
        },
        close() {
            document.body.classList.remove("tw-overflow-hidden");
            this.isOpen = false;
        },
        download() {
            axios({
                url: this.fileurl,
                method: "GET",
                responseType: "blob"
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", this.filename);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        }
    },
    mounted() {
        createPDF(this.file, `bp_check-${this._uid}-holder`);
    }
};
</script>
