<template>
    <div v-if="loading" class="loader"></div>
</template>

<script>
    export default {
        props: {
            loading: {
                default: false, type: Boolean
            }
        }
    }
</script>

<style>


</style>
