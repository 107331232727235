<template>
    <div class="contract-status">
        <template v-if="order.isDate">
            <div class="status-delivery">
                <span class="delivery-status status-end"></span>
                <span class="delivery-status-name">{{order.translations.contract_ended}}</span>
            </div>
        </template>
        <template v-else-if="order.licenseNumber">
            <div class="status-delivery">
                <span class="delivery-status status-active"></span>
                <span class="delivery-status-name">{{order.translations.contract_active}}</span>
            </div>
        </template>
        <template v-else>
            <div class="status-delivery">
                <span class="delivery-status status-open"></span>
                <span class="delivery-status-name">{{order.translations.contract_open}}</span>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: ['order']
    }
</script>
