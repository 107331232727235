<template>
    <span class="f-big">{{ date }}</span>
</template>

<script>
    import moment from 'moment'

    export default {
        props: ['meta', 'data'],
        computed: {
            date() {
                return moment(this.data.created_at).format('DD.MM.YYYY')
            }
        }
    }
</script>
