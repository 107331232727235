<template>
    <div class="row" v-if="order.translations">
        <order-track :order="order"></order-track>
    </div>
</template>

<script>
    import OrderTrack from "./order-track/OrderTrack";

    export default{
        props: ['order'],
        components: {
            OrderTrack
        }
    }
</script>
