<template>
    <div class="button__data-table">
        <div class="button__edit" @click="handleClick(data)">
            <svg v-if="meta.icon === 'dots'"
                 xmlns="http://www.w3.org/2000/svg"
                 width="36"
                 height="36"
                 stroke="currentColor"
                 stroke-width="2"
                 viewBox="0 0 40 40"
                 class="fill--secondary stroke--secondary">
                <circle class="cls-1" cx="20" cy="20" r="1.98"/>
                <circle class="cls-1" cx="32" cy="20" r="1.98"/>
                <circle class="cls-1" cx="8" cy="20" r="1.98"/>
            </svg>

            <svg v-else
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 stroke="currentColor"
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 width="32"
                 height="32"
                 viewBox="0 0 40 40"
                 class="stroke--secondary">
                <path class="cls-1" d="M34.44,5.76a5.17,5.17,0,0,0-7.33,0L6.57,26.35,4.06,36.13l9.78-2.5L34.39,13.08a5.15,5.15,0,0,0,0-7.32Z"/>
                <line class="cls-1" x1="26.53" y1="6.4" x2="33.8" y2="13.67"/>
                <line class="cls-1" x1="23.56" y1="9.36" x2="30.83" y2="16.63"/>
                <line class="cls-1" x1="6.57" y1="26.35" x2="13.85" y2="33.61"/>
            </svg>
        </div>
        <order-history-popup v-if="meta.order"
                             :order-history="history"
                             :is-open="open"
                             :order-data="getOrderData"
                             :order-number="translations.order_number"
                             :order-id="data.id"
                             @popupClose="open = false"></order-history-popup>
    </div>
</template>

<script>
    import moment from 'moment';
    import OrderHistoryPopupMixin from "../../mixins/OrderHistoryPopupMixin";

    export default {
        props: {
            data: {},
            meta: {},
            name: {},
            click: {
                type: Function,
                default: () => {
                }
            },
            url: {},
            classes: {
                type: Object,
                default: () => ({
                    'btn': true,
                    'btn-primary': true,
                    'btn-sm': true,
                }),
            },
        },
        data() {
            return {
                translations: {}
            }
        },
        mixins: [OrderHistoryPopupMixin],
        created() {
            this.translations = this.meta.translations ? JSON.parse(this.meta.translations) : []
        },
        computed: {
            getOrderData() {
                this.translations['client'] = this.data.id
                this.translations['date'] = moment(this.data.ctime, 'YYYY-MM-DD H:i:s').format('DD.MM.YYYY')
                return {
                    translations: this.translations,
                    deliveryClass: this.data.statusdelivery,
                    licenseNumber: this.data.rental_license_number,
                    isDate: this.data.contract_ending !== null && this.data.contract_ending < moment().format('YYYY-MM-DD H:i:s'),
                    status: this.translations[this.data.statusdelivery]
                }
            }
        },
        methods: {
            editUser(data, meta) {
                window.location.href = this.meta.url + "/" + data.id;
            },
            getOrderHistory(data) {
                const url = this.meta.url + '&his_id=' + data.id
                this.getOrder(url)
            },
            handleClick(data, meta) {
                this.meta.order ? this.getOrderHistory(data) : this.editUser(data, meta);
            },
        }
    }
</script>

<style scoped>
    .button__edit {
        text-align: center;
        cursor: pointer;
    }

    .popup-header span:last-child:hover {
        cursor: pointer;
    }

    .pop-up .name {
        font-weight: bold;
    }
</style>
