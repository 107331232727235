<template>
    <div class="order-track-status d-flex justify-content-between align-items-center">
        <span class="track-status font-bold coloring--color">{{ status }}</span>
        <span class="track-info d-none d-sm-inline-block">{{ translations.more_info }}</span>
    </div>
</template>

<script>
    export default {
        props: ['status', 'translations', 'color']
    }
</script>

<style lang="scss">
    .track-status {
        font-size: 18px;

        @media(min-width: 668px) {
            font-size: 19px;
        }
    }

    .track-info {
        font-size: 12px;
    }
</style>
