<template>
    <div class="tw-space-y-6">
        <buyback-product
            v-for="product in products"
            :key="product.prod_id"
            :product="product"
            :settings="settings"
            :errors="getProductErrors(product.prod_id)"
            @calculatedResult="getResult">
        </buyback-product>

        <buyback-total :total="resultTotal" :taxes="resultTaxes"></buyback-total>
    </div>
</template>

<script>
export default {
    name: "BuybackContainer",
    props: {
        errors: {
            type: [Array, Object],
            default: () => []
        },
        settings: {
            type: [Array, Object],
            default: () => []
        },
        products: {
            type: [Array, Object],
            default: () => []
        },
        taxes: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            result: {}
        };
    },
    computed: {
        hasResult() {
            return !!this.result.data;
        },
        resultTotal() {
            return this.hasResult ? this.result.data.total : 0;
        },
        resultTaxes() {
            return this.hasResult ? this.result.data.taxes : this.taxes;
        }
    },
    methods: {
        getResult(data) {
            this.result = data;
        },
        getProductErrors(id) {
            if (this.errors != null) {
                return this.errors[Number(id)];
            }

            return;
        }
    }
};
</script>

<style>
</style>
