import {openVueOrderReports} from "../../../components/MyAccount";

export default {
    data() {
        return {
            open: false,
            history: '',
            loading: false,

        }
    },
    methods: {
        async getOrder(url) {
            if (this.history.length === 0) {
                this.loading = true
                await fetch(url).then(data => data.text()).then(data => {
                    this.history = this.parseAccountHistory(data).innerHTML
                    return data
                }).then(data => {
                    if (this.hasTempElementClass(data, '.order-report-button')) {
                        openVueOrderReports()
                    }
                    this.loading = false
                    this.open = true
                    document.body.classList.add('o-hidden')
                })
                    .catch(err => {
                        this.loading = false
                        this.history = err
                    })
            } else {
                this.open = true
                document.body.classList.add('o-hidden')
            }
        },
        parseAccountHistory(html) {
            let div = document.createElement('div')
            div.innerHTML = html
            return div.querySelector('.aimeos.account-history')
        },
        hasTempElementClass(html, className) {
            let div = document.createElement('div')
            div.innerHTML = html
            return div.querySelectorAll(className).length > 0
        }
    }
}
