<template>
    <div>
        <div class="modal-trigger" @click="showModal" >
            <slot name="trigger">
                <simple-svg @click="showModal" :src="svgImage" :custom-class-name='this.classes' />
            </slot>
        </div>

        <Modal v-show="isModalVisible" @close="closeModal">
            <slot></slot>
        </Modal>


    </div>
</template>

<script>
    import Modal from './../components/Modal.vue';

    export default {
      name: 'info-modal',
      props: {

        classes: {
            default: 'tw-fill-gray-100 tw-ml-1', type: String
        },

        icon: {
            default: 'Icon-Info', type: String
        }},
      components: {
        Modal,
      },
      data() {
        return {
          isModalVisible: false,
        };
      },
      computed: {
            svgImage(){
                return require('../../../../svg/'+ this.icon+'.svg')
            }
        },
      methods: {
        showModal() {
          this.isModalVisible = true;
        },
        closeModal() {
          this.isModalVisible = false;
        }
      }
    };
</script>

<style>
.modal-trigger{
    cursor: pointer;
}
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }


  .modal-body {
    position: relative;
  }


</style>
