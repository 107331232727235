<template>
    <div class="tracking d-flex align-items-center">
        <div class="order-tracking-icon">
            <icon-base :icon="order.delivery.icon" width="80px" height="80px" className="icon coloring--stroke" />
        </div>
        <div class="status-line">
            <order-track-status
                :status="order.delivery.status_text"
                :translations="order.translations"
                :color="order.delivery.color">
            </order-track-status>
            <order-track-line :order-step="order.delivery.step"></order-track-line>
            <order-track-text :text="order.delivery.tracking_text"></order-track-text>
        </div>
    </div>
</template>

<script>
    import OrderTrackLine from "./OrderTrackLine";
    import OrderTrackStatus from "./OrderTrackStatus";
    import OrderTrackText from "./OrderTrackText";

    export default {
        props: ['order'],
        components: {
            OrderTrackLine,
            OrderTrackStatus,
            OrderTrackText
        }
    }
</script>

<style>
    .tracking {
        width: 100%;
        padding: 2.5rem 0;
    }

    .status-line {
        width: 100%;
    }
</style>
