<template>
    <label class="justify-content-end" v-bind:for="'user_select_'+data.id">
        <input v-bind:id="'user_select_'+data.id" type="radio" :value="data.id" name="user_id">
        {{meta.text}}
    </label>
</template>
<script>
    export default {
        props: {
            data: {},
            meta: {},
            classes: {
                default: {}, type: Object
            },
        },
    }
</script>
