<template>
    <div class="alert">
        <span class="alert-header">
            <svg    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    width="36"
                    height="36"
                    viewBox="0 0 40 40"
                    class="stroke--secondary mr-2">
                    <path class="cls-1" d="M20,9.33c7.46,0,13.5,4.47,13.5,10a8.5,8.5,0,0,1-2.92,6.21l1.67,6.63L24.7,28.7a17.44,17.44,0,0,1-4.7.63c-7.46,0-13.5-4.48-13.5-10S12.54,9.33,20,9.33Z"/>
                    <rect class="fill--secondary" stroke="none" x="18.47" y="16.86" width="3.05" height="8.86"/>
                    <path class="cls-2 fill--secondary" stroke="none" d="M20,15.76a1.76,1.76,0,0,1-1.22-.42,1.4,1.4,0,0,1-.48-1.1,1.41,1.41,0,0,1,.48-1.1A1.76,1.76,0,0,1,20,12.72a1.79,1.79,0,0,1,1.22.42,1.41,1.41,0,0,1,.48,1.1,1.4,1.4,0,0,1-.48,1.1A1.79,1.79,0,0,1,20,15.76Z"/>
            </svg>
            {{ header }}
        </span>
        <p class="mt-2">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
        type: {
            type: String,
            default: 'warning'
        },
        header: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.alert {
    font-size: 1rem;
    border: 1px solid #FDBB00;
    border-radius: 0;
}

.alert-header {
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
}
</style>
