<template>
    <div class="dashboard-grouped-charts">
        <bar-chart :data="chartdata.data" :dataset="dataset" :library="options" :colors="chartdata.colors" :legend="false"/>
    </div>
</template>

<script>
    import ChartMixin from "../../../mixins/ChartMixin";

    export default {
        props: {
            chartdata: {
                type: [Array, Object],
                required: true
            },
            translations: {
                type: [Array, Object],
                required: true
            }
        },
        mixins: [ChartMixin],
        data() {
            return {
                dataset: {
                    barThickness: 20,
                },
                options: {
                    scales: {
                        xAxes: [
                            {
                                id: 'x-axis-1',
                                ticks: {
                                    beginAtZero: true,
                                },
                                gridLines: {
                                    display: false
                                },
                            }
                        ]
                    },
                    plugins: {}
                }
            }
        },
        computed: {
            getData() {
                return this.getChartData(this.chartdata)
            },
            getChartColors() {
                return this.getColors(this.chartdata)
            }
        },
        created() {
            this.options.plugins.datalabels = this.getDataLabels()
        },
        methods: {
            getDataLabels() {
                return {
                    color: '#3B3B3B',
                    anchor: "end",
                    align: 0,
                    formatter: (value, context) => {
                        return `${value} ${context.dataset.label}`
                    },
                    labels: {
                        title: {
                            font: {
                                size: '17'
                            }
                        }
                    }
                }
            }
        }
    }
</script>
