<template>
    <div class="last-invite">{{ data.last_invite | moment("DD.MM.YYYY") }}</div>
</template>

<script>
    export default {
        name: 'LastInvite',
        props: {
            data: {},
            meta: {},
            name: {},
            classes: {
                type: Object,
                default: {}
            },
            url: {}
        }
    }
</script>

<style scoped>
.last-invite {
    text-align: center;
}
</style>
