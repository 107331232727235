<template>
    <div class="select-field">
        <label :for="name" v-if="label">{{ label }}</label>
        <select :name="name" @change="selectedChange($event)" :value="selectDefaultValue" :disabled="values.length === 0">
            <option value="" selected v-if="defaultText">{{ defaultText }}</option>
            <option v-for="(v,i) in values" :key="i" :value="v.value">{{ v.text }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: [String],
                default: ''
            },
            name: {
                type: [String]
            },
            values: {
                type: [Array, Object],
                default: () => [] // needs values like {value: 'Test', text: 'option Text'}
            },
            selectedIndex: {
                type: [Number],
                default: 0
            },
            disabled: {
                type: [Boolean],
                default: false
            },
            value: {
                type: [String, Number],
                default: ''
            },
            defaultText: {
                type: [String]
            }
        },
        data() {
            return {
                selectDefaultValue: 0,
            }
        },
        created() {
            this.selectDefaultValue = this.value || this.values[0].value
        },
        methods: {
            selectedChange(event) {
                this.selectDefaultValue = event.target.value
                this.$emit('change', this.selectDefaultValue)
            }
        }
    }
</script>

<style scoped>
    label {
        font-family: "Source Sans Pro", sans-serif;
        display: block;
        font-weight: bold;
        font-size: 16px;
    }

    select {
        width: 100%;
        min-height: 0;
        font-size: 17px;
        padding: 0.75rem;
        border: #e3e3e3;
        margin-bottom: 1rem;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 400;
    }
</style>
