export default {
    props: [
        'url',
        'data_columns',
        'config_pages',
        'additional_data',
        'compact'
    ],
    data() {
        return {
            isLoading: true,
        }
    },
    computed: {
        perPage() {
            return this.parse(this.config_pages)
        },
        columns() {
            return this.parse(this.data_columns)
        },
        additional() {
            return this.parse(this.additional_data)
        },
        translations() {
            return this.additional.translations ? this.additional.translations : ''
        }
    },
    methods: {
        parse(value) {
            return value ? JSON.parse(value) : ''
        }
    },
}
