<template>
    <div class="employer-share-banner d-flex">
        <icon-base
            icon="present"
            className="success"
            width="28"
            height="28"
            stroke-width='2'
        ></icon-base>
        <p>
            <span v-html="shareHeader"></span>
            <span v-html="replacesTranslation"></span>
        </p>
    </div>
</template>

<script>
    import IconBase from "./IconBase";
    export default {
        name: 'EmployerShare',
        components: {IconBase},
        props: ['employerShare', 'shareHeader', 'shareText'],
        computed: {
            replacesTranslation() {
                const amount = new Intl.NumberFormat('de-DE',
                    { style: 'currency', currency: 'EUR', minimumFractionDigits: 2  })
                    .format(this.employerShare)
                return this.shareText.replace(':count', amount)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .employer-share-banner {
        padding: 10px 5px;

        p {
            font-size: 17px;
            margin-left: 5px;
            margin-bottom: 0;

            span:first-child {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
</style>
