<template>
    <div class="order-text">
        <span>{{ text }}</span>
    </div>
</template>

<script>
    export default {
        props: ['text']
    }
</script>

<style scoped>
    span {
        font-size: 17px;
    }
</style>
