const setupVariantImages = () => {

    $(".catalog-detail-basket-selection .selection, .catalog-list-items .items-selection .selection").on("change", ".select-list", function(event) {

        var elem = $(this);
        var type = elem.data("type");
        var value = elem.find(".select-option:checked").val();

        elem.parents(".product").find(".bu-image-single .item").each( function(ev) {

            if( $(this).data("variant-" + type) == value ) {
                window.location.hash = $(this).attr("id");
                return false;
            }
        });
    });
}

const lightBoxHook = () => {
    if(document.querySelector('.catalog-detail-image')) {
        imageSwap()
        const catalogDetail = document.querySelector('.catalog-detail-image')
        Array.from(catalogDetail.querySelectorAll('.bu-image-single .item')).forEach((el, index) => {
            el.addEventListener('click', ev => {
                const list = [];
                const gallery = catalogDetail
                const pswp = catalogDetail.querySelectorAll('.pswp')
                const options = gallery.dataset.options || {index: index};

                if( pswp.length === 0 ) {
                    console.log( 'No element with class .pswp for PhotoSwipe found' );
                    return false;
                }

                Array.from(catalogDetail.querySelectorAll(".bu-image-single .item")).forEach((item, idx) => {
                    const entries = JSON.parse(item.dataset.sources)
                    let imgurl = entries["1"];

                    list.push({
                        msrc: item.dataset.image,
                        src: imgurl,
                        pid: idx,
                        h: 0,
                        w: 0
                    });
                });

                gallery._photoswipe = new PhotoSwipe(pswp[0], PhotoSwipeUI_Default, list, options);
                gallery._photoswipe.init();

                gallery._photoswipe.listen("imageLoadComplete", (idx, item) => {

                    if( item.w === 0 && item.h === 0 ) {
                        var imgpreload = new Image();

                        imgpreload.onload = function() {
                            item.w = this.width;
                            item.h = this.height;
                            gallery._photoswipe.updateSize(true);
                        };

                        imgpreload.src = item.src;
                    }
                });
            })
        })
    }
}

const imageSwap = () => {
    setDetailImageSelectedByUrl()
    Array.from(document.querySelectorAll(".catalog-detail-image .thumbs .bu-item")).forEach(el => {
        el.addEventListener('click', ev => {
            const scrollPosition = document.documentElement.scrollTop
            unselectMediaItems('.bu-item')
            el.classList.add("selected")
            const idx = el.dataset.idx
            const mediaItem = document.querySelectorAll('.media-item')[idx]
            unselectMediaItems('.media-item')
            mediaItem.classList.add('selected')

            window.location = el.getAttribute('href')
            window.scroll(0, scrollPosition)

            return false;
        })
    })
}

function unselectMediaItems(className) {
    Array.from(document.querySelectorAll(className)).forEach(el => {
        el.classList.remove('selected')
    })
}

function setDetailImageSelectedByUrl() {
    let id;
    if(window.location.hash && window.location.hash.split('-').length > 1) {
       id = window.location.hash.split('-').pop()
    } else {
        id = 0
    }
    document.querySelectorAll('.media-item')[id].classList.add('selected')
}

export { lightBoxHook }
