<template>
    <chart-accordion class="supplier-chart" :headline="translations.supplier_charts.title">
        <loader v-show="loading" />

        <chart-filter
            :url="url"
            :translations="additionals.translations"
            :companies="filters"
            @updatedLoader="getUpdatedLoader"
            @updatedData="getUpdatedData" />

        <bars-chart-element :info-text="infoTranslations.info_statistics_orders_per_company_empty" :charts="charts" :colors="colors" :translations="translations"/>
    </chart-accordion>
</template>

<script>
export default {
    name: 'SupplierChart',
    props: {
        url: {
            type: String,
            default: ''
        },
        additionals: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            chartData: {},
            loading: false
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        filters() {
            return this.additionals.filters
        },
        translations() {
            return this.additionals.translations
        },
        infoTranslations() {
            return this.translations.info
        },
        charts() {
            return this.chartData.data
        },
        colors() {
            return this.chartData.colors
        }
    },
    methods: {
        getUpdatedLoader(value) {
            if(value) {
                this.loading = value
            }
        },
        getUpdatedData(value) {
            if(value) {
                this.chartData = value
                this.loading = false
            }
        },
        async fetchData() {
            this.loading = true

            await axios
                .get(this.url)
                .then(response => {
                    this.chartData = response.data
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
            })
        }
    }
}
</script>

<style scoped>
.supplier-chart {
    position: relative;
    border: 2px solid #F1F1F1;
}

.bars__row {
    grid-template-columns: 160px 1fr;
}

.bars__bar {
    max-width: 600px;
}
</style>
