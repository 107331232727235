function initPopUp() {
    popupOpen()
}

function initNewAddress() {
    if (document.querySelector(".item-address.item-new")) {
        checkNewAddress()
    }

    if (document.querySelector(".new-address-form")) {
        checkServiceNewAddress()
    }
}

function popupOpen() {
    Array.from(document.querySelectorAll('.pop-up-form')).forEach((element, index) => {
        const id = element.dataset.id ? '-' + element.dataset.id : ''
        if (element.dataset.id) {
            document.querySelector(`#update-id${id}`).addEventListener('click', event => {
                document.querySelector(`.pop-up-layer.layer-id${id}`).classList.add('open')
                document.querySelector(`.pop-up-form.form-id${id}`).classList.add('open')
                popupClose(id)
                refreshDeliveryCards(index, element)
            })
        }
    })
}

function popupClose(index) {
    setListenerToElement('.popup-back', index)
}

function setListenerToElement(elementClass, index) {
    Array.from(document.querySelectorAll(elementClass)).forEach((element) => {
        element.addEventListener('click', event => {
            event.preventDefault()
            document.querySelector(`.pop-up-layer.layer-id${index}`).classList.remove('open')
            document.querySelector(`.pop-up-form.form-id${index}`).classList.remove('open')
        })
    })
}

function closeNewAddress() {
    const newAddress = document.getElementById("ca_mpp_deliveryoption-null")
    if (document.querySelector('.btn-safe-new-address')) {
        document.querySelector('.btn-safe-new-address').addEventListener('click', () => {
            document.querySelector('.mpp_deliveryoption').classList.remove('open')
            newAddress.classList.remove('no-m')
            document.querySelector(".item-address.item-new").parentElement.classList.add("col-md-4")
        })
    }
}

function checkNewAddress() {
    const newAddress = document.getElementById("ca_mpp_deliveryoption-null")
    Array.from(document.querySelectorAll('input[type="radio"]')).forEach(element => {
        element.addEventListener('change', event => {
            if (newAddress.checked) {
                document.querySelector(".item-address.item-new").parentElement.classList.remove("col-md-4")
                document.querySelector('.mpp_deliveryoption').classList.add("open")
                newAddress.classList.add('no-m')
            } else {
                document.querySelector(".item-address.item-new").parentElement.classList.add("col-md-4")
                document.querySelector('.mpp_deliveryoption').classList.remove("open")
                newAddress.classList.remove('no-m')
            }
        })
    })
    closeNewAddress()
}

function openNewAddressForm() {
    const btn = document.querySelector('.show-form')
    const closeForm = document.querySelector('.close-form')
    btn.addEventListener('click', event => {
        document.querySelector('.show-form + form').classList.remove('d-none')
        btn.classList.add('d-none')
    })
    closeForm.addEventListener('click', event => {
        btn.classList.remove('d-none')
        document.querySelector('.show-form + form').classList.add('d-none')
    })
}

function checkServiceNewAddress() {
    const newAddress = document.getElementById("ca_mpp_deliveryoption-null")
    changeNewServiceAddressState(newAddress)
    Array.from(document.querySelectorAll('input[type="radio"]')).forEach(element => {
        element.addEventListener('change', event => {
            if (newAddress.checked) {
                document.querySelector(".new-address-form").classList.remove("d-none")
            } else {
                document.querySelector(".new-address-form").classList.add("d-none")
            }
        })
    })
}

function changeNewServiceAddressState(element) {
    if (element.checked) {
        document.querySelector(".new-address-form").classList.remove("d-none")
    } else {
        document.querySelector(".new-address-form").classList.add("d-none")
    }
}

function refreshDeliveryCards(index, form) {
    const type = form.dataset.type
    const id = form.dataset.id ? '-' + form.dataset.id : ''
    if (form.querySelector('.btn-safe')) {
        form.querySelector('.btn-safe').addEventListener('click', ev => {
            document.querySelectorAll('.delivery-card-name')[index].innerHTML = document.getElementById(`address-${type}-firstname${id}`).value + ' ' + document.getElementById(`address-${type}-lastname${id}`).value
            document.querySelectorAll('.delivery-card-address')[index].innerHTML = document.getElementById(`address-${type}-address1${id}`).value
            document.querySelectorAll('.delivery-card-postal')[index].innerHTML = document.getElementById(`address-${type}-postal${id}`).value + ' ' + document.getElementById(`address-${type}-city${id}`).value
            if (document.querySelectorAll('.delivery-card-address2')[index] && document.getElementById(`address-${type}-address2${id}`)) {
                document.querySelectorAll('.delivery-card-address2')[index].innerHTML = document.getElementById(`address-${type}-address2${id}`).value
            }
        })
    }
}

export {initPopUp, openNewAddressForm, initNewAddress}
