<template>
    <div class="registered-user" v-if="data">
        <div class="registered-user-headline">
            <h3>{{ translations.registered_user_headline }}</h3>
        </div>
        <div class="registered-user-body">
            <average-tile
                icon="Calendar"
                icon-width="85px"
                icon-height="85px"
                :headline="data.registeredUserDay.label"
                :value="data.registeredUserDay.value" />

            <average-tile
                icon="CalendarWeek"
                icon-width="85px"
                icon-height="85px"
                :headline="data.registeredUserWeek.label"
                :value="data.registeredUserWeek.value" />

            <average-tile
                icon="CalendarFull"
                icon-width="85px"
                icon-height="85px"
                :headline="data.registeredUserMonth.label"
                :value="data.registeredUserMonth.value" />

            <average-tile
                icon="CalendarThree"
                icon-width="85px"
                icon-height="85px"
                :headline="data.registeredUserThreeMonth.label"
                :value="data.registeredUserThreeMonth.value" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: [Object || Array]
            },
            translations: {
                type: [Object || Array]
            }
        }
    }
</script>

<style lang="scss">
    .registered-user {
        border-top: 2px dashed #e3e3e3;
        padding-bottom: 2rem;

        h3 {
            text-transform: none;
            font-weight: 700;
        }
    }

    .registered-user-body {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .registered-user-headline {
        padding: 1.5875rem 0.725rem;
    }
</style>
