<template>
<div>
    <div class="tw-w-full tw-grid tw-justify-items-center tw-relative">
            <div class="tw-mt-6 tw-block tw-justify-self-end">
                <div  v-show="isModalVisible" class="tw-absolute tw-rounded tw-bg-gray-100 tw-inset-0 tw-p-4 tw-cursor-pointer" @click="closeModal">
                    <div class="tw-grid tw-pt-12 tw-px-16">
                        <span class="tw-font-bold tw-block">{{ translations.headline }}</span>
                        <span class="tw-font-base" v-html="translations.text"></span>
                        <span class="tw-block" v-html="translations.footer"></span>
                    </div>
                </div>
                <span :class="{'tw-bg-gray-300 tw-text-white open': isModalVisible, '': !isModalVisible, }" @click="showModal()" class="tw-mt-8 tw-mr-8 tw-z-1 tw-relative tw-cursor-pointer tw-stroke-white-hover tw-p-2 te-flex tw-rounded-full tw-items-center tw-content-center hover:tw-bg-gray-300 tw-text-gray-300 hover:tw-text-white">
                    <simple-svg v-if="svgImage" :src="svgImage" custom-class-name="tw-stroke-white-hover tw-stroke-gray-300 " />
                    <span class="tw-break-keep tw-text-sm tw-self-center tw-pl-1">{{translations.title}}</span>
                </span>
            </div>
        <div class="sm:tw-w-128 sm:tw-h-128 tw-h-64  tw-w-64 tw-overflow-hidden tw-rounded-lg tw-flex tw-items-stretch">
            <img class="tw-object-cover tw-self-center" :src="bannerImage" alt="" />
        </div>
    </div>
    <div class="tw-mt-2 tw-w-full ">
        <div class="tw-grid tw-grid-cols-6 tw-gap-4">
            <button @click="currentThumnail(image.imageUrl, index)" type="button" class="tw-aspect-square tw-h-24 tw-p-2 tw-w-24 tw-overflow-hidden tw-rounded-lg tw-border-2  tw-text-center hover:tw-border-primary" v-for="(image, index) in images" :class="[activeClass == index ? 'tw-border-primary': 'tw-border-white']" :key="index">
                <img class="tw-h-full tw-w-full tw-object-cover"  :src="image.imageUrl" alt="" />
            </button>
        </div>
    </div>
</div>
</template>
<script>

export default {
    name: 'product-gallery',
    props: {
        translation: {
            type: Object|Array,
            default: {}
        },
        images: {
            type: Object|Array,
            default: {}
        },
        main: {
            default: false, type: Boolean
        },
        banner: {
            default: '', type: String
        }
    },
    data() {
        return {
            activeClass: 0,
            bannerImage: this.banner,
            isModalVisible: false,
        };
    },
    computed: {
            translations(){
                if(this.main){
                    return this.translation.main
                }
                return this.translation.accessories

            },
            svgImage(){
                if(this.main){
                    return require('../../../../svg/Icon_Hauptgeraet.svg')
                }
                return require('../../../../svg/Icon_Zubehoer.svg')
            },
            closeImage(){
                return require('../../../../svg/Alert/Close.svg')
            }
        },
    methods: {
        currentThumnail: function (image, index) {
            this.bannerImage = image;
            this.activeClass = index;
            this.isModalVisible = false;
        },
        showModal() {
          this.isModalVisible = true;
        },
        closeModal() {
          this.isModalVisible = false;
        }
    },
    components: {

   },
};
</script>

<style scoped>
.image-slider {
  position: relative;
}
.image-slider__slide,
.image-slider__content,
.image-slider__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-slider__slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.image-slider__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}
.image-slider__indicator {
  margin: .5rem .2rem;
  cursor: pointer;
}
</style>
