<template>
    <div class="tw-border tw-border-gray-100 tw-px-6 tw-space-y-1 tw-py-3 tw-bg-gray-100">
        <div class="tw-flex tw-text-sm tw-justify-between">
            <span>zzgl. {{ Object.keys(taxes)[0] }}% MwSt.</span>
            <span class="tw-font-bold">{{ formatNumber(tax) }} €</span>
        </div>
        <div class="tw-flex tw-justify-between tw-text-2xl tw-font-bold">
            <span>Gesamtsumme marktüblicher Restwert:</span>
            <span>{{ formatNumber(total) }} €</span>
        </div>
    </div>
</template>

<script>
import { formatNumber } from "../../../helpers/number.helper";

export default {
    name: "BuybackTotal",
    props: {
        total: {
            type: String | Number,
            default: "0"
        },
        taxes: {
            type: [Array, Object, Number, String],
            default: () => []
        }
    },
    data() {
        return {
            formatNumber
        }
    },
    computed: {
        tax() {
            return this.taxes ? this.taxes[Object.keys(this.taxes)[0]] : 0;
        }
    }
}
</script>

<style>
</style>
