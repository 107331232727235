<template>
    <div class="tw-w-full tw-space-y-4">
        <form
            enctype="multipart/form-data"
            novalidate>
            <div class="tw-relative tw-inline-block">
                <span :class="isLimit || isSaving || isFailed ? 'tw-bg-gray-400 tw-cursor-not-allowed' : 'tw-bg-primary tw-cursor-pointer'" class="tw-inline-block tw-whitespace-no-wrap hover:tw-text-white tw-py-1 tw-px-4 tw-rounded-full tw-text-white">
                    Dateien hochladen
                </span>
                <input
                    v-if="!isLimit"
                    type="file"
                    :multiple="false"
                    :name="uploadFieldName"
                    :disabled="isSaving || isFailed"
                    @change="filesChange($event.target.files); fileCount = $event.target.files.length"
                    accept="image/*"
                    class="input-file">
                <p v-if="isSaving" class="tw-inline-block tw-text-sm tw-cursor-wait">
                    Bilder werden hochgeladen...
                </p>
            </div>
        </form>

        <div class="tw-w-full tw-space-y-5" v-if="isSuccess || isInitial">
            <div class="tw-justify-between tw-items-center tw-grid tw-grid-cols-3 tw-px-6"
                v-for="(item, index) in uploadedFiles" :key="index">
                <img class="tw-w-20 tw-h-20 tw-object-contain" :src="item.file" :alt="item.name">

                <span class="text-sm" v-if="item.message">{{ item.message }}</span>
                <span class="text-sm" v-else>{{ item.name }}</span>

                <div class="tw-flex tw-items-center tw-space-x-32 tw-justify-end">
                    <div v-if="item.success" class="tw-h-10 tw-w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <path d="M11 11v19h19V19.8h2V32H9V9h19.5v2H11zm23.5-1l1.3 1.5-15 13.7-7.1-8.1 1.5-1.3 5.7 6.5L34.5 10z" fill="#00da71"/>
                        </svg>
                    </div>

                    <div class="tw-cursor-not-allowed tw-h-10 tw-w-10" v-else>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                            <path d="M11 11v19h19V11H11zm9.5 8.1l-5.3-5.3-1.4 1.4 5.3 5.3-5.3 5.3 1.4 1.4 5.3-5.3 5.3 5.3 1.4-1.4-5.3-5.3 5.3-5.3-1.4-1.4-5.3 5.3zM9 9h23v23H9V9z" fill="#eb0046"/>
                        </svg>
                    </div>

                    <div class="tw-cursor-pointer tw-h-8 tw-w-8" @click="remove(item.id, index)">
                        <svg class="tw-text-gray-100" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path   d="M25.0457 4.20231V5.91683H22.4807L21.0108 23.5638C20.9583 24.194 20.6709 24.7814 20.2055 25.2095C19.7401 25.6376 19.1308 25.8752 18.4985 25.875H6.59284C5.96049 25.8752 5.35118 25.6376 4.8858 25.2095C4.42042 24.7814 4.13297 24.194 4.08049 23.5638L2.60829 5.91569H0.0456543V4.20117H7.53011V3.39535C7.53011 2.06488 8.56225 0.974442 9.86986 0.880715L10.0505 0.875H15.0397C16.4319 0.875 17.5612 2.0043 17.5612 3.39535L17.5601 4.20117H25.0457V4.20231ZM20.7616 5.91683H4.32853L5.78816 23.4209C5.82245 23.8393 6.17221 24.1605 6.5917 24.1605H18.4996C18.7019 24.1606 18.8969 24.0846 19.0458 23.9476C19.1946 23.8106 19.2865 23.6226 19.3032 23.4209L20.7616 5.91683ZM10.9077 10.0237V20.0536H9.1932V10.0248H10.9077V10.0237ZM15.897 10.0237V20.0536H14.1825V10.0248H15.897V10.0237ZM15.0397 2.58952H10.0505C9.83674 2.58952 9.63177 2.67442 9.48065 2.82554C9.32953 2.97666 9.24463 3.18163 9.24463 3.39535V4.20231H15.8467V3.39535C15.8467 3.28943 15.8258 3.18455 15.7852 3.08671C15.7447 2.98886 15.6852 2.89998 15.6103 2.82514C15.5353 2.75029 15.4463 2.69096 15.3484 2.65053C15.2505 2.6101 15.1456 2.58937 15.0397 2.58952Z"
                                    fill="#849CA8" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isFailed">
            <p>Es ist ein Fehler aufgetreten. Bitte versuche es erneut.</p>
            <p v-if="uploadError">{{ uploadError }}</p>
            <div class="tw-cursor-pointer tw-font-bold tw-bg-gray-100" @click="reset">Jetzt erneut versuchen</div>
        </div>

        <ul class="tw-text-xs">
            <li>Mögliche Dateiformate: JPG, PNG</li>
            <li>maximale Dateigröße: 10MB</li>
            <li>Beachte bitte, dass die Bilder aus Sicherheitsgründen im Vorfeld geprüft werden müssen. Dafür werden die Daten temporär gespeichert, solltest du das Formular neu laden, oder in einem weiteren Schritt zurückspringen, so müssen die Bilder erneut hochgeladen werden.</li>
        </ul>
    </div>
</template>

<script>
import { upload } from "../../../service/upload.service";

const STATUS_INITIAL = 0,
    STATUS_SAVING = 1,
    STATUS_SUCCESS = 2,
    STATUS_FAILED = 3;

export default {
    name: "BuybackUpload",
    props: {
        productId: {
            type: String,
            default: ""
        },
        uploadPostUrl: {
            type: String,
            default: ""
        },
        removePostUrl: {
            type: String,
            default: ""
        },
        uploadFieldName: {
            type: String,
            default: ""
        },
        uploadLimit: {
            type: Number,
            default: 1
        },
        allowedFileTypes: {
            type: Array,
            default: () => ["jpg", "png"]
        },
        sessionFiles: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null
        };
    },
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
        isLimit() {
            return this.uploadedFiles.length >= this.uploadLimit;
        },
        transformedSessionFiles() {
            return Object.keys(this.sessionFiles).map(
                key => this.sessionFiles[key]
            );
        }
    },
    methods: {
        reset() {
            this.uploadError = null;
            this.clear(STATUS_INITIAL);
        },
        save(formData) {
            this.currentStatus = STATUS_SAVING;

            upload(this.uploadPostUrl, formData)
                .then(response => {
                    this.uploadedFiles.push(response);
                    this.currentStatus = STATUS_SUCCESS;
                    this.$emit("uploadedFiles", this.uploadedFiles);
                })
                .catch(err => {
                    console.log(err);
                    this.clear(STATUS_FAILED);
                });
        },
        filesChange(fileList) {
            const formData = new FormData();

            if (!fileList.length) return;

            if (this.uploadedFiles.length >= this.uploadLimit) {
                this.uploadError = `Du hast die maximale Anzahl an Bilder überschritten. Bitte bachte dass, maximal ${
                    this.uploadLimit
                } Bilder hochgeladen werden dürfen.`;
                this.clear(STATUS_FAILED);

                return;
            }

            Array.from(Array(fileList.length).keys()).map(x => {
                formData.append("file", fileList[x]);
                formData.append("prod_id", this.productId);

                this.save(formData);
            });
        },
        remove(identifier, index) {
            if (identifier) {
                const formData = new FormData();

                formData.append("prod_id", this.productId);
                formData.append("identifier", identifier);

                upload(this.removePostUrl, formData)
                    .then(response => {
                        this.currentStatus = STATUS_SUCCESS;
                        this.$emit("uploadedFiles", this.uploadedFiles);
                    })
                    .catch(err => {
                        this.clear(STATUS_FAILED);
                    });
            }

            this.uploadedFiles.splice(index, 1);
        },
        clear(status) {
            this.currentStatus = status;
            this.uploadedFiles = [];
            this.$emit("uploadedFiles", this.uploadedFiles);
        },
        setCurrentFiles() {
            this.transformedSessionFiles
                ? (this.uploadedFiles = this.transformedSessionFiles)
                : (this.uploadedFiles = []);
            this.$emit("uploadedFiles", this.uploadedFiles);
        }
    },
    mounted() {
        this.reset();
        this.setCurrentFiles();
    }
};
</script>

<style>
input[type="file"] {
    opacity: 0;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    right: 0;
    position: absolute;
    cursor: pointer;
}
</style>
