<template>
    <div class="service-pie-chart d-md-flex justify-content-between" v-if="chartdata">
        <div class="service-pie-chart-big">
            <h3>{{ translations.accordion_services.total_services }} <br> {{ chartdata.totalServices.total }}</h3>
            <dashboard-pie-chart :chartdata="chartdata.totalServices.chart.data" legend="bottom-left" />
        </div>
        <div class="service-pie-chart-small">
            <div class="service-pie-chart-small-grid d-block d-md-flex justify-content-between">
                <div class="service-pie-chart-small-grid-item">
                    <h4>{{ translations.accordion_services.leasing_services + chartdata.leasingServices.total}}</h4>
                    <dashboard-pie-chart width="120px" height="120px" :chartdata="chartdata.leasingServices.chart.data" legend="right" />
                </div>
                <div class="service-pie-chart-small-grid-item">
                    <h4>{{ translations.accordion_services.damaged_services + chartdata.damagedServices.total}}</h4>
                    <dashboard-pie-chart width="120px" height="120px" :chartdata="chartdata.damagedServices.chart.data" legend="right" />
                </div>
            </div>
            <div class="service-pie-chart-small-grid d-block d-md-flex justify-content-between">
                <div class="service-pie-chart-small-grid-item">
                    <h4>{{ translations.accordion_services.theft_services + chartdata.theftServices.total}}</h4>
                    <dashboard-pie-chart width="120px" height="120px" :chartdata="chartdata.theftServices.chart.data" legend="right" />
                </div>
                <div class="service-pie-chart-small-grid-item">
                    <h4>{{ translations.accordion_services.warranty_services + chartdata.warrantyServices.total}}</h4>
                    <dashboard-pie-chart width="120px" height="120px" :chartdata="chartdata.warrantyServices.chart.data" legend="right" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            translations: {
                type: [Array, Object],
                required: true
            },
            chartdata: {
                type: [Array, Object]
            }
        }
    }
</script>
