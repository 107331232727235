<template>
    <div class="admin-dashboard">
        <tab-view
            :state="activeState"
            :show-service="showService"
            :translations="translations.charts.tabs"
            @tabchange="tabChange($event)"  />

        <chart-view
            v-show="activeState === 0"
            :filters="filters"
            :translations="translations.charts"
            :additionals="additionals" />

        <order-view
            v-show="activeState === 1"
            :data="orders"
            :translations="translations.order"
            :order-route="orderRoute" />

        <service-view
            v-show="activeState === 2 && showService"
            :data="services"
            :translations="translations.service"
            :service-route="serviceRoute" />      
    </div>
</template>

<script>
export default {
    props: {
        additionals: {
            type: [Array, Object],
            default: () => []
        },
        translations: {
            type: [Array, Object],
            default: () => []
        },
        filters: {
            type: [Array, Object],
            default: () => []
        },
        orders: {
            type: [Array, Object],
            default: () => []
        },
        services: {
            type: [Array, Object],
            default: () => []
        },
        orderRoute: {
            type: String,
            default: ''
        },
        showService: {
            type: [Boolean],
            default: false
        },
        serviceRoute: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            activeState: 0
        }
    },
    methods: {
        tabChange(event) {
            this.activeState = event
        }
    }
}
</script>
