<template>
    <div class="chart" :class="legend">
        <pie-chart
            v-if="!isChartDataEmpty && !isOneCompany"
            :data="data"
            :dataset="{borderWidth: 0}"
            :colors="colors"
            :legend="false"
            :library="chartOptions"
            :width="width"
            :height="height"
            :class="className"
        ></pie-chart>
        <info-pie-component v-if="isChartDataEmpty || isOneCompany"
                            :info-text-empty="getInfoText"
                            :info-text-one="infoTextOne"
                            :info-text-value="getValueText"
                            :size="width"
                            :background-color="colors[0]"
                            :empty="!isOneCompany"></info-pie-component>
        <ul :class="legendClass" v-if="!isChartDataEmpty">
            <li class="legend-item" v-for="(label,i) in getLabels(chartdata)"
                @mouseover="triggerLabel($event, i)"
                @mouseout="removeLabel($event, i)"
                :key="i" :title="chartdata[i].value"
                ref="label">
                <div class="legend-color d-inline-block" :style="{backgroundColor: getColors(chartdata)[i]}"></div>
                <span>{{ label }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
    import ChartMixin from '../../../mixins/ChartMixin'

    export default {
        props: {
            chartdata: {
                type: [Array, Object],
                default: () => []
            },
            width: {
                type: [String],
                default: ''
            },
            height: {
                type: [String],
                default: ''
            },
            legend: {
                type: String,
                default: ''
            },
            legendClass: {
                type: [String],
                default: 'legend'
            },
            className: {
                type: [String],
                default: ''
            },
            infoTextEmpty: {
                type: String,
                default: ''
            },
            infoTextOne: {
                type: String,
                default: ''
            },
            infoTextValue: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                chartOptions: {
                    responsive: true,
                    animation: {easing: 'easeOutExpo'},
                    legend: {
                        labels: {fontSize: 12, boxWidth: 10, usePointStyle: true}
                    },
                    plugins: {
                        datalabels: this.getDataLabels()
                    }
                }
            }
        },
        mixins: [ChartMixin],
        computed: {
            colors() {
                return this.getColors(this.chartdata)
            },
            data() {
                return this.getChartData(this.chartdata)
            },
            getInfoText() {
                return this.chartdata.length > 0 ? this.replaceText(':company', this.infoTextEmpty, this.chartdata[0].label) : this.infoTextEmpty
            },
            getValueText() {
                return this.chartdata.length > 0 ? this.replaceText(':count', this.infoTextValue, this.chartdata[0].data) : ''
            },
            isChartDataEmpty() {
                return this.chartdata.length === 0
                    || (this.chartdata.length <= 1 && this.chartdata[0].data === 0)
                    || this.isGreaterZero() === 0
            },
            isOneCompany() {
                return (this.chartdata.length === 1 && this.chartdata[0].data !== 0)
            }
        },
        methods: {
            getDataLabels() {
                return {
                    display: false
                }
            },
            triggerLabel(event, index) {
                if (this.$refs.label[index].children.length < 3) {
                    const div = document.createElement('div')
                    div.classList.add('legend-tooltip')
                    div.classList.add(`tooltip-${index}`)
                    div.innerText = this.chartdata[index].data
                    this.$refs.label[index].appendChild(div)
                }
            },
            removeLabel(event, index) {
                const tooltip = document.querySelector(`.tooltip-${index}`)
                this.$refs.label[index].removeChild(tooltip)
            },
            replaceText(needle, haystack, replacer) {
                return haystack.split(needle).join(replacer)
            },
            isGreaterZero() {
                return this.chartdata.filter(item => item.data > 0).length
            }
        }
    }
</script>

<style lang="scss">
    @keyframes toggle {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .chart {
        .legend-item {
            position: relative;

            .legend-tooltip {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                top: -50px;
                transition: .3s all ease-in;
                color: #fff;
                background-color: #000;
                font-weight: 700;
                padding: .5rem 2.75rem;
                font-size: 15px;
                border-radius: 10px;
                animation: toggle .2s;
                animation-iteration-count: 1;

                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    bottom: -18px;
                    left: 50%;
                    transform: translateX(-50%);
                    border-bottom: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-left: 10px solid transparent;
                    border-top: 10px solid #000;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .legend {
            list-style: none;
            display: flex;
            justify-content: space-between;
            padding: 0;

            &-item {
                font-size: 12px;
                display: flex;
                align-items: center;
                font-family: "Source Sans Pro", sans-serif;
                font-weight: 200;
                color: #3B3B3B;
                margin: 0 .25rem;
                cursor: pointer;

                .legend-color {
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin-right: .3rem;
                }
            }
        }

        &.right {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .legend {
                justify-content: normal;
                flex-direction: column;
            }
        }

        &.bottom-left {
            .legend {
                justify-content: flex-start;
                margin-top: 1.125rem;

                &-item {
                    padding-right: 0.625rem;
                }
            }
        }

        .legend-grid {
            display: grid;
            grid-template-columns: repeat(4, 25%);
            grid-template-rows: auto;
            margin-bottom: 4.375rem !important;

            .legend-item {
                margin-bottom: 0.875rem;
                align-items: baseline !important;

                .legend-color {
                    position: relative;
                    top: 3px;
                }

                & > span {
                    line-height: 12px;
                    display: inline-block;
                    width: 85%;
                }
            }
            &.legend-grid--3{
                grid-template-columns: repeat(3, 33.33%);
                margin-left:0;
            }
            &.legend-grid--2{
                grid-template-columns: repeat(2, 50%);
                margin-left:0;
            }
        }

        .center {
            margin: 0 auto;
        }

    }

    .info-content {
        margin: 3rem 1.25rem !important;
    }
    .service-pie .info-content-pie {
        margin: 0rem 1.25rem 1rem !important;
        svg{
            display: none;
        }
    }
</style>
