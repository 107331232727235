import { render, staticRenderFns } from "./DeliveryPieCharts.vue?vue&type=template&id=220f995e&scoped=true&"
import script from "./DeliveryPieCharts.vue?vue&type=script&lang=js&"
export * from "./DeliveryPieCharts.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryPieCharts.vue?vue&type=style&index=0&id=220f995e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220f995e",
  null
  
)

export default component.exports