<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :viewBox="viewbox"
      :stroke-width="strokeWidth"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke="currentColor"
      :class="getClassName"
    >
      <component :is="`Icon${iconType}`" />
    </svg>
  </div>
</template>

<script>
export default {
    name: 'IconBase',
    props: {
        icon: {
            type: String,
            default: "Question"
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        strokeWidth: {
            type: [Number, String],
            default: 1
        },
        viewbox: {
            type: [String],
            default: '0 0 40 40'
        },
        className: {
            type: [String],
            default: 'secondary'
        }
    },
    computed: {
        iconType() {
        return this.icon[0].toUpperCase() + this.icon.slice(1);
        },
        getClassName() {
            return `stroke--${this.className}`
        }
    }
}
</script>
