<template>
    <div class="data-table mt-4" :class="compact ? 'data-table--small' : ''">
        <div class="loading" v-show="isLoading">
            <loader />
        </div>

        <data-table
            class="data-table__table"
            v-show="!isLoading"
            :url="url"
            :per-page="perPage"
            :filters="filters"
            :columns="columns"
            order-dir="desc"
            :translate="{ nextButton: translations.paginate_next, previousButton: translations.paginate_previous}"
            @finishedLoading="isLoading = false">
            <div slot="filters" slot-scope="{ tableData, perPage }">
                <div class="filters row">
                    <div v-if="perPage" class="col-md-3">
                        <div class="select-value">
                            <select name="perPage" class="form-control select-list" v-model="tableData.length">
                                <option :key="page" v-for="page in perPage" :value="page">
                                    {{ translations.entries_per_page }} {{ page }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div v-if="additional.states" class="col-md-3">
                        <div class="select-value">
                            <select
                                v-model="tableData.filters.states"
                                class="form-control select-list">
                                <option value>{{ translations.all_states }}</option>
                                <option v-for="(state, index) in additional.states"
                                        :key="index"
                                        :value="index">
                                    {{ state }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div v-if="additional.types" class="col-md-3">
                        <div class="select-value">
                            <select
                                v-model="tableData.filters.types"
                                class="form-control select-list">
                                <option value>{{ translations.all_types }}</option>
                                <option v-for="(type, index) in additional.types"
                                        :key="index"
                                        :value="index">
                                    {{ type }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <div v-if="additional.search" class="col-md-3 ml-auto">
                        <div class="field__search">
                            <input
                                name="name"
                                class="form-control filters__search"
                                v-model="tableData.search"
                                placeholder="">
                        </div>
                    </div>
                </div>
            </div>
        </data-table>
    </div>
</template>

<script>
    import DatatableMixin from "./mixins/DatatableMixin";
    import ServiceTicketButton from "./administration/table/ServiceTicketButton";
    import ServiceStatus from "./administration/table/ServiceStatus";
    import DateText from "./administration/table/DateText";
    import StateText from "./administration/table/StateText";

    export default {
        components: {
            ServiceTicketButton,
            ServiceStatus,
            DateText,
            StateText
        },
        data() {
            return {
                isLoading: true,
                filters: {
                    types: '',
                    states: '',
                },
            }
        },
        mixins: [DatatableMixin]
    }
</script>
