<template>
    <div class="row newsletter">
            <div class="col-12 mt-4 tw-block tw-border tw-p-4" :class="classes">
                <div class="info-box--header pb-4 tw-items-center" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" class="icon icon-big secondary--stoke">
                        <path d="m19.988 10.953-2.885-.868-2.303 1.942M8.069 17.025l-4.48 3.781 3.297 15.918 30.175-6.25-3.297-15.918-5.614-1.69" style="fill:none;;stroke-linecap:round;stroke-linejoin:round"/>
                        <path d="M20.041 24.272 6.886 36.724l30.175-6.25-17.02-6.202zM25.34 26.011l9.226-7.584M4.391 24.678l11.479 3.295M11.945 24.683l-2.03-9.804 15.689-3.25 2.03 9.804M13.998 23.756l6.003-1.244M13.602 21.844l9.077-1.88" style="fill:none;;stroke-linecap:round;stroke-linejoin:round"/>
                        <path d="M12.778 16.173h5.441V19h-5.441z" style="fill:none;;stroke-linecap:round;stroke-linejoin:round" transform="rotate(-11.702 15.498 17.587)"/>
                        <path d="m3.661 10.077 3.087 2.613M8.864 5.04l2.568 3.791M29.435 4.738 27.64 8.362M22.66 2.182l-.852 4.499" style="fill:none;;stroke-linecap:round;stroke-linejoin:round"/>
                    </svg>
                    <span class="tw-font-mediun tw-text-2xl c-dark-gray tw-capitalize">{{ translations.title }}</span>
                </div>
                <div class="success-container pt-2 pb-4" v-if="success">
                    <div class="col-12 info-box border-success">
                        {{ translations.success }}
                    </div>
                </div>
                <div class="error-container pt-2 pb-4" v-if="error">
                    <div class="col-12 info-box border-error">
                        {{ translations.error }}
                    </div>
                </div>
                <div class="info-box--body">
                    <loader v-show="loading" />
                    <form v-show="!loading" @submit.prevent="add" >
                        <div class="checkbox checkbox--left" v-for="(item, index) in list" :key="index">
                            <label :for="`list_id_${item.id}`">
                                <input type="checkbox" name="list_id[]" :id="`list_id_${item.id}`" :value="item.id" v-model="selected">
                                <span class="checkbox"></span>
                                {{ item.text }}
                            </label>
                        </div>
                        <div class="tw-grid tw-justify-items-stretch">
                            <button v-on:click="changeLists()" type="button" class="btn btn-primary c-white tw-justify-self-end">
                                {{ translations.submit }}
                            </button>
                        </div>
                    </form>
                </div>
        </div>
    </div>
</template>


<script>

export default {
    name: "Newsletter",
    props: {
        url: {
            type: Object
        },
        email: {
            type: String
        },
        classes: {
            type: String,
            default: 'border-gray'
        },
        listids:{
            type: [Array, Object],
            default: () => []
        },
        translations: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            selected: [],
            list_id: {},
            loading: false,
            error: false,
            success: false,
        };
    },
    computed: {
        list() {
            return this.listids.map(id => ({id, text: this.translations['listIds'][id]}))
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async changeLists() {

        await axios
            .post(this.url['post'], {
                email:this.email,
                listIds:this.selected
            })
            .then(response => {
                this.success = true;

                setTimeout(() => this.success = false, 3000)

            })
        .catch(error => {
                console.log(error);
                this.error = true;
            });
        },

        async fetchData() {
            this.loading = true;

            await axios
                .get(this.url['get'])
                .then(response => {
                    this.selected = response.data.data;
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                });
        }
    }
};
</script>

<style>
.newsletter{
    position: relative;
}
</style>
