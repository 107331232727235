<template>
    <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-py-8">
        <icon-base v-if="contract == 1" icon="ContractEnd" width="80px" height="80px" :class="text.class ? 'tw-text-'+text.class : ''" className="tw-w-18 tw-h-18 lg:tw-w-20 lg:tw-h-20" />
        <icon-base v-else icon="Contract" width="80px" height="80px" :class="text.class ? 'tw-text-'+text.class : ''" className="tw-w-18 tw-h-18 lg:tw-w-20 lg:tw-h-20" />

        <div class="md:tw-flex tw-justify-between tw-items-center tw-w-full ">
        <div class="tw-my-5 lg:tw-my-0 tw-ml-8">
            <p v-html="text.text" class="tw-mb-1 tw-text-lg" :class="text.class ? 'tw-text-'+text.class : ''"  >{{ text.text }}</p>
            <p class="tw-mb-0">{{ text.message }}</p>
        </div>
        <div>
            <a v-if="contract == 4" class="tw-bg-primary tw-whitespace-no-wrap hover:tw-text-white tw-ml-auto tw-py-1 tw-px-4 tw-rounded-full tw-text-white" :href="text.button_link" >
                <span class="tw-text-white" >{{ text.button}}</span>
            </a>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["contract", "text"]
};
</script>
