<template>
    <div class="button__delete">
        <svg    xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                width="36"
                height="36"
                viewBox="0 0 40 40"
                class="stroke--secondary"
                @click="togglePopup">
            <line class="cls-1" x1="6.42" y1="10.5" x2="33.58" y2="10.5"/>
            <path class="cls-1" d="M22.91,6.62H17.09a1.94,1.94,0,0,0-1.94,1.94V10.5h9.7V8.56A1.94,1.94,0,0,0,22.91,6.62Z"/>
            <line class="cls-1" x1="17.09" y1="26.99" x2="17.09" y2="17.29"/>
            <line class="cls-1" x1="22.91" y1="26.99" x2="22.91" y2="17.29"/>
            <path class="cls-1" d="M28.88,32A1.94,1.94,0,0,1,27,33.78H13.06A2,2,0,0,1,11.12,32L9.33,10.5H30.67Z"/>
        </svg>

        <PopUp :open="open">
            <div class="container popup">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h2 class="mb-3 text-left">{{ translations.headline }}</h2>
                        <p>{{ translations.content }}</p>
                    </div>
                    <div class="col-12 col-sm-5">
                        <button type="button" class="btn close-form btn-block btn-default btn-lg btn-back bc-p b-transparent" @click="togglePopup">
                            {{ translations.cancel }}
                        </button>
                    </div>
                    <div class="col-12 col-sm-5">
                        <button type="submit" class="btn btn-primary btn-block" @click="removeUser(data)">
                            {{ translations.delete }}
                        </button>
                    </div>
                </div>
            </div>
        </PopUp>
    </div>
</template>

<script>
    export default {
        name: "DeleteButton",
        props: {
            data: {},
            meta: {}
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            translations() {
                return this.meta.translations ? this.meta.translations : ''
            }
        },
        methods: {
            togglePopup() {
                this.open = !this.open
            },
            removeUser(data, meta) {
                window.location.href = `${this.meta.url}/${data.id}`;
            }
        },
    }
</script>

<style scoped>
.button__delete {
    text-align: center;
    cursor: pointer;
}

.popup {
    max-height: 100%;
    background: #fff;
    padding: 2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50vw;
    transform: translate(-50%, -50%);
}
</style>
