<template>
    <div class="users-chart">
        <loader v-show="loading" />

        <chart-filter
            :url="url"
            :translations="additionals.translations"
            :companies="filters"
            :datesFilterable="false"
            @updatedLoader="getUpdatedLoader"
            @updatedData="getUpdatedData" />

        <div>
            <div class="relative d-md-flex">
                <conversion-block
                    icon="WhitelistUser"
                    :translations="additionals.translations"
                    :headline="translations.whitelist"
                    :percentHeadline="translations.interest_rate"
                    :conversion-data="conversions.whitelistUser"
                    :info-text-empty="infoTranslations.info_whitelist_user_per_company_empty"
                    :info-text-one="infoTranslations.info_whitelist_user_per_company_one"
                    :info-text-value="infoTranslations.info_whitelist_user_per_company_value" />

                <conversion-block
                    icon="UserConversion"
                    :translations="additionals.translations"
                    :headline="translations.registered_user"
                    :percentHeadline="translations.conversion_rate"
                    :conversion-data="conversions.registeredUser"
                    :info-text-empty="infoTranslations.info_registered_user_per_company_empty"
                    :info-text-one="infoTranslations.info_registered_user_per_company_one"
                    :info-text-value="infoTranslations.info_registered_user_per_company_value"/>
            </div>
            <registered-user :data="conversions.tile" :translations="translations"></registered-user>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UsersChart',
    props: {
        url: {
            type: String,
            default: ''
        },
        additionals: {
            type: [Array, Object],
            default: () => []
        }
    },
    data() {
        return {
            chartData: {},
            loading: false
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        filters() {
            return this.additionals.filters || []
        },
        translations() {
            return this.additionals.translations.conversion || []
        },
        infoTranslations() {
            return this.additionals.translations.info
        },
        conversions() {
            return this.chartData.conversion || []
        }
    },
    methods: {
        getUpdatedLoader(value) {
            if(value) {
                this.loading = value
            }
        },
        getUpdatedData(value) {
            if(value) {
                this.chartData = value
                this.loading = false
            }
        },
        async fetchData() {
            this.loading = true

            await axios
                .get(this.url)
                .then(response => {
                    this.chartData = response.data
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
            })
        }
    }
}
</script>

<style scoped>
.users-chart {
    position: relative;
    border: 2px solid #F1F1F1;
}
</style>
